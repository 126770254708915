const ApiData = {
    estimateDetail: "sigong/estimateDetail",
    sigonginfoDetail: "sigong/sigonginfoDetail",
    infoIn: "sigong/infoIn",
    infoDetail: "sigong/infoDetail",
    payerIn: "sigong/payerIn",
    finalInfo: "sigong/finalInfo",
    MaterialTime: "sigong/MaterialTime",
    MaterialSearch: "sigong/MaterialSearch",
    MaterialChoose: "sigong/MaterialChoose",
    AsSendIn: "sigong/AsSendIn",
    AsDetail: "sigong/AsDetail",
    movementFlagIn: "sigong/movementFlagIn",
    movementFlagVal: "sigong/movementFlagVal",
    loginUser: "sigong/loginUser",
    download: "sigong/downloadImg",
    sendAgreeDate: "sigong/sendAgreeDate",
    findFirstCeiling: "sigong/findFirstCeiling",
    MaterialNewSearch: "sigong/MaterialNewSearch",
    MaterialCompany: "sigong/MaterialCompany",

    MaterialNewSelectDetail: "sigong/MaterialNewSelectDetail",
    MaterialNewDetail: "sigong/MaterialNewDetail",
    MaterialFinishNewDetail: "sigong/MaterialFinishNewDetail",
    Materialrecommend: "sigong/Materialrecommend",

    MaterialNewSubmit: "sigong/MaterialNewSubmit",
    MaterialNewSelectIn: "sigong/MaterialNewSelectIn",
    MaterialNewDelete: "sigong/MaterialNewDelete",

    MaterialDropDownList: "sigong/MaterialDropDownList",

    MaterialNewBaseBoardSelect: "sigong/MaterialNewBaseBoardSelect",
    MaterialNewZangpanSelectIn: "sigong/MaterialNewZangpanSelectIn",
    MaterialNewZangpanSelectDetail: "sigong/MaterialNewZangpanSelectDetail",
};

export default ApiData;
