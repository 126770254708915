// import moment from "moment";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { imageData } from "../libs/images";
import consts from "../libs/consts";
import { useEffect, useRef, useState } from "react";
import usePopup from "../zustands/usePopup";
import { toast } from "react-toastify";
import { usePostData } from "../utils/service";
import ApiData from "../libs/api";
dayjs.extend(utc);
dayjs.extend(timezone);
// import moment from "moment-timezone";
const AccordionContent = [
    {
        title: "도배 시공 계약서",
        children: `<p>※ 아래는 ‘시공 가능 여부’에 영향을 미치는 사항이며, 사전연락이 없이 발생한 상황에 대한 책임은 고객에게 있습니다. 의심되는 부분이 있다면 반드시 고객센터로 문의주세요.</p>
        <ul>
        <li>사전에 다른 인테리어 시공을 하는 경우</li>
        <li>타 인테리어 시공과 일정이 겹치는 경우</li>
        <li>기존 벽지가 발포벽지나 지사벽지인 경우</li>
        <li>기존 벽지가 종이벽지가 아닌 재질인 경우 (페인트, 레더벽지, 패브릭벽지, 방음재, 단열재, 아이소핑크, 유리, 나무 등)</li>
        <li>우물형 천장, 간접등이 있는 경우</li>
        <li>몰딩이 없는 공간(무몰딩)에 도배하는 경우</li>
        <li>천장높이가 2m 40cm 이상인 경우, 복층, 계단이 있는 경우</li>
        </ul>
        <p>※ 다음은 도배 시공 기준에 대한 내용입니다. 반드시 확인하시기 바랍니다.</p>
        <ul>
        <li>특이 사항을 통해 전달된 내용은 계약사항과 동일한 효력을 가지니, 재차 확인 부탁드립니다.</li>
        <li>고객님이 제공해주신 중요정보(전용면적, 시공 범위, 당일 환경)가 실제와 차이가 나는 경우, 견적이나 작업 상황에 변경이 생길 수 있습니다.</li>
        <li>시공지(단지내)의 정책에 따라 관리규정에 맞는 사전준비(실내 및 엘레베이터 보양, 입주민 동의서 등)는 별도로 고객님께서 준비해 주셔야 합니다.</li>
        <li>LH에서 지원하는 인테리어 시공은 진행하지 않습니다.</li>
        <li>주차 비용이 있는 건물의 주차비와 폐기물 처리 비용이 포함되어 있지 않습니다. 미리 준비가 안된 상황인 경우 이와 관련하여 추가비용이 발생할 수 있습니다.</li>
        <li>기존 벽지 위에 시공 되어있는 굽도리(스티커 형식의 걸레받이)는 제거되고 재활용되지 않습니다. 도배 시공자가 굽도리 시공을 하지 않습니다.</li>
        <li>짐이 있는 상황에서 시공을 할 때, 가구 안에 있는 짐을(책, 화장품, 옷 등) 정리하지 않은 경우 견적 혹은 시공 가능 여부가 변경될 수 있습니다. 가구를 제외한 짐은 시공을 하지 않는 베란다로 정리해주세요.</li>
        <li>대형가구(장롱, 돌침대 등)의 경우 시공 범위 바깥으로 옮겨 놓으셔야 해당 범위 시공 가능하며, 옮겨 놓지 않으실 경우 손이 닿는 부분까지만 작업합니다.</li>
        <li>설치된 구조물(싱크대, 시스템장 등)은 시공팀이 해체, 조립할 수 없습니다. 미리 해체하지 않으실 경우 해당 부분은 시공 불가합니다.</li>
        <li>샷시교체, 몰딩교체 등의 공사 후, 틈이 벌어진 채로 마감을 하지 않는 경우가 많습니다. 이 부분은 도배 시공으로 완벽한 마감이 불가능하니, 문선 몰딩이나 핸디코트 등으로 마감을 먼저 해놓아야 합니다.</li>
        <li>기존벽지를 모두 제거하지는 않습니다. 기존 벽지는 도배품질에 영향을 미치는 부분 위주로 선택적으로 제거 합니다. 전부 제거를 원하면(뒷면 진한 벽지의 비침 우려 등) 인원이 추가(면적에 따라 다름)되어 이를 제거하고 벽지를 부착합니다. 다만, 기존 시멘트 벽면이나 석고보드 면이 100% 드러나게 제거할 수는 없습니다. 기존 벽지가 2겹 이상(일반적인 상황이 아님) 켜켜이 덧붙여 있다면 연장작업으로 인한 비용 추가가 발생할 수 있습니다.</li>
        <li>벽면의 미장상태가 나쁠 경우, 시공 후 기존 벽의 상태가 벽지 위로 노출될 수 있습니다. 특히 벽면의 상, 하단, 벽면 부착물(콘센트, 인터폰 등) 주변, 좁은 공간 같이 벽지가 벽에 직접 붙는 부분은 기존의 벽 상태가 그대로 드러납니다.</li>
        <li>실크 시공의 경우 부직포 초배 작업을 통해 벽면의 굴곡을 완화하는 공사를 하지만, 기존의 벽면 상태가 고르지 못한 경우엔 벽면의 굴곡이 벽지 위로 드러날 수 있습니다. 주로 벽지가 부착되는 벽면의 가장자리( 상하 좌우 약 20cm 및 부착물 주변)는 벽면의 굴곡이 드러납니다. 가장자리 굴곡까지 해결하려면 ' 핸디 코트 작업 (퍼티)'을 추가해야 합니다. 또한, 일반적으로 천장은 부직포시공을 진행하지 않습니다.</li>
        <li>이러한 벽면은 미장 작업 (퍼티, 빠데, 핸디코트)을 통한 시공으로 완화할 수 있으나, 이는 도배 시공의 영역에서 제외됩니다. 사전의 별도 고지 및 공사가 필요하므로 참고해주시기 바랍니다.</li>
        <li>곰팡이가 있는 경우, 아크졸 작업 진행 후에 도배를 진행하고 있습니다. 하지만 근본적인 곰팡이 발생 원인 제거를 위해선 단열 공사가 필요합니다. 단열 공사는 기본적으로 시공 영역에 포함되어 있지 않습니다.</li>
        <li>도배 시공팀은 콘센트, 조명장치 교체, 굽도리 시공 등을 포함한 도배 이외의 모든 작업은 진행하지 않습니다.</li>
        <li>조명의 경우, 교체 예정이 아닌 전등을 프레임까지 제거하고 도배 시공을 하지 않습니다. 또한, 벽에 고정된 부착물(벽걸이 TV, 에어컨, 액자, 콘센트 등)이 있을 경우, 주위를 재단해 도배를 진행합니다. 부착물의 뒷면까지 도배 시공을 희망하시면, 시공 전 부착물을 미리 철거해 주시기 바랍니다.</li>
        <li>붙박이장 내부, 세탁실, 다용도실, 베란다, 아트월, 화장실, 전실, 팬트리, 드레스룸은 도배 견적에 포함되지 않습니다.</li>
        <li>고객이 고지하지 않은 다른 인테리어 공사가 도배 시공과 동시에 진행되어 영향을 미칠 경우, 도배 시공이 취소되거나 견적이 변경될 수 있습니다. 당일 현장에서 취소되는 경우, 그에 따른 위약금이 발생합니다.</li>
        <li>시공사의 책임으로 발생한 외부 충격에 의한 손상 및 하자는 (찢어짐, 찍힘, 긁힘, 까짐 등 외부 충격요인), [5일] 이내 사진과 함께 A/S를 요청해야 합니다. 이 기간을 넘길 시 외부 충격에 의한 손상 원인 증명의 책임은 고객에게 있으며, 과실이 증명될 경우에만 A/S를 진행합니다.</li>
        <li>기존 벽면의 상태로 인해 벽지가 고르게 시공이 되지 못한 것은 A/S 대상이 아닙니다.</li>
        <li>시공지의 환경에 따라 시공 후 벽지 위로 색이 배어 나올 수 있으며, 이 경우 무상 A/S는 불가합니다.</li>
        <li>도배 시공 후 풀이 남아있을 수 있습니다. 도배지가 마른 후, 따뜻한 물에 적신 걸레로 닦아내어 주시기 바랍니다. 잔여 풀은 시공 과정상의 문제가 아니며 A/S 대상에 해당하지 않습니다.</li>
        <li> A/S가 고객의 요청으로 인해 거절되는 경우에는 다른 방법으로 보상을 진행하지 않으며, A/S 진행 시 부가적으로 발생하는 비용( 청소비용, 짐 정리 비용, 교통비용, 시간 소요에 의한 기회비용 등)들이 지원되지는 않습니다.</li>        
        <li>도배 시공 시 발생하는 폐기물은 고객님이 준비해주신 해당 지역의 폐기물 봉투를 통해 처리합니다. 일반적으로 일반 종량제 100L 봉투로 폐기가 가능하지만, 지역에 따라 폐기물 전용 봉투나 마대가 필요한 곳도 있으니 확인하여 준비하시면 됩니다. 당일 현장방문이 어려울 경우, 폐기물 봉투는 작업자가 현장에서 구매하며, 발생한 비용은 견적에 자동 추가됩니다. </li>
        <li>시공자가 당일 시공 현장이 별도의 조치없이 시공할 경우 명백한 문제가 발생할 것으로 판단되면 현장에서 시공이 취소될 수 있습니다. 또한 이에 대한 예약금 환불 정책은 [일정변경, 취소 및 환불정책]의 [시공이 불가할 경우의 환불]을 참고해주시기 바랍니다.</li>
        </ul>
        <p>※ 시공 진행에 따른 확인사항</p>
        <h3>1. 시공 전</h3>
        <ul>
        <li>벽지 선택은 시공 5일 전까지 완료되어야 합니다. 정해진 기간 이후 벽지 선택 혹은 변경시 별도의 배송 비용이 발생합니다.</li>
        <li>2종류 이상의 벽지 선택 시 1롤에 해당하는 벽지가격이 추가됩니다. 일반 제품기준 합지는 15,000원, 실크는 30,000원의 비용이 각각 추가되며, 고급 제품을 선택하실 경우 고객센터로 별도 문의해주시기 바랍니다.
        </li>
        <li>시공 5일 전까지 주소와 출입번호, 출입방법을 전달해주시기 바랍니다. 자재 배송 및 시공팀 출입 목적으로 사용됩니다.
        </li>
        <li공사 전 변동사항은 시공일로부터 5일 전까지 통보가 되어야 하며, 변동사항이 발생할 경우 견적 및 시공 일정이 변경될 수 있습니다. 변동사항이 있을 경우 고객센터로 반드시 연락주세요. 환불 정책은 [일정 변경, 취소 및 환불정책]의 [시공이 불가할 경우의 환불]을 확인하시기 바랍니다.</li>
        </ul>
        <h3>2. 시공 당일</h3>
        <ul>
        <li>시공은 일반적으로 오전 8시에 시작합니다.</li>
        <li>사전에 주차공간을 확보해주시기 바랍니다. 주차비용은 견적에 포함되어있지 않으며, 추가적으로 발생하는 주차비용이 청구됩니다.</li>
        <li>계약 이외의 범위에 대한 시공은 진행하지 않습니다.</li>
        <li>사전에 현장의 짐을 치워주세요. 사전에 통보되지 않은 짐이 있는 경우 시공이 취소될 수 있습니다.</li>
        <li>도배 시공팀이 조명 혹은 콘센트를 다른 제품으로 교체하거나 다른 위치로 옮겨드리지는 않습니다.</li>
        <li>시공 중 촬영된 사진은 제품선택에 도움이 되는 정보, 혹은 마케팅 용도로 사용될 수 있습니다.</li>
        </ul>
        <h3>3. 시공 후</h3>
        <ul>
        <li>시공 직후에는 벽지가 펴지지 않은 상태입니다. 자연건조를 위해 바람이 불지 않도록 하고, 온도를 일정 수준으로 유지해주세요. 선풍기, 에어컨, 온풍기, 보일러를 끄고 창문을 닫아주세요. 최대 7일 안에 벽지가 다 펴집니다. (창문은 한쪽만 살짝 열어두고, 겨울철은 보일러 온도를 약 20도 정도의 적정 온도로 유지하는 것이 좋습니다.)</li>
        <li>대금 지급은 시공 당일 결과 확인 후 당일 잔금 입금을 원칙으로 합니다.</li>
        <li>자재는 여유분을 포함해 배송됩니다. 자재와 부자재가 남는 것이 일반적이며, 시공 후 남은 자재 및 부자재는 회수합니다.</li>
        <li>시공 후 1년간 배상 범위 내의 A/S를 진행합니다.</li>
        <li>[배상 범위가 아닌 경우: 구조물로 인한 손상 (석고의 뒤틀림 등), 시공 후의 외부 충격에 의한 손상 (찍힘, 찢김), 고객의 귀책사유로 인한 문제 (건조 과정에서의 문제 등), 시간 변화 및 생활 조건에 따른 변색 및 오염, 정상 사용 범위에 준하지 않는 제품 유지 관리시]</li>
        <li>A/S는 시공을 진행한 시공자가 진행합니다.</li>
        </ul>
        <p>※ 대금 지급일은 시공완료일을 기준으로 합니다. 대금 지급이 지연되는 경우, 미지급액에 대하여 지급일 다음 날로부터 완제일까지 연 12%의 지연이자가 부가됩니다. 잔금 지급에 관한 원칙은 준공일을 기준으로 합니다.</p>
        <ul>
        <li>현금, 유가증권, 귀금속, 예금통장, 신용카드, 인감 등 고객이 휴대할 수 있는 귀중품, 위험품, 불결한 물품 등 다른 화물에 손해를 끼칠 염려가 있는 물건, 동식물, 미술품, 골동품 등 운송에 특수한 관리를 요하는 물건은 현장에서 치워주세요. 앞에서 언급한 물건에 대한 훼손, 파손, 분실 등에 책임지지 않습니다.</li>
        <li>여덟시도배는 계약서에 기재된 금액을 초과하여 청구하지 않습니다. 다만, 고객의 책임이 있는 사유로 금액 산정에 관련된 사항이 변경되는 경우, 초과금액을 미리 고객에게 고지한 한 뒤 초과된 금액을 청구할 수 있습니다.</li>
        <li>고객이 책임 있는 사유로 계약을 해제한 경우에는 계약금에 해당하는 손해배상액을 여덟시도배에 지급해야합니다.</li>
        </ul>
        `,
    },
    {
        title: "장판 시공 계약서",
        children: `
        <p>※ 아래는 ‘시공 가능 여부’에 영향을 미치는 사항이며, 사전연락이 없이 발생한 상황에 대한 책임은 고객에게 있습니다. 의심되는 부분이 있다면 반드시 고객센터로 문의주세요.</p>
        <ul>
            <li>타 인테리어 시공과 일정이 겹치는 경우</li>
            <li>강(화)마루, 데코타일, 타일이 시공되어 있을 경우</li>
            <li>바닥면에 심한 요철이나 굴곡, 크랙이 있는 경우</li>
            <li>바닥면에 페인트나 압착장판, 종이장판(한지장판)이 시공되어있는 경우</li>
            <li>기존 장판이 1겹 이상으로 되어 있는 경우</li>
            <li>4.5T와 같은 두꺼운 장판의 경우</li>
            <li>시스템장이 설치되어있는 경우</li>
            <li>엘리베이터가 없거나, 사용할 수 없는 경우 (엘리베이터 사용료, 보양 작업이 필요한 경우)</li>
            <li>거주중 혹은 이사일정이 겹치는 경우</li>
            <li>시공당일 짐이나 폐기물 등으로 시공이 불가능하다고 판단되는 경우</li>
        </ul>
        <p>※ 다음은 시공 기준에 대한 내용입니다. 반드시 확인하시기 바랍니다.</p>
        <ul>
            <li>특이 사항을 통해 전달된 내용은 계약사항과 동일한 효력을 가지니, 재차 확인 부탁드립니다.</li>
            <li>고객님이 제공해주신 중요정보 (전용면적, 시공 범위, 당일 환경)가 실제 시공면적과 차이가 나는 경우, 견적이나 작업 상황에 변경사항이 발생할 수 있습니다.</li>
            <li>시공지(단지내)의 정책에 따라 관리규정에 맞는 사전준비(실내 및 엘레베이터 보양, 입주민 동의서 등)는 별도로 고객님께서 준비해 주셔야 합니다.</li>
            <li>LH에서 지원하는 인테리어 시공은 진행하지 않습니다.</li>
            <li>짐이 있는 상황에서 시공을 할 때, 가구 안에 있는 짐을 (책, 화장품, 옷 등) 정리하지 않은 경우 견적 혹은 시공 가능 여부가 변경될 수 있습니다.</li>
            <li>대형가구(장롱, 돌침대 등)는 이동이 불가능합니다. 해당 경우에는 손이 닿는 부분까지만 작업합니다.</li>
            <li>기존에 MDF 걸레받이가 시공되어 있는 경우 철거 하지 않습니다. 이때 기존 장판과 MDF 걸레받이가 실리콘으로 마감되어 있는 경우, 기존 실리콘이 완전히 제거 되지 않거나 걸레받이의 손상이 있을수 있습니다.</li>
            <li>바닥의 미장상태가 나쁠 경우, 시공 후 기존 바닥의 상태가 장판 위로 노출될 수 있습니다. 바닥을 평탄화하는 작업은 별도의 추가 비용이 발생합니다.</li>
            <li>바닥면의 굴곡이나 균열을 없애는 미장 작업 (퍼티, 빠데, 핸디코트)을 통한 시공으로 완화할 수 있으나, 이는 장판 시공의 영역에서 제외됩니다. 사전의 별도 고지 및 공사가 필요하므로 참고해주시기 바랍니다.</li>
            <li>아파트 구조적인 문제 (바닥의 높은 습도, 누수, 바닥 경사 등)로 인해 발생하는 문제는 A/S에 해당하지 않습니다.</li>
            <li>곰팡이가 있는 경우, 이를 제거하고 간단한 약품처리를 하는 등 최대한 노력을 하고 있습니다. 하지만 근본적으로 곰팡이 제거를 위해선 단열 공사가 필요합니다. 단열 공사는 기본적으로 시공 영역에 포함되어 있지 않습니다.</li>
            <li>시공팀은 콘센트, 조명장치 교체 등을 포함한 장판 이외의 모든 작업은 진행하지 않습니다.</li>
            <li>대형가구(장롱, 돌침대 등)의 경우 시공 범위 바깥으로 옮겨 놓으셔야 해당 범위 시공 가능하며, 옮겨 놓지 않으실 경우 손이 닿는 부분까지만 작업합니다.</li>
            <li>설치된 구조물 (싱크대, 시스템장 등)은 시공팀이 해체, 조립할 수 없습니다. 미리 해체하지 않으실 경우 해당 부분은 시공 불가합니다.</li>
            <li>붙박이장 내부, 세탁실, 다용도실, 베란다는 장판 견적에 포함되지 않습니다.</li>
            <li>주차 비용이 있는 건물의 주차비는 지원하지 않습니다. 미리 준비가 안된 상황인 경우 이와 관련하여 추가비용이 발생할 수 있습니다.</li>
            <li>고객이 고지하지 않은 다른 인테리어 공사가 장판 시공과 동시에 진행되어 영향을 미칠 경우, 장판 시공이 취소되거나 견적이 변경될 수 있습니다. 당일 현장에서 취소되는 경우, 그에 따른 위약금이 발생하오니 [시공 일정의 취소 및 예약금 환불 정책]을 꼭 확인하시기 바랍니다.</li>
            <li>시공사 책임으로 발생한 외부 충격에 의한 손상 및 하자는 (찢어짐, 찍힘, 긁힘, 까짐 등 외부 충격요인), 장판이 자리잡는 [3일] 이내 사진과 함께 A/S를 요청해야 합니다. 이 기간을 넘길 시 외부 충격에 의한 손상 원인 증명의 책임은 고객에게 있고, 증명될 경우에만 A/S를 진행합니다.</li>
            <li>A/S가 고객의 요청으로 인해 거절되는 경우에는 다른 방법으로 보상을 진행하지 않으며, A/S 진행 시 부가적으로 발생하는 비용(청소비용, 짐 정리 비용, 교통비용, 시간 소요에 의한 기회비용 등)들이 지원되지는 않습니다.</li>
            <li>장판 시공시 기존 장판 제거를 요청하시거나, 부득이하게 기존 장판을 제거해야만 진행이 가능한 경우, 폐장판은 별도 비용 없이 수거해 갑니다.</li>
        </ul>
        <p>단, 예외상황은 아래와 같습니다.</p>
        <ul>
            <li>기존 장판 2겹인 경우: 2겹 모두 수거하는 경우 추가금이 발생합니다.</li>
            <li>기존 장판이 3겹 이상인 경우: 3겹 이상을 수거해야하는 경우 추가금 발생 및 일정 조정이 필요할 수 있습니다.</li>
            <li>특수 장판(4.5mm 이상 장판, 한지 종이 장판, 압착 장판, AP장판)인 경우: 수거 및 철거를 위해서는 전문 철거팀이 필요합니다. 일정 조정 및 철거 비용이 발생합니다.</li>
            <li>때문에 기존 장판을 걷어내는 것을 원하는 고객님은 반드시 기존 바닥재를 확인해 주셔야, 예상치 못한 일정 변경과 비용 지출(일정 변경 비용, 추가 철거 비용 등)을 막을 수 있습니다.</li>
            <li>현장상황에 따라, 기존 바닥재 위에 장판을 시공하거나, 기존 바닥재가 모노륨장판인 경우 걷어내고 시공합니다. 다른 자재나 여러 겹으로 장판이 시공되어 있는 경우 추가금액이 발생할 수 있습니다.</li>
            <li>시공 당일 현장 상황이 시공하기 불가능한 현장이라고 판단하는 경우 (바닥에 페인트가 시공되어 있는 경우, 압착장판이 시공되어 있는 경우, 바닥에 습기가 차 있는 경우, 바닥에 균열이나 단차가 심한 경우 등) 시공 일정이 변경되거나 취소될 수 있습니다. 현장에서 취소되는 경우, 그에 따른 위약금이 발생하오니 [시공 일정의 취소 및 예약금 환불 정책]을 꼭 읽어주시기 바랍니다.</li>
            <li>시공자가 당일 시공 환경이 적절하지 않아, 적절한 사전 조치 없이 시공할 경우 명백하게 하자 발생 확률이 매우 높다고 판단할 경우, 현장에서 작업이 취소될 수 있으며, 이에 대한 예약금 환불 정책은 [일정 변경, 취소 및 환불 정책]의 [시공이 불가할 경우의 환불]을 참고해주시기 바랍니다.</li>
        </ul>
        <p>※ 알아두세요.</p>
        <h3>1. 시공 전</h3>
        <ul>
            <li>장판 선택은 시공 5일 전까지 완료되어야 합니다. 정해진 기간 이후 장판 선택이나 변경 시 별도의 배송 비용이 추가로 발생합니다.</li>
            <li>시공 5일 전까지 장판 종류 및 시공 위치, 상세 주소, 출입 번호를 다시 한 번 확인해주시기 바랍니다. (자재 배송 및 시공팀 출입 목적)</li>
            <li>공사 전 변동 사항은 시공일로부터 7일 전까지 통보가 되어야 합니다. 이후 변동 사항이 발생할 경우 견적 및 시공 일정이 변경될 수 있습니다. 이에 대한 예약금 환불 정책은 [일정 변경, 취소 및 환불 정책]의 [시공이 불가할 경우의 환불]을 확인하시기 바랍니다. 부득이한 변동 사항이 있을 경우 고객센터로 반드시 연락 주세요.</li>
        </ul>
        <h3>2. 시공 당일</h3>
        <ul>
            <li>시공은 사전에 약속된 시간에 시작됩니다.</li>
            <li>주차 공간을 확보해주시기 바랍니다. 건물의 주차 비용은 전체 비용에 포함되어 있지 않습니다.</li>
            <li>짐이 있는 경우, 최상의 작업 환경에서 시공할 수 있도록 짐을 최대한 정리해 주세요.</li>
            <li>시공 중 촬영된 사진은 제품 선택에 도움이 되는 정보, 혹은 마케팅 용도로 사용될 수 있습니다.</li>
            <li>시공팀은 장판 이외의 영역에 대한 시공을 진행하지 않습니다.</li>
        </ul>
        <h3>3. 시공 후</h3>
        <ul>
            <li>시공 후 ‘1년’간 배상 범위 내의 A/S를 진행합니다. [배상 범위가 아닌 경우: 구조물로 인한 손상 (석고의 뒤틀림 등), 시공 후의 외부 충격에 의한 손상 (찍힘, 찢김), 고객의 귀책사유로 인한 문제 (건조 과정에서의 문제 등), 시간 변화 및 생활 조건에 따른 변색 및 오염, 정상 사용 범위에 준하지 않는 제품 유지 관리 시]</li>
            <li>A/S는 기본적으로 사전에 시공했던 시공 담당자가 진행하며, 다른 시공자로 변경은 불가능합니다.</li>
            <li>시공 당일 결과 확인 후 당일 잔금 입금을 원칙으로 하며, A/S는 잔금이 입금된 순으로 우선 진행합니다.</li>
            <li>견적서, 계약서상에 표시된 가격은 추가 비용 없이 현금영수증, 세금계산서, 카드 결제가 가능합니다.</li>
            <li>장판 시공 후 남은 자재 및 부자재는 모두 회수합니다. 별도로 원하실 경우 추가금이 발생할 수 있습니다.</li>
        </ul>
        <p>※ 정당한 사유 없이 대금 지급이 지연되는 경우, 미 지급액에 대하여 지급일 다음 날로부터 완제일까지 연 12%의 지연이자가 부가됩니다. 단, 잔금 지급에 관한 원칙은 준공일을 기준으로 합니다.</p>
        <ul>
            <li>현금, 유가증권, 귀금속, 예금통장, 신용카드, 인감 등 고객이 휴대할 수 있는 귀중품, 위험품, 불결한 물품 등 다른 화물에 손해를 끼칠 염려가 있는 물건, 동식물, 미술품, 골동품 등 운송에 특수한 관리를 요하는 물건은 치워주세요. 앞에서 언급한 물건에 대한 훼손, 파손, 분실 등에 책임지지 않습니다.</li>
            <li>계약서에 기재된 금액을 초과하여 청구하지 않습니다. 다만, 고객의 책임이 있는 사유로 금액 산정에 관련된 사항이 변경되는 경우, 초과 금액을 미리 고객에게 고지한 후 초과된 금액을 청구할 수 있습니다.</li>
            <li>규정에 의한 금액 이외에 수고비 등의 금액을 추가로 청구하지 않습니다.</li>
            <li>고객의 책임 있는 사유로 계약을 해제한 경우에는 계약금에 해당하는 손해배상액을 지급해야 합니다.</li>
        </ul>
        `,
    },
    {
        title: "마루 시공 계약서",
        children: `
        <p>※ 아래는 ‘시공 가능 여부’에 영향을 미치는 사항이며, 사전연락이 없이 발생한 상황에 대한 책임은 고객에게 있습니다. 의심되는 부분이 있다면 반드시 고객센터로 문의주세요.</p>
        <ul>
            <li>타 인테리어 시공과 일정이 겹치는 경우</li>
            <li>강(화)마루, 데코타일, 타일이 시공되어 있을 경우</li>
            <li>바닥면에 심한 요철이나 굴곡, 크랙이 있는 경우</li>
            <li>바닥면에 페인트나 압착장판, 종이장판(한지장판)이 시공되어있는 경우</li>
            <li>시스템장이 설치되어있는 경우</li>
            <li>엘리베이터가 없거나, 사용할 수 없는 경우 (엘리베이터 사용료, 보양 작업이 필요한 경우)</li>
            <li>거주중 혹은 이사일정이 겹치는 경우</li>
        </ul>
        <p>※ 다음은 시공 기준에 대한 내용입니다. 반드시 확인하시기 바랍니다.</p>
        <ul>
            <li>특이 사항을 통해 전달된 내용은 계약사항과 동일한 효력을 가지니, 재차 확인 부탁드립니다.</li>
            <li>고객님이 제공해주신 중요정보 (전용면적, 시공 범위, 당일 환경)가 실제 시공면적과 차이가 나는 경우, 견적이나 작업 상황에 변경사항이 발생할 수 있습니다.</li>
            <li>시공지(단지내)의 정책에 따라 관리규정에 맞는 사전준비(실내 및 엘레베이터 보양, 입주민 동의서 등)는 별도로 고객님께서 준비해 주셔야 합니다.</li>
            <li>마루 자재 (강마루, 강화마루 등 모든 마루 바닥재)가 기존 장판을 제거한 후 시공될 경우, 두께차이로 인해 종종 방문이 닫히지 않을 수 있습니다. 해당 경우, 문을 갈아내는 작업이 부수적으로 진행되며, 이에 따른 추가금이 있으니 해당 상황이 발생할 경우 고객센터로 문의해주시기 바랍니다.</li>
            <li>시공자가 현장상황이 시공하기 불가능한 현장이라고 판단하는 경우 (바닥에 페인트가 시공되어 있는 경우, 압착장판이 시공되어있는 경우, 바닥에 습기가 차 있는 경우, 바닥에 균열이나 단차가 심한경우) 시공일정이 변경되거나 취소될 수 있습니다. 현장에서 취소되는 경우, 그에 따른 위약금이 발생하오니 [시공일정의 취소 및 예약금 환불정책]을 꼭 읽어주시기 바랍니다.</li>
            <li>시공자가 당일 시공환경이 적절하지 않아, 적절한 사전 조치 없이 시공할 경우 명백하게 하자 발생확률이 매우 높다고 판단할 경우, 현장에서 작업이 취소될 수 있으며, 이에 대한 예약금 환불 정책은 [일정변경, 취소 및 환불정책]의 [시공이 불가할 경우의 환불]을 참고해주시기 바랍니다.</li>
            <li>아파트 구조적인 문제 (바닥의 높은 습도, 누수, 바닥 경사 등)로 인해 발생하는 문제는 A/S에 해당하지 않습니다.</li>
            <li>마루 및 철거작업은 먼지와 소음이 많이 발생하는 시공입니다. 따라서, 거주 중이거나 짐이 있는 상태에서의 마루 시공은 진행하지 않습니다. 빈집에서 시공 이후에도 고객님이 먼지를 털어주는 간단한 청소, 혹은 입주 청소가 병행되어야 합니다.</li>
            <li>아울러, 공사 전 관리소 및 인근 거주자들의 동의를 받으셔야 하며, 해당 과정은 일반적으로 아파트 관리사무소를 통하여 진행이 가능합니다.</li>
            <li>타업체에서 미리 철거를 진행하신 경우, 바닥의 미장 상태가 좋지 않다면 평탄화 작업이 필요하며, 이는 별도의 추가 비용이 발생합니다. 원천적으로 계약 시 특별한 요구가 없는 상황에서, 바닥면의 굴곡을 없애는 미장 작업 (퍼티, 빠데, 핸디코트, 샌딩)은 시공 영역에서 제외됩니다.</li>
            <li>철거 작업은 평탄화 작업을 포함합니다.</li>
            <li>곰팡이 발생의 근본을 제거하기 위해서는 단열 공사가 별도로 진행되어야 합니다. 단열 공사는 마루 시공의 영역에서 제외됩니다.</li>
            <li>시공팀은 콘센트, 조명장치 교체, 내부 청소 등을 포함한 모든 마루 시공 이외의 작업은 진행하지 않습니다.</li>
            <li>대형 가구(장롱, 돌침대 등)의 경우 시공 범위 바깥으로 옮겨 놓으셔야 해당 범위 시공 가능하며, 옮겨 놓지 않으실 경우 손이 닿는 부분까지만 작업합니다.</li>
            <li>설치된 구조물 (싱크대, 시스템장 등)은 시공팀이 해체, 조립할 수 없습니다. 미리 해체하지 않으실 경우 해당 부분은 시공 불가합니다.</li>
            <li>붙박이장 내부, 세탁실, 다용도실, 베란다는 마루 견적에 포함되지 않습니다.</li>
            <li>대형 가구 (장롱, 돌침대 등)는 이동이 불가합니다. 따라서 해당 경우에는 손이 닿는 부분까지만 작업합니다.</li>
            <li>주차 비용이 있는 건물의 주차비는 지원하지 않습니다. 미리 준비가 안된 상황인 경우 이와 관련하여 추가 비용이 발생할 수 있습니다.</li>
            <li>A/S가 고객의 요청으로 인해 거절되는 경우에는 다른 방법으로 보상을 진행하지 않으며, A/S 진행 시 부가적으로 발생하는 비용(청소 비용, 짐 정리 비용, 교통 비용, 시간 소요에 의한 기회 비용 등)들이 지원되지는 않습니다.</li>
            <li>고객이 고지하지 않은 다른 인테리어 공사가 마루 시공과 동시에 진행되어 영향을 미칠 경우, 마루 시공이 취소되거나 견적이 변경될 수 있습니다. 당일 현장에서 취소되는 경우, 그에 따른 위약금이 발생하오니 [시공 일정의 취소 및 예약금 환불 정책]을 꼭 확인하시기 바랍니다.</li>
            <li>시공사 책임으로 발생한 외부 충격에 의한 손상 및 하자는 (찢어짐, 찍힘, 긁힘, 까짐 등 외부 충격 요인), 마루 시공 후 [3일] 이내 사진과 함께 A/S를 요청해야 합니다. 이 기간을 넘길 시 외부 충격에 의한 손상 원인 증명의 책임은 고객에게 있고, 증명될 경우에만 A/S를 진행합니다.</li>
            <li>마루 시공 시 발생하는 폐기물은 수거하며, 별도 비용이 발생하지 않습니다. 단, 마루 보호를 위해 사용된 보양지는 수거하지 않습니다.</li>
        </ul>
        <p>※ 알아두세요.</p>
        <h3>1. 시공 전</h3>
        <ul>
            <li>공사 전, 관리소 및 인근 거주자의 동의를 필수로 받으셔야 합니다.</li>
            <li>마루선택은 시공 5일 전까지 완료되어야 합니다. 정해진 기간 이후 마루 선택이나 변경 시 별도의 배송 비용이 추가로 발생합니다.</li>
            <li>시공 5일 전까지 마루 종류 및 시공 위치, 상세 주소, 출입 번호를 다시 한 번 확인해주시기 바랍니다. (자재 배송 및 시공팀 출입 목적)</li>
            <li>공사 전 변동사항은 시공일로부터 7일 전까지 통보가 되어야 합니다. 이후 변동사항이 발생할 경우 견적 및 시공 일정이 변경될 수 있습니다. 이에 대한 예약금 환불 정책은 [일정 변경, 취소 및 환불정책]의 [시공이 불가할 경우의 환불]을 확인하시기 바랍니다. 부득이한 변동사항이 있을 경우 고객센터로 반드시 연락주세요.</li>
        </ul>
        <h3>2. 시공 당일</h3>
        <ul>
            <li>시공은 사전에 약속된 시간에 시작됩니다.</li>
            <li>주차공간을 확보해주시기 바랍니다. 건물의 주차비용은 전체 비용에 포함되어있지 않습니다.</li>
            <li>시공 중 촬영된 사진은 제품 선택에 도움이 되는 정보, 혹은 마케팅 용도로 사용될 수 있습니다.</li>
            <li>계약 이외의 범위에 대한 추가 시공은 진행될 수 없습니다.</li>
            <li>시공팀은 마루 이외의 영역에 대한 시공을 일체 진행하지 않습니다.</li>
        </ul>
        <h3>3. 시공 후</h3>
        <ul>
            <li>시공 후 ‘1년’간 배상 범위 내의 A/S를 진행합니다. [배상 범위가 아닌 경우: 구조물로 인한 손상 (석고의 뒤틀림 등), 시공 후의 외부 충격에 의한 손상 (찍힘, 찢김), 고객의 귀책사유로 인한 문제 (건조 과정에서의 문제 등), 시간 변화 및 생활 조건에 따른 변색 및 오염, 정상 사용 범위에 준하지 않는 제품 유지 관리 시]</li>
            <li>A/S는 기본적으로 사전에 시공했던 시공 담당자가 진행하며, 다른 시공자로 변경은 불가능합니다.</li>
            <li>시공 당일 결과 확인 후 당일 잔금 입금을 원칙으로 하며, A/S는 잔금이 입금된 순으로 우선 진행합니다.</li>
            <li>견적서, 계약서상에 표시된 가격은 추가 비용 없이 현금영수증, 세금계산서, 카드결제가 가능합니다.</li>
            <li>마루 시공 후 남은 자재 및 부자재는 모두 회수합니다. 별도로 원하실 경우 추가금이 발생할 수 있습니다.</li>
            <li>정당한 사유 없이 대금 지급이 지연되는 경우, 미 지급액에 대하여 지급일 다음 날로부터 완제일까지 연 12%의 지연이자가 부가됩니다. 단, 잔금 지급에 관한 원칙은 준공일을 기준으로 합니다.</li>
            <li>현금, 유가증권, 귀금속, 예금통장, 신용카드, 인감 등 고객이 휴대할 수 있는 귀중품, 위험품, 불결한 물품 등 다른 화물에 손해를 끼칠 염려가 있는 물건, 동식물, 미술품, 골동품 등 운송에 특수한 관리를 요하는 물건은 치워주세요. 앞에서 언급한 물건에 대한 훼손, 파손, 분실 등에 책임지지 않습니다.</li>
            <li>계약서에 기재된 금액을 초과하여 청구하지 않습니다. 다만, 고객의 책임이 있는 사유로 금액 산정에 관련된 사항이 변경되는 경우, 초과 금액을 미리 고객에게 고지한 한 뒤 초과된 금액을 청구할 수 있습니다.</li>
            <li>규정에 의한 금액 이외에 수고비 등의 금액을 추가로 청구하지 않습니다.</li>
            <li>고객의 책임 있는 사유로 계약을 해제한 경우에는 계약금에 해당하는 손해배상액을 지급해야 합니다.</li>
        </ul>
    `,
    },
    {
        title: "철거 시공 계약서",
        children: `
        <p>※ 아래는 ‘시공 가능 여부’에 영향을 미치는 사항이며, 사전연락이 없이 발생한 상황에 대한 책임은 고객에게 있습니다. 의심되는 부분이 있다면 반드시 고객센터로 문의주세요.</p>
        <ul>
            <li>타 인테리어 시공과 일정이 겹치는 경우</li>
            <li>철거할 자재가 여러겹 겹쳐져 있어, 겹쳐져 있는 모든 자재를 철거해야하는 경우</li>
            <li>HDF(원목가루를 압착시킨목재)소재의 강마루를 본드로 시공하는 제품(ex 동화나투스진, 한솔SB마루 등)이 시공된 경우 *자세한 사항은 본문 내용을 참고하세요.</li>
            <li>바닥면에 심한 요철이나 굴곡, 크랙이 있는 경우</li>
            <li>바닥면에 페인트나 압착장판이 시공되어있는 경우</li>
            <li>시스템장이 설치되어있는 경우</li>
            <li>엘리베이터가 없거나, 사용할 수 없는 경우 (엘리베이터 사용료, 보양 작업이 필요한 경우)</li>
            <li>거주중 혹은 이사일정이 겹치는 경우</li>
            <li>후에 도배 시공이 없는 경우</li>
        </ul>
        <p>※ 다음은 시공 기준에 대한 내용입니다. 반드시 확인하시기 바랍니다.</p>
        <ul>
            <li>특이 사항을 통해 전달된 내용은 계약사항과 동일한 효력을 가지니, 재차 확인 부탁드립니다.</li>
            <li>고객님이 제공해주신 중요정보 (전용면적, 시공 범위, 당일 환경)가 실제 시공면적과 차이가 나는 경우, 견적이나 작업 상황에 변경사항이 발생할 수 있습니다.</li>
            <li>시공지(단지내)의 정책에 따라 관리규정에 맞는 사전준비(실내 및 엘레베이터 보양, 입주민 동의서 등)는 별도로 고객님께서 준비해 주셔야 합니다.</li>
            <li>문턱 제거 작업을 하게 될 경우 문틀 부분의 손상이 발생할 수 있습니다. 또한 문턱 제거 이후 바닥 미장 작업을 진행하지 않습니다. 이후 바닥재 시공 시 미장 비용이 추가 될 수 있습니다.</li>
            <li>시공자가 현장상황이 시공하기 불가능한 현장이라고 판단하는 경우 (일반적인 마루, 데코타일 등의 바닥재가 아닌 경우) 시공일정이 변경되거나 취소될 수 있습니다. 현장에서 취소되는 경우, 그에 따른 위약금이 발생하오니 [시공일정의 취소 및 예약금 환불정책]을 꼭 읽어주시기 바랍니다.</li>
            <li>아파트 구조적인 문제 (바닥의 높은 습도, 누수 등)로 인해 발생하는 문제는 A/S에 해당하지 않습니다.</li>
            <li>마루 및 철거작업은 먼지와 소음이 많이 발생하는 시공입니다. 따라서, 거주중이거나 짐이 있는 상태에서의 철거 시공은 진행하지 않습니다. 빈집에서 시공 이후에도 고객님이 먼지를 털어주는 간단한 청소, 혹은 입주청소가 병행되어야 합니다.</li>
            <li>아울러, 공사 전 관리소 및 인근 거주자들의 동의를 받으셔야 하며, 해당 과정은 일반적으로 아파트 관리사무소를 통하여 진행이 가능합니다.</li>
            <li>원천적으로 계약시 특별한 요구가 없는 상황에서, 바닥면의 굴곡을 없애는 미장작업 (퍼티, 빠데, 핸디코트, 샌딩)은 시공영역에서 제외됩니다.</li>
            <li>강마루 철거작업을 진행할 경우, 별도의 추가비용 없이 철거 후 기본적인 본드자국 제거를 위한 평탄화 작업을 진행합니다. 평탄화 작업은 철거 이후 시공할 바닥재 종류에 맞추어 진행됩니다. 단, 바닥 굴곡이 심하여 발생하는 평탄화는 별도 작업으로 분류됩니다.</li>
            <li>HDF(원목가루를 압착시킨 목재) 소재의 강마루를 본드로 시공하는 제품(ex 동화나투스진, 한솔SB마루 등)은 철거시 많은 양의 잔해물이 남기 때문에 추가 샌딩비용이 발생합니다. 대략 평당 2만원의 추가금액이 발생합니다.</li>
            <li>강마루 하단에 맥반석이 붙어있는 마루의 경우 추가금이 발생합니다.</li>
            <li>곰팡이 발생의 근본을 제거하기 위해서는 단열 공사가 별도로 진행되어야 합니다. 단열 공사는 철거 시공의 영역에서 제외됩니다.</li>
            <li>시공팀은 콘센트, 조명장치 교체, 내부 청소 등을 포함한 모든 철거 시공 이외의 작업은 진행하지 않습니다.</li>
            <li>시스템장, 장식장 등 기존 구조물이 시공지에 설치가 되어있는 경우 구조물 하단 부분은 철거 진행이 어렵습니다. 철거팀은 기존 구조물 관련하여 별도의 해체 및 조립 작업을 진행하지 않습니다. 해당 부분 철거 시공을 희망하시는 경우 미리 구조물 등을 해체하여 주셔야 합니다.</li>
            <li>철거 진행 시 바닥이 다소 손상될 수 있습니다. 특히, 미장의 두께가 얇은 경우 난방 배관(=엑셀 파이프)이 손상되는 경우가 발생합니다. 이 경우 철거팀에서 배관 보수 작업을 진행합니다. 단, 난방 배관이 동파이프 소재로 되어있을 경우, 손상될 경우 용접비용이 발생하며, 이후 일정이 다소 변경될 수 있습니다. 난방이때 미장작업을 요청하실 경우 추가비용이 발생할 수 있습니다. 또한 미장작업으로 인해 이후 일정이 다소 변경될 수 있습니다.</li>
            <li>발코니 확장을 위해 바닥 난방 공사를 진행하는 경우, 확장된 공간의 난방공사와 관련된 하자는 시공 후 1년간 배상 범위 내의 A/S를 진행합니다.</li>
            <li>붙박이장 내부, 세탁실, 다용도실, 베란다는 철거 견적에 포함되지 않습니다.</li>
            <li>대형가구(장롱, 돌침대 등)의 경우 시공 범위 바깥으로 옮겨 놓으셔야 해당 범위 시공 가능하며, 옮겨 놓지 않으실 경우 손이 닿는 부분까지만 작업합니다.</li>
            <li>설치된 구조물 (싱크대, 시스템장 등)은 시공팀이 해체, 조립할 수 없습니다. 미리 해체하지 않으실 경우 해당 부분은 시공 불가합니다.</li>
            <li>주차 비용이 있는 건물의 주차비는 지원하지 않습니다. 미리 준비가 안된 상황인 경우 이와 관련하여 추가비용이 발생할 수 있습니다.</li>
            <li>고객이 고지하지 않은 다른 인테리어 공사가 철거 시공과 동시에 진행되어 영향을 미칠 경우, 철거 시공이 취소되거나 견적이 변경될 수 있습니다. 당일 현장에서 취소되는 경우, 그에 따른 위약금이 발생하오니 [시공 일정의 취소 및 예약금 환불 정책]을 꼭 확인하시기 바랍니다.</li>
            <li>시공사 책임으로 발생한 외부 충격에 의한 손상 및 하자는 (찢어짐, 찍힘, 긁힘, 까짐 등 외부 충격요인), 철거 시공 후 [3일] 이내 사진과 함께 A/S를 요청해야 합니다. 이 기간을 넘길 시 외부 충격에 의한 손상 원인 증명의 책임은 고객에게 있고, 증명될 경우에만 A/S를 진행합니다.</li>
            <li>A/S가 고객의 요청으로 인해 거절되는 경우에는 다른 방법으로 보상을 진행하지 않으며, A/S 진행 시 부가적으로 발생하는 비용(청소비용, 짐 정리 비용, 교통비용, 시간 소요에 의한 기회비용 등)들이 지원되지는 않습니다.</li>
            <li>시공자가 당일 시공환경이 적절하지 않아, 적절한 사전 조치 없이 시공할 경우 명백하게 하자 발생확률이 매우 높다고 판단할 경우, 현장에서 작업이 취소될 수 있으며, 이에 대한 예약금 환불 정책은 [일정변경, 취소 및 환불정책]의 [시공이 불가할 경우의 환불]을 참고해주시기 바랍니다.</li>
        </ul>
        <p>※ 알아두세요.</p>
        <h3>1. 시공 전</h3>
        <ul>
            <li>공사 전, 관리소 및 인근 거주자의 동의를 필수로 받으셔야 합니다. 위 과정을 대신 진행해드리지 않습니다.</li>
            <li>시공 5일 전까지 시공위치, 상세주소, 출입번호를 다시 한 번 확인해주시기 바랍니다. (시공팀 출입 목적)</li>
            <li>공사 전 변동사항은 시공일로부터 7일 전까지 통보가 되어야 합니다. 이후 변동사항이 발생할 경우 견적 및 시공 일정이 변경될 수 있습니다. 이에 대한 예약금 환불 정책은 [일정 변경, 취소 및 환불정책]의 [시공이 불가할 경우의 환불]을 확인하시기 바랍니다. 부득이한 변동사항이 있을 경우 고객센터로 반드시 연락주세요.</li>
        </ul>
        <h3>2. 시공 당일</h3>
        <ul>
            <li>시공은 사전에 약속된 시간에 시작됩니다.</li>
            <li>주차공간을 확보해주시기 바랍니다. 건물의 주차비용은 전체 비용에 포함되어있지 않습니다.</li>
            <li>시공 중 촬영된 사진은 제품선택에 도움이 되는 정보, 혹은 마케팅 용도로 사용될 수 있습니다.</li>
            <li>계약 이외의 범위에 대한 추가 시공은 진행될 수 없습니다.</li>
            <li>시공팀은 사전 약속된 영역 외의 시공을 일체 진행하지 않습니다.</li>
        </ul>
        <h3>3. 시공 후</h3>
        <ul>
            <li>시공 후 ‘1년’간 배상 범위 내의 A/S를 진행합니다. [배상 범위가 아닌 경우: 구조물로 인한 손상 (석고의 뒤틀림 등), 시공 후의 외부 충격에 의한 손상 (찍힘, 찢김), 고객의 귀책사유로 인한 문제 (건조 과정에서의 문제 등), 시간 변화 및 생활 조건에 따른 변색 및 오염, 정상 사용 범위에 준하지 않는 제품 유지 관리시]</li>
            <li>A/S는 기본적으로 사전에 시공했던 시공 담당자가 진행하며, 다른 시공자로 변경은 불가능합니다.</li>
            <li>시공 당일 결과 확인 후 당일 잔금 입금을 원칙으로 하며, A/S는 잔금이 입금된 순으로 우선 진행합니다.</li>
            <li>견적서, 계약서상에 표시된 가격은 추가비용 없이 현금영수증, 세금계산서, 카드결제가 가능합니다.</li>
        </ul>
        <p>※ 정당한 사유 없이 대금 지급이 지연되는 경우, 미 지급액에 대하여 지급일 다음 날로부터 완제일까지 연 12%의 지연이자가 부가됩니다. 단, 잔금 지급에 관한 원칙은 준공일을 기준으로 합니다.</p>
        <ul>
    <li>현금, 유가증권, 귀금속, 예금통장, 신용카드, 인감 등 고객이 휴대할 수 있는 귀중품, 위험품, 불결한 물품 등 다른 화물에 손해를 끼칠 염려가 있는 물건, 동식물, 미술품, 골동품 등 운송에 특수한 관리를 요하는 물건은 치워주세요. 앞에서 언급한 물건에 대한 훼손, 파손, 분실 등에 책임지지 않습니다.</li>
    <li>계약서에 기재된 금액을 초과하여 청구하지 않습니다. 다만, 고객의 책임이 있는 사유로 금액 산정에 관련된 사항이 변경되는 경우, 초과금액을 미리 고객에게 고지한 한 뒤 초과된 금액을 청구할 수 있습니다.</li>
    <li>규정에 의한 금액 이외에 수고비 등의 금액을 추가로 청구하지 않습니다.</li>
    <li>고객의 책임 있는 사유로 계약을 해제한 경우에는 계약금에 해당하는 손해배상액을 지급해야 합니다.</li>
</ul>
        `,
    },
    {
        title: "취소/변경/환불 규정",
        children: `
        <p>※ 계약의 확정</p>
        <ul>
            <li>계약금 결제 전에는 계약이 확정된 것이 아닙니다.</li>
            <li>계약금 결제기한 이후 사전통보 없이 계약대기명단에서 제외될 수 있습니다.</li>
        </ul>
        <p>※ 시공일정의 변경 및 취소 관련</p>
        <p>시공일로부터 8일 전까지, 위약금 없는 일정 변경 및 시공 취소가 가능합니다. 일정 변경 및 취소는 전화 혹은 내방을 통해 이루어져야 하며, SNS, 문자, 이메일을 통한 의사전달은 유효하지 않습니다. 또한, 예약이 가득 찬 일자로는 변경은 불가능합니다.</p>
        <p>아래는 위약금 발생 기준입니다.</p>
        <ul>
            <li>시공일 5일전 : 계약금 100% 환불</li>
            <li>시공일 4일전 : 계약금 환불 불가</li>
        </ul>
        <p>※ 시공이 불가할 경우의 환불</p>
        <ul>
            <li>고객 확인서에 기재된 내용과 현장상황이 상이하여 시공이 불가할 경우, 시공이 취소될 수 있으며, 이 경우 계약금 환불은 불가합니다.</li>
            <li>기후변화와 천재지변 등의 이유로 시공이 취소될 경우, 일정변경 혹은 계약금 전액 환불이 가능합니다.</li>
        </ul>
    `,
    },
];
export const AgreePop = ({
    isOpen,
    setIsOpen,
    heightChange,
    type = 1,
    Price,
    Date,
    Time,
    agreeClk,
    setAgreeClk,
    agreeDate,
    sigongIdx,
}) => {
    const contentRef = useRef(null);
    const agreeDateRef = useRef(null);
    const sendAgreeDate = usePostData(ApiData.sendAgreeDate);

    const { setPopTitle, isPopup, setPopup, setPopContent, setSubmitClk } =
        usePopup();

    const sendAgreeDateApi = () => {
        let sender = {
            sigongIdx: sigongIdx,
        };
        if (type === 1) {
            sender.dobaeAgreeDate = dayjs()
                .tz("Asia/Seoul")
                .format("YYYY-MM-DD HH:mm:ss");
        } else if (type === 2) {
            sender.zangpanAgreeDate = dayjs()
                .tz("Asia/Seoul")
                .format("YYYY-MM-DD HH:mm:ss");
        } else if (type === 3) {
            sender.maruAgreeDate = dayjs()
                .tz("Asia/Seoul")
                .format("YYYY-MM-DD HH:mm:ss");
        } else if (type === 4) {
            sender.demolitionAgreeDate = dayjs()
                .tz("Asia/Seoul")
                .format("YYYY-MM-DD HH:mm:ss");
        } else if (type === 5) {
            sender.paybackAgreeDate = dayjs()
                .tz("Asia/Seoul")
                .format("YYYY-MM-DD HH:mm:ss");
        }
        sendAgreeDate
            .mutateAsync(sender)
            .then((data) => {
                setPopup(false);
                setAgreeClk(true);
                setIsOpen(false);
                toast.success(
                    <div
                        style={{
                            fontSize: "14px",
                            lineHeight: "19px",
                        }}
                    >
                        {parseInt(type) === 1
                            ? "'도배 고객 계약사항'"
                            : parseInt(type) === 2
                            ? "'장판 고객 계약사항'"
                            : parseInt(type) === 3
                            ? "'마루 고객 계약사항'"
                            : parseInt(type) === 4
                            ? "'철거 고객 계약사항'"
                            : "'일정변경 취소 및 환불 정책'"}
                        에 동의하셨습니다.
                    </div>,
                    {
                        className: "custom-toast-error",
                        position: "bottom-center",
                    }
                );
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const ClkBtn = () => {
        setPopup(!isPopup);
        setPopTitle(
            parseInt(type) === 1
                ? "도배 고객 계약사항"
                : parseInt(type) === 2
                ? "장판 고객 계약사항"
                : parseInt(type) === 3
                ? "마루 고객 계약사항"
                : parseInt(type) === 4
                ? "철거 고객 계약사항"
                : "일정변경 취소 및 환불 정책"
        );
        setPopContent("계약에 동의하시겠습니까?");
        setSubmitClk(() => {
            sendAgreeDateApi();
        });
    };

    useEffect(() => {
        const scrollHeight = contentRef.current.scrollHeight;
        const clientHeight = contentRef.current.clientHeight;

        if (isOpen) {
            console.log("isOpen");
            contentRef.current.scrollTop = 0;
            if (clientHeight === scrollHeight) {
                console.log("equal height");
                if (agreeDateRef.current) {
                    console.log("is current");
                    agreeDateRef.current.style.display = "flex";
                    console.log(
                        "is current",
                        agreeDateRef.current.style.display
                    );
                }
            } else {
                console.log("not equal height");
                if (agreeDateRef.current) {
                    agreeDateRef.current.style.display = "none";
                }
            }
        }
    }, [isOpen]);
    useEffect(() => {
        const handleScroll = () => {
            if (contentRef.current) {
                const scrollTop = contentRef.current.scrollTop;
                const scrollHeight = contentRef.current.scrollHeight;
                const clientHeight = contentRef.current.clientHeight;

                const scrollPercentage =
                    (scrollTop / (scrollHeight - clientHeight)) * 100;

                if (scrollPercentage >= 75) {
                    if (agreeDateRef.current) {
                        agreeDateRef.current.style.display = "flex";
                    }
                } else {
                    if (agreeDateRef.current) {
                        if (clientHeight === scrollHeight) {
                            return;
                        }
                        agreeDateRef.current.style.display = "none";
                    }
                }
            }
        };

        if (contentRef.current) {
            contentRef.current.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (contentRef.current) {
                contentRef.current.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        console.log("heightChange", heightChange);
    }, [heightChange]);
    return (
        <div
            className="overlay"
            style={{
                display: isOpen ? "flex" : "none",
            }}
        >
            <div
                style={{
                    height: "155px",
                    position: "fixed",
                    top: "0",
                    width: "100%",
                    // zIndex: "1000000",
                }}
                onClick={() => setIsOpen(!isOpen)}
            ></div>
            <div className="popTop">
                <img src={imageData.warning} />
                <div className="popTopText">
                    <p>
                        <span>계약서</span>를 꼼꼼히 읽은 뒤
                    </p>
                    <p>
                        <span>화면 하단의 동의하기 버튼</span>을 눌려주세요
                    </p>
                </div>
            </div>
            <div
                className="LongPopup"
                style={{
                    top: "155px",
                    height: `calc(${heightChange}px - 155px)`,
                    minHeight: "unset",
                }}
            >
                <div
                    style={{ justifyContent: "space-between" }}
                    className="popupTitle"
                >
                    {type === 1 ? (
                        <h1>도배 고객 계약사항</h1>
                    ) : type === 2 ? (
                        <h1>장판 고객 계약사항</h1>
                    ) : type === 3 ? (
                        <h1>마루 고객 계약사항</h1>
                    ) : type === 4 ? (
                        <h1>철거 고객 계약사항</h1>
                    ) : (
                        <h1>일정변경 취소 및 환불 정책</h1>
                    )}
                    <img
                        style={{ cursor: "pointer" }}
                        onClick={() => setIsOpen(!isOpen)}
                        src={imageData.exit}
                        alt="exit"
                    />
                </div>
                <div
                    className="AgreePopContent"
                    ref={contentRef}
                    style={{
                        height: `calc(${heightChange}px - 220px)`,
                    }}
                >
                    <div className="AgreePopContentText">
                        <p>고객님의 소중한 집을 위한 계약 내용입니다.</p>
                        <p>반드시 꼼꼼히 검토해주세요.</p>
                    </div>
                    <div className="AgreePopContentMain">
                        {type !== 5 && (
                            <>
                                <div className="title">
                                    {type === 1 ? (
                                        <p>계약한 도배 시공 정보</p>
                                    ) : type === 2 ? (
                                        <p>계약한 장판 시공 정보</p>
                                    ) : type === 3 ? (
                                        <p>계약한 마루 시공 정보</p>
                                    ) : type === 4 ? (
                                        <p>계약한 철거 시공 정보</p>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="sigongInfoWrap">
                                    <div className="sigongInfo">
                                        {type === 1 ? (
                                            <h2>도배 시공</h2>
                                        ) : type === 2 ? (
                                            <h2>장판 시공</h2>
                                        ) : type === 3 ? (
                                            <h2>마루 시공</h2>
                                        ) : type === 4 ? (
                                            <h2>철거 시공</h2>
                                        ) : (
                                            ""
                                        )}

                                        <p>{`${dayjs(Date).format(
                                            "YYYY-MM-DD"
                                        )} (${
                                            consts.dayText[dayjs(Date).day()]
                                        }) ${Time || "-"}${" "}`}</p>
                                    </div>
                                    <h1>
                                        {(Price || 0).toLocaleString()}
                                        <span style={{ marginLeft: "2px" }}>
                                            원
                                        </span>
                                    </h1>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="agreeTextWrap">
                        <div
                            className="agreeText"
                            style={{
                                paddingBottom: "100px",
                            }}
                            dangerouslySetInnerHTML={{
                                __html: AccordionContent[type - 1].children,
                            }}
                        ></div>
                    </div>
                    <div
                        className={`AgreeDateWrap  ${agreeClk ? "active" : ""}`}
                        ref={agreeDateRef}
                        style={{ display: "none" }}
                        onClick={!agreeClk ? ClkBtn : () => setIsOpen(!isOpen)}
                    >
                        {agreeClk ? (
                            <img className="Chk" src={imageData.contractOn} />
                        ) : (
                            <img className="Chk" src={imageData.contractOff2} />
                        )}

                        {/* {window.innerHeight} */}
                        <div className="AgreeDate">
                            {agreeClk ? (
                                <p>{`${dayjs(agreeDate).format(
                                    "YYYY년 MM월 DD일 HH:mm:ss"
                                )}`}</p>
                            ) : (
                                <p>동의 시 계약이 완료됩니다.</p>
                            )}
                            {agreeClk ? (
                                <h4>계약에 동의하셨습니다.</h4>
                            ) : (
                                <h4>계약에 동의합니다.</h4>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
