import { useEffect, useState } from "react";
import { HeaderComp3 } from "../components/Header";
import { ProgressBar } from "../components/progressBar";
import { Button } from "../components/Button";
import { BottomBtn } from "../components/BottomBtn";
import { usePostData } from "../utils/service";
import ApiData from "../libs/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import routes from "../libs/routes";
import { getDecryptedParam } from "../components/navigateWithEncryption";
import useCustomer from "../zustands/useCustomer";

function UserNewPaymentInfo() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const index = encodeURIComponent(searchParams.get("sigongIdx"));
    const decryptIdx = getDecryptedParam("sigongIdx");
    const looking = Boolean(searchParams.get("Looking"));

    const payerIn = usePostData(ApiData.payerIn);
    const [paymentUser, setPaymentUser] = useState("");

    const [movementFlag, setMovementFlag] = useState([]);
    const movementFlagIn = usePostData(ApiData.movementFlagIn);
    const movementFlagVal = usePostData(ApiData.movementFlagVal);
    const sigonginfoDetail = usePostData(ApiData.sigonginfoDetail);

    const { setUseCustomerPaymentName, setUseCustomerContractChk } =
        useCustomer();

    const paymentUserHandler = (e) => {
        setPaymentUser(e.target.value);
    };

    const movementFlagValApi = () => {
        movementFlagVal
            .mutateAsync({ sigongIdx: decryptIdx || index })
            .then(({ data }) => {
                setMovementFlag(data);
            })
            .catch((error) => {});
    };
    const sigonginfoDetailApi = () => {
        sigonginfoDetail
            .mutateAsync({ sigongIdx: decryptIdx || index })
            .then((data) => {
                setUseCustomerPaymentName(data.data.customer_payment_name);
                setUseCustomerContractChk(data.data.customer_payment_chk);
                setPaymentUser(data.data.customer_payment_name);
            })
            .catch((err) => {
                // toast.error(err.response.data);
            });
    };
    const payerInApi = () => {
        let sender = {
            sigongIdx: decryptIdx || index,
            payName: paymentUser,
        };
        if (paymentUser === "") {
            toast.error(
                <div style={{ fontSize: "12px", lineHeight: "19px" }}>
                    결제자명을 입력해주세요.
                </div>,
                {
                    className: "custom-toast-error",
                    position: "bottom-center",
                }
            );
            return;
        }

        payerIn
            .mutateAsync(sender)
            .then((res) => {
                movementFlagIn
                    .mutateAsync({
                        location: 4,
                        sigongIdx: decryptIdx || index,
                    })
                    .then((data) => {
                        navigate(
                            `${routes.UserNewPaymentInfo2}?sigongIdx=${index}`
                        );
                    })
                    .catch((error) => {
                        toast.error(
                            <div
                                style={{
                                    fontSize: "12px",
                                    lineHeight: "19px",
                                }}
                            >
                                오류 발생하였습니다.
                            </div>,
                            {
                                className: "custom-toast-error-login addressIn",
                                position: "bottom-center",
                            }
                        );
                    });
            })
            .catch((err) => {
                toast.error(err.response.data);
            });
    };

    useEffect(() => {
        sigonginfoDetailApi();
        movementFlagValApi();
    }, [index]);
    return (
        <>
            <HeaderComp3 title={"결제정보"} />
            <ProgressBar
                style={{ marginTop: "10px" }}
                progress={4}
                movementFlag={movementFlag}
                index={index}
            />
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "calc(100vh - 190px)",
                    paddingBottom: "70px",
                }}
            >
                <div className="ContentBody">
                    <div className="ContentBodyHeader">
                        <h1>입금자명 입력</h1>
                        <p>입금자명을 동일하게 입력해주세요.</p>
                    </div>
                    <div className="InnerContentWrap">
                        <h1 style={{ fontSize: "12px" }}> 입금자명</h1>
                        <div className="InputWrap ">
                            <input
                                style={{
                                    minHeight: "40px",
                                    borderRadius: "7px",
                                    border: "1px solid #ddd",
                                    padding: "10px",
                                    width: "100%",
                                    fontSize: "12px",
                                }}
                                placeholder={"입금자명을 입력해주세요"}
                                value={paymentUser || ""}
                                onChange={paymentUserHandler}
                            />
                        </div>
                    </div>
                </div>
                <div className="ContentBody">
                    <Button
                        title={"다음"}
                        active={paymentUser ? true : false}
                        // style={{ marginTop: "400px" }}
                        onClick={payerInApi}
                    />
                </div>
            </div>
            <BottomBtn
                active={looking ? 4 : 3}
                movementFlag={movementFlag}
                index={index}
            />
        </>
    );
}

export default UserNewPaymentInfo;
