import { useEffect, useState } from "react";
import { HeaderComp3 } from "../components/Header";
import { ProgressBar } from "../components/progressBar";
import { imageData } from "../libs/images";
import { BottomBtn } from "../components/BottomBtn";
import { AgreePop } from "../components/AgreePop";
import ApiData from "../libs/api";
import { usePostData } from "../utils/service";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../components/Button";
import { toast } from "react-toastify";
import routes from "../libs/routes";
import { getDecryptedParam } from "../components/navigateWithEncryption";
import useCustomer from "../zustands/useCustomer";

function UserNewContract() {
    const navigate = useNavigate();
    const [heightChange, setHeightChange] = useState(window.innerHeight);

    const [searchParams, setSearchParams] = useSearchParams();
    const index = encodeURIComponent(searchParams.get("sigongIdx"));
    const decryptIdx = getDecryptedParam("sigongIdx");
    const looking = Boolean(searchParams.get("Looking"));
    const movementFlagVal = usePostData(ApiData.movementFlagVal);
    const movementFlagIn = usePostData(ApiData.movementFlagIn);
    const sigonginfoDetail = usePostData(ApiData.sigonginfoDetail);
    const [cusmtomPaymentChk, setCusmtomPaymentChk] = useState(0);
    const [customerName, setCustomerName] = useState("");
    const [customerPhone, setCustomerPhone] = useState("");
    const [dobaeAgree, setDobaeAgree] = useState(true);
    const [zangpanAgree, setZangpanAgree] = useState(false);
    const [maruAgree, setMaruAgree] = useState(false);
    const [demolitionAgree, setDemolitionAgree] = useState(false);
    const [payBackAgree, setPayBackAgree] = useState(false);
    const [agreePop, setAgreePop] = useState(false);
    const [type, setType] = useState(1);
    const [dobaeOn, setDobaeOn] = useState(false);
    const [dobaeAgreeDate, setDobaeAgreeDate] = useState("");
    const [dobaePrice, setDobaePrice] = useState(0);
    const [dobaeDate, setDobaeDate] = useState("");
    const [dobaeTime, setDobaeTime] = useState("");
    const [zangpanOn, setZangpanOn] = useState(false);
    const [zangpanAgreeDate, setZangpanAgreeDate] = useState("");
    const [zangpanPrice, setZangpanPrice] = useState(0);
    const [zangpanDate, setZangpanDate] = useState("");
    const [zangpanTime, setZangpanTime] = useState("");
    const [maruOn, setMaruOn] = useState(false);
    const [maruAgreeDate, setMaruAgreeDate] = useState("");
    const [maruPrice, setMaruPrice] = useState(0);
    const [maruDate, setMaruDate] = useState("");
    const [maruTime, setMaruTime] = useState("");
    const [demolitionAgreeDate, setDemolitionAgreeDate] = useState("");
    const [demolitionPrice, setDemolitionPrice] = useState(0);
    const [demolitionDate, setDemolitionDate] = useState("");
    const [demolitionTime, setDemolitionTime] = useState("");
    const [paybackAgreeDate, setPaybackAgreeDate] = useState("");

    const [movementFlag, setMovementFlag] = useState([]);
    const [allAgree, setAllAgree] = useState(false);

    const { setUseCustomerPaymentName, setUseCustomerContractChk } =
        useCustomer();

    const GoNext = () => {
        const agreements = [
            {
                condition: dobaeOn === 1 && !dobaeAgree,
                message: "도배 계약을 체결해주세요.",
            },
            {
                condition: zangpanOn === 1 && !zangpanAgree,
                message: "장판 계약을 체결해주세요.",
            },
            {
                condition: maruOn === 1 && !maruAgree,
                message: "마루 계약을 체결해주세요.",
            },
            {
                condition:
                    maruOn === 1 && demolitionPrice !== 0 && !demolitionAgree,
                message: "철거 계약을 체결해주세요.",
            },
            { condition: !payBackAgree, message: "환불규정을 체결해주세요." },
        ];

        for (const agreement of agreements) {
            if (agreement.condition) {
                toast.error(
                    <div style={{ fontSize: "12px", lineHeight: "19px" }}>
                        {agreement.message}
                    </div>,
                    {
                        className: "custom-toast-error",
                        position: "bottom-center",
                    }
                );
                return;
            }
        }
        movementFlagIn
            .mutateAsync({ location: 2, sigongIdx: decryptIdx || index })
            .then((data) => {
                navigate(`${routes.UserNewAddressIn}?sigongIdx=${index}`);
            })
            .catch((error) => {
                // toast.error("오류가 발생하였습니다.");
            });
    };

    const getPrice = () => {
        switch (type) {
            case 1:
                return dobaePrice;
            case 2:
                return zangpanPrice;
            case 3:
                return maruPrice;
            case 4:
                return demolitionPrice;
            default:
                return null;
        }
    };

    const getDate = () => {
        switch (type) {
            case 1:
                return dobaeDate;
            case 2:
                return zangpanDate;
            case 3:
                return maruDate;
            case 4:
                return demolitionDate;
            default:
                return null;
        }
    };
    const getTime = () => {
        switch (type) {
            case 1:
                return dobaeTime;
            case 2:
                return zangpanTime;
            case 3:
                return maruTime;
            case 4:
                return demolitionTime;
            default:
                return null;
        }
    };

    const getAgreeClk = () => {
        switch (type) {
            case 1:
                return dobaeAgree;
            case 2:
                return zangpanAgree;
            case 3:
                return maruAgree;
            case 4:
                return demolitionAgree;
            case 5:
                return payBackAgree;
            default:
                return null;
        }
    };

    const getSetAgreeClk = () => {
        switch (type) {
            case 1:
                return setDobaeAgree;
            case 2:
                return setZangpanAgree;
            case 3:
                return setMaruAgree;
            case 4:
                return setDemolitionAgree;
            case 5:
                return setPayBackAgree;
            default:
                return null;
        }
    };

    const getAgreeDate = () => {
        switch (type) {
            case 1:
                return dobaeAgreeDate;
            case 2:
                return zangpanAgreeDate;
            case 3:
                return maruAgreeDate;
            case 4:
                return demolitionAgreeDate;
            case 5:
                return paybackAgreeDate;
            default:
                return null;
        }
    };

    const agreePopOpen = (type) => {
        setType(type);
        setAgreePop(true);
    };
    const movementFlagValApi = () => {
        movementFlagVal
            .mutateAsync({ sigongIdx: decryptIdx || index })
            .then(({ data }) => {
                setMovementFlag(data);
            })
            .catch((error) => {});
    };
    const AgreeDateApi = () => {
        sigonginfoDetail
            .mutateAsync({ sigongIdx: decryptIdx || index })
            .then((data) => {
                setDobaeAgreeDate(data.data.dobae_agree_date);
                setZangpanAgreeDate(data.data.zangpan_agree_date);
                setMaruAgreeDate(data.data.maru_agree_date);
                setDemolitionAgreeDate(data.data.demolition_agree_date);
                setPaybackAgreeDate(data.data.payback_agree_date);
                // setDobaeAgree(data.data.dobae_agree_date ? true : false);
                // setZangpanAgree(data.data.zangpan_agree_date ? true : false);
                // setMaruAgree(data.data.maru_agree_date ? true : false);
                // setDemolitionAgree(
                //     data.data.demolition_agree_date ? true : false
                // );
                // setPayBackAgree(data.data.payback_agree_date ? true : false);
            })
            .catch((error) => {});
    };
    const sigonginfoDetailApi = () => {
        sigonginfoDetail
            .mutateAsync({ sigongIdx: decryptIdx || index })
            .then((data) => {
                // console.log("data", data.data);
                setUseCustomerPaymentName(data.data.customer_payment_name);
                setUseCustomerContractChk(data.data.customer_payment_chk);

                setCusmtomPaymentChk(data.data.customer_payment_chk);
                setCustomerName(data.data.cutomer_name);
                setCustomerPhone(data.data.mb_phone);
                setDobaeOn(data.data.dobae);
                setDobaeAgree(data.data.dobae_agree_date ? true : false);
                setDobaeAgreeDate(data.data.dobae_agree_date);
                setDobaePrice(data.data.dobae_price);
                setDobaeDate(data.data.dobae_date);
                setDobaeTime(data.data.dobae_time);
                setZangpanOn(data.data.zangpan);
                setZangpanAgreeDate(data.data.zangpan_agree_date);
                setZangpanAgree(data.data.zangpan_agree_date ? true : false);
                setZangpanPrice(data.data.zangpan_price);
                setZangpanDate(data.data.zangpan_date);
                setZangpanTime(data.data.zangpan_time);
                setMaruOn(data.data.maru);
                setMaruAgreeDate(data.data.maru_agree_date);
                setMaruAgree(data.data.maru_agree_date ? true : false);
                setMaruPrice(data.data.maru_price);
                setMaruDate(data.data.maru_date);
                setMaruTime(data.data.maru_time);
                setDemolitionPrice(data.data.demolition_price);
                setDemolitionAgreeDate(data.data.demolition_agree_date);
                setDemolitionAgree(
                    data.data.demolition_agree_date ? true : false
                );
                setDemolitionDate(data.data.demolition_date);
                setDemolitionTime(data.data.demolition_time);
                setPaybackAgreeDate(data.data.payback_agree_date);
                setPayBackAgree(data.data.payback_agree_date ? true : false);
            })
            .catch((error) => {});
    };
    useEffect(() => {
        sigonginfoDetailApi();
        movementFlagValApi();
    }, [index]);

    useEffect(() => {
        AgreeDateApi();
        if (
            (dobaeOn === 0 || (dobaeOn === 1 && dobaeAgree)) &&
            (zangpanOn === 0 || (zangpanOn === 1 && zangpanAgree)) &&
            (maruOn === 0 || (maruOn === 1 && maruAgree)) &&
            (maruOn === 0 ||
                (maruOn === 1 && demolitionPrice !== 0 && demolitionAgree)) &&
            payBackAgree
        ) {
            setAllAgree(true);
        }
    }, [dobaeAgree, zangpanAgree, maruAgree, demolitionAgree, payBackAgree]);

    useEffect(() => {
        const handleResize = () => {
            setHeightChange(window.innerHeight);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [window.innerHeight]);

    // useEffect(() => {
    //     if (agreePop) {
    //         document.body.style.overflow = "hidden";
    //     } else {
    //         document.body.style.overflow = "auto";
    //     }
    // }, [agreePop]);

    return (
        <>
            <AgreePop
                heightChange={heightChange}
                isOpen={agreePop}
                setIsOpen={setAgreePop}
                type={type}
                Price={getPrice()}
                Date={getDate()}
                Time={getTime()}
                agreeClk={getAgreeClk()}
                setAgreeClk={getSetAgreeClk()}
                agreeDate={getAgreeDate()}
                sigongIdx={decryptIdx || index}
            />
            <HeaderComp3 title={"계약서"} />
            <ProgressBar
                style={{ marginTop: "10px" }}
                progress={2}
                movementFlag={movementFlag}
                index={index}
                paymentChk={cusmtomPaymentChk}
            />
            <div className="ContentBody">
                <div className="pSpan">
                    <p>
                        <span>{customerName || customerPhone.slice(-4)}</span>
                        님과의 시공계약서 및 환불규정입니다.
                    </p>
                </div>
                <h3>시공별 계약사항</h3>
                <div className="contractBoxWrap">
                    {dobaeOn === 1 && (
                        <div
                            className={`contractBox ${
                                dobaeAgree ? "active" : ""
                            }`}
                            onClick={() => agreePopOpen(1)}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                }}
                            >
                                {dobaeAgree ? (
                                    <img
                                        className="Chk"
                                        src={imageData.contractOn}
                                    />
                                ) : (
                                    <img
                                        className="Chk"
                                        src={imageData.contractOff}
                                    />
                                )}

                                <p>도배 고객 계약사항</p>
                            </div>
                            {dobaeAgree ? (
                                <img
                                    className="arrow"
                                    src={imageData.rightArrowOn}
                                />
                            ) : (
                                <img
                                    className="arrow"
                                    src={imageData.rightArrowOff}
                                />
                            )}
                        </div>
                    )}
                    {zangpanOn === 1 && (
                        <div
                            className={`contractBox ${
                                zangpanAgree ? "active" : ""
                            }`}
                            onClick={() => agreePopOpen(2)}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                }}
                            >
                                {zangpanAgree ? (
                                    <img
                                        className="Chk"
                                        src={imageData.contractOn}
                                    />
                                ) : (
                                    <img
                                        className="Chk"
                                        src={imageData.contractOff}
                                    />
                                )}

                                <p>장판 고객 계약사항</p>
                            </div>
                            {zangpanAgree ? (
                                <img
                                    className="arrow"
                                    src={imageData.rightArrowOn}
                                />
                            ) : (
                                <img
                                    className="arrow"
                                    src={imageData.rightArrowOff}
                                />
                            )}
                        </div>
                    )}
                    {maruOn === 1 && (
                        <div
                            className={`contractBox ${
                                maruAgree ? "active" : ""
                            }`}
                            onClick={() => agreePopOpen(3)}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                }}
                            >
                                {maruAgree ? (
                                    <img
                                        className="Chk"
                                        src={imageData.contractOn}
                                    />
                                ) : (
                                    <img
                                        className="Chk"
                                        src={imageData.contractOff}
                                    />
                                )}

                                <p>마루 고객 계약사항</p>
                            </div>
                            {maruAgree ? (
                                <img
                                    className="arrow"
                                    src={imageData.rightArrowOn}
                                />
                            ) : (
                                <img
                                    className="arrow"
                                    src={imageData.rightArrowOff}
                                />
                            )}
                        </div>
                    )}
                    {maruOn === 1 && demolitionPrice !== 0 && (
                        <div
                            className={`contractBox ${
                                demolitionAgree ? "active" : ""
                            }`}
                            onClick={() => agreePopOpen(4)}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                }}
                            >
                                {demolitionAgree ? (
                                    <img
                                        className="Chk"
                                        src={imageData.contractOn}
                                    />
                                ) : (
                                    <img
                                        className="Chk"
                                        src={imageData.contractOff}
                                    />
                                )}

                                <p>철거 고객 계약사항</p>
                            </div>
                            {demolitionAgree ? (
                                <img
                                    className="arrow"
                                    src={imageData.rightArrowOn}
                                />
                            ) : (
                                <img
                                    className="arrow"
                                    src={imageData.rightArrowOff}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="cutLine" />
            <div className="ContentBody" style={{ paddingBottom: "70px" }}>
                <h3>변경 및 환불 정책</h3>
                <div
                    className={`contractBox ${payBackAgree ? "active" : ""}`}
                    onClick={() => agreePopOpen(5)}
                >
                    <div
                        style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                        }}
                    >
                        {payBackAgree ? (
                            <img className="Chk" src={imageData.contractOn} />
                        ) : (
                            <img className="Chk" src={imageData.contractOff} />
                        )}

                        <p>일정 변경, 취소 및 환불 정책</p>
                    </div>
                    {payBackAgree ? (
                        <img className="arrow" src={imageData.rightArrowOn} />
                    ) : (
                        <img className="arrow" src={imageData.rightArrowOff} />
                    )}
                </div>
                <Button
                    title={"다음"}
                    active={allAgree}
                    onClick={GoNext}
                    style={{ marginTop: "25px" }}
                />
            </div>
            <BottomBtn
                active={looking ? 2 : 3}
                movementFlag={movementFlag}
                index={index}
            />
        </>
    );
}

export default UserNewContract;
