import { useEffect } from "react";
import { Button } from "./Button";
// kakao 기능 동작을 위해 넣어준다.
const { Kakao } = window;
console.log(Kakao);

const KakaoShareButton = ({ type, idx, title, style }) => {
    // 재랜더링시에 실행되게 해준다.
    useEffect(() => {
        // init 해주기 전에 clean up 을 해준다.
        Kakao.cleanup();
        // 자신의 js 키를 넣어준다.
        Kakao.init("b7ce30877a60cbcac550f4cc8a52d2b5");
        // 잘 적용되면 true 를 뱉는다.
        console.log(Kakao.isInitialized());
    }, []);

    const shareKakao = () => {
        Kakao.Share.sendCustom({
            templateId: 114717,
            templateArgs: {
                Link: `${
                    type === 1 ? "UserNewEstimate" : "UserNewConstrManagement"
                }`,

                idx: idx,
            },
        });
    };

    return (
        <>
            <Button title={title} onClick={shareKakao} style={style} />
        </>
    );
};

export default KakaoShareButton;
