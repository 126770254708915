import { imageData } from "../libs/images";
import consts from "../libs/consts";
import { useState } from "react";

export const ContentBox = ({ title, content }) => {
    return (
        <div className="contentBox">
            <div
                className="contentBoxHeader"
                dangerouslySetInnerHTML={{
                    __html: title.replace(/\n/g, "<br />"),
                }}
            ></div>
            <div className="contentBoxContent">{content}</div>
        </div>
    );
};

export const MaterialBox = ({
    material_img,
    material_company,
    material_number,
    material_name,
    material_pattern,
    material_color,
    material_badge,

    material_chk,
    value,
    readOnly = false,
    onChange,
    onDelete,
    onBlur = () => {},
}) => {
    const [imgError, setImgError] = useState(false);
    return (
        <div className="materialBox">
            <div className="imgWrap">
                {material_img ? (
                    imgError ? (
                        <div className="noImgDiv">
                            이미지
                            <br />
                            없음
                        </div>
                    ) : (
                        <>
                            <img
                                src={consts.ImgState + material_img}
                                onError={() => setImgError(true)}
                            />
                            <div
                                className={`badge ${
                                    // material_badge === 0
                                    //     ? "None"
                                    //     : material_badge === 1
                                    //     ? "NEW"
                                    //     : material_badge === 2
                                    //     ? "BEST"
                                    //    :
                                    material_badge === 3
                                        ? "PREMIUM"
                                        : material_badge === 4
                                        ? "HIGH-END"
                                        : "None"
                                }`}
                            >
                                {
                                    // material_badge === 1
                                    //     ? "NEW"
                                    //     : material_badge === 2
                                    //     ? "BEST"
                                    //     :
                                    material_badge === 3
                                        ? "PREMIUM"
                                        : material_badge === 4
                                        ? "HIGH-END"
                                        : ""
                                }
                            </div>
                        </>
                    )
                ) : (
                    <div className="noImgDiv">
                        이미지 <br />
                        없음
                    </div>
                )}
            </div>

            <div className="materialBoxInfo">
                <div className="materialBoxTitle">
                    <p>{material_company}</p>
                    <div className="alignLine" />
                    <h1>{material_number}</h1>
                </div>
                <div className="materialBoxContent">
                    <p>
                        [{material_name}] ({material_pattern}) {material_color}
                    </p>
                </div>
                <div
                    className={`materialBoxInputWrap ${value ? "" : "active"}`}
                >
                    <p>위치</p>
                    <div className="alignLine" />
                    <input
                        readOnly={readOnly}
                        type="text"
                        placeholder="위치를 입력해주세요."
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        onBlur={() => onBlur()}
                    />
                </div>
                {!material_chk && (
                    <img
                        src={imageData.materialCancel}
                        className="materialCancel"
                        onClick={onDelete}
                    />
                )}
            </div>
        </div>
    );
};
export const MaterialBaseBoardBox = ({
    material_img,
    material_company,
    material_number,
    material_name,
    material_chk,
    value = "",
    readOnly = false,
    onChange,
    onDelete,
    onBlur,
    read,
}) => {
    const [imgError, setImgError] = useState(false);
    return (
        <div className="materialBox">
            <div className="imgWrap">
                {material_img ? (
                    imgError ? (
                        <div className="noImgDiv">
                            이미지 <br />
                            없음
                        </div>
                    ) : (
                        <img
                            src={consts.ImgState + material_img}
                            onError={() => setImgError(true)}
                        />
                    )
                ) : (
                    <div className="noImgDiv">
                        이미지 <br />
                        없음
                    </div>
                )}
            </div>

            <div className="materialBoxInfo">
                <div className="materialBoxTitle">
                    <p>{material_company}</p>
                    <div className="alignLine" />
                    <h1>{material_number}</h1>
                </div>
                <div className="materialBoxContent">
                    <p>{material_name}</p>
                </div>
                <div
                    className={`materialBoxInputWrap ${value ? "" : "active"}`}
                >
                    <p>위치</p>
                    <div className="alignLine" />
                    <input
                        readOnly={readOnly}
                        type="text"
                        placeholder="위치를 입력해주세요."
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        onBlur={() => onBlur()}
                    />
                </div>
                {!material_chk && (
                    <img
                        src={imageData.materialCancel}
                        className="materialCancel"
                        onClick={onDelete}
                    />
                )}
            </div>
        </div>
    );
};

export const MaterialFinalLook = ({
    material_company,
    material_number,
    material_name,
    material_pattern,
    material_color,
    material_img,
    material_badge,
}) => {
    return (
        <div className="MaterialFinalLookWrap">
            <div className="imgWrap">
                <img src={consts.ImgState + material_img} />
                <div
                    className={`badge ${
                        // material_badge === 0
                        //     ? "None"
                        //     : material_badge === 1
                        //     ? "NEW"
                        //     : material_badge === 2
                        //     ? "BEST"
                        //     :
                        material_badge === 3
                            ? "PREMIUM"
                            : material_badge === 4
                            ? "HIGH-END"
                            : "None"
                    }`}
                >
                    {
                        // material_badge === 1
                        //     ? "NEW"
                        //     : material_badge === 2
                        //     ? "BEST"
                        //     :
                        material_badge === 3
                            ? "PREMIUM"
                            : material_badge === 4
                            ? "HIGH-END"
                            : ""
                    }
                </div>
            </div>
            <div className="materialBoxInfo">
                <div className="materialBoxTitle">
                    <p>{material_company}</p>
                    <div className="alignLine" />
                    <h1>{material_number}</h1>
                </div>
                <div className="materialBoxContent">
                    <p>
                        [{material_name}] ({material_pattern}) {material_color}
                    </p>
                </div>
            </div>
        </div>
    );
};

export const MaterialFinalBaseBoardLook = ({
    material_company,
    material_number,
    material_name,
    material_img,
}) => {
    return (
        <div className="MaterialFinalLookWrap">
            <div className="imgWrap">
                <img src={consts.ImgState + material_img} />
            </div>
            <div className="materialBoxInfo">
                <div className="materialBoxTitle">
                    <p>{material_company}</p>
                    <div className="alignLine" />
                    <h1>{material_number}</h1>
                </div>
                <div className="materialBoxContent">
                    <p>{material_name}</p>
                </div>
            </div>
        </div>
    );
};
export const MaterialFinalLook2 = ({
    material_company,
    material_number,
    material_name,
    material_img,
    finalConfirm = false,
    memo,
}) => {
    const [imgError, setImgError] = useState(false);
    return (
        <div className="MaterialFinalLookWrap">
            <div className="imgWrap">
                {imgError ? (
                    <div className="noImgDiv">
                        이미지
                        <br />
                        없음
                    </div>
                ) : (
                    <img
                        src={consts.ImgState + material_img}
                        onError={() => setImgError(true)}
                    />
                )}
            </div>
            <div className="materialBoxInfo">
                <div className="materialBoxTitle">
                    {finalConfirm ? (
                        <>
                            <h1>{material_number}</h1>

                            <div className="alignLine" />
                            <p>
                                {material_company || ""} {material_name || ""}
                            </p>
                        </>
                    ) : (
                        <>
                            {" "}
                            <p>
                                {material_company || ""} {material_name || ""}
                            </p>
                            <div className="alignLine" />
                            <h1>{material_number}</h1>
                        </>
                    )}
                </div>
                <div className="materialBoxContent">
                    <p>{memo}</p>
                </div>
            </div>
        </div>
    );
};

export const MaterialPopLook = ({
    material_badge,
    material_company,
    material_number,
    material_name,
    material_pattern,
    material_color,
    material_img,
    onClick,
}) => {
    return (
        <div className="MaterialPopLookWrap" onClick={() => onClick()}>
            <div className="imgWrap">
                <img src={consts.ImgState + material_img} />
                <div
                    className={`badge ${
                        // material_badge === 0
                        //     ? "None"
                        //     : material_badge === 1
                        //     ? "NEW"
                        //     : material_badge === 2
                        //     ? "BEST"
                        //     :
                        material_badge === 3
                            ? "PREMIUM"
                            : material_badge === 4
                            ? "HIGH-END"
                            : "None"
                    }`}
                >
                    {
                        // material_badge === 1
                        //     ? "NEW"
                        //     : material_badge === 2
                        //     ? "BEST"
                        //     :
                        material_badge === 3
                            ? "PREMIUM"
                            : material_badge === 4
                            ? "HIGH-END"
                            : ""
                    }
                </div>
            </div>
            <div className="materialBoxInfo">
                <div className="materialBoxTitle">
                    <p>{material_company}</p>
                    <div className="alignLine" />
                    <h1>{material_number}</h1>
                </div>
                <div className="materialBoxContent">
                    <p>
                        {material_pattern
                            ? `[${material_name}] (${material_pattern}) ${material_color}`
                            : material_name}
                    </p>
                </div>
            </div>
        </div>
    );
};
