import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "./libs/routes";

// import UserEstimate from "./pages/UserEstimate";
// import UserContract from "./pages/UserContract";
// import UserAddressIn from "./pages/UserAddressIn";
// import UserPaymentInfo from "./pages/UserPaymentInfo";
// import UserMaterialSelect from "./pages/UserMaterialSelect";
// import UserFinalConfirm from "./pages/UserFinalConfirm";
// import UserSigongFinish from "./pages/UserSigongFinish";

// import UserASInquire from "./pages/UserASInquire";
import UserLogin from "./pages/UserLogin";
import UserNewEstimate from "./pages/UserNewEstimate";
import UserNewContract from "./pages/UserNewContract";
import UserNewAddressIn from "./pages/UserNewAddressIn";
import UserNewPaymentInfo from "./pages/UserNewPaymentInfo";
import UserNewPaymentInfo2 from "./pages/UserNewPaymentInfo2";
import UserNewMaterialSelect from "./pages/UserNewMaterialSelect";
import UserNewFinalConfirm from "./pages/UserNewFinalConfirm";
import UserNewFinished from "./pages/UserNewFinished";
import UserNewAsInquire from "./pages/UserNewAsInquire";
import UserNewConstrManagement from "./pages/UserNewConstrManagement";
function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path={"*"} element={<UserLogin />} />
                <Route
                    exact
                    path={routes.UserNewEstimate}
                    element={<UserNewEstimate />}
                />
                <Route
                    exact
                    path={routes.UserNewContract}
                    element={<UserNewContract />}
                />
                <Route
                    exact
                    path={routes.UserNewAddressIn}
                    element={<UserNewAddressIn />}
                />
                <Route
                    exact
                    path={routes.UserNewPaymentInfo}
                    element={<UserNewPaymentInfo />}
                />
                <Route
                    exact
                    path={routes.UserNewPaymentInfo2}
                    element={<UserNewPaymentInfo2 />}
                />
                <Route
                    exact
                    path={routes.UserNewMaterialSelect}
                    element={<UserNewMaterialSelect />}
                />
                <Route
                    exact
                    path={routes.UserNewFinalConfirm}
                    element={<UserNewFinalConfirm />}
                />
                <Route
                    exact
                    path={routes.UserNewFinished}
                    element={<UserNewFinished />}
                />
                <Route
                    exact
                    path={routes.UserNewAsInquire}
                    element={<UserNewAsInquire />}
                />
                <Route
                    exact
                    path={routes.UserNewConstrManagement}
                    element={<UserNewConstrManagement />}
                />

                {/* <Route
                    exact
                    path={routes.UserEstimate}
                    element={<UserEstimate />}
                />
                <Route
                    exact
                    path={routes.UserContract}
                    element={<UserContract />}
                />
                <Route
                    exact
                    path={routes.UserAddressIn}
                    element={<UserAddressIn />}
                />
                <Route
                    exact
                    path={routes.UserPaymentInfo}
                    element={<UserPaymentInfo />}
                />
                <Route
                    exact
                    path={routes.UserMaterialSelect}
                    element={<UserMaterialSelect />}
                />
                <Route
                    exact
                    path={routes.UserFinalConfirm}
                    element={<UserFinalConfirm />}
                />
                <Route
                    exact
                    path={routes.UserASInquire}
                    element={<UserASInquire />}
                />
                <Route
                    exact
                    path={routes.UserSigongFinish}
                    element={<UserSigongFinish />}
                /> */}
            </Routes>
        </BrowserRouter>
    );
}
export default Router;
