import { useEffect, useState } from "react";
import { imageData } from "../libs/images";
import { MaterialFinalLook, MaterialPopLook } from "./BoxInfo";
import { Button } from "../components/Button";
import Pagination from "react-js-pagination";
import PaginationCustom from "./CustomPageNation";
import { set } from "lodash";
import { usePostData } from "../utils/service";
import ApiData from "../libs/api";
import consts from "../libs/consts";

export const SearchMaterialPop = ({
    SigongIdx,
    materialType,
    isOpen,
    setIsOpen,
    materialSearch,
    setMaterialSearch,
    materialData,
    setMaterialData,
    materialCount,
    // materialBadge,
    // setMaterialBadge,
    materialCompanyArr,
    materialCompany,
    setMaterialCompany,
    materialColorArr,
    materialColor = "",
    setMaterialColor,
    materialPatternArr,
    materialPattern = "",
    setMaterialPattern,
    CeilingPopup = false,
    setCeilingPopup = () => {},
    setMaterialClass01,
    setIsSaved,
    // SetSaveArr,
    zangpanMM = "",
    innerHeight,

    page,
    setPage,
}) => {
    const Materialrecommend = usePostData(ApiData.Materialrecommend);

    const MaterialNewSelectIn = usePostData(ApiData.MaterialNewSelectIn);
    const [currentPage, setCurrentPage] = useState(1);
    const [clkbox, setClkbox] = useState(1);
    const [materialDataArr, setMaterialDataArr] = useState([]);
    const [firstRecommend, setFirstRecommend] = useState([]);
    const [secondRecommend, setSecondRecommend] = useState([]);
    const [thirdRecommend, setThirdRecommend] = useState([]);
    const [fourthRecommend, setFourthRecommend] = useState([]);

    const MaterialIn = ({ type, materialThings, ceiling }) => {
        let sender = {
            sigongIdx: SigongIdx,
            material_type: type,
            material: materialThings,
            ceiling: ceiling,
        };
        console.log("sender", sender);
        MaterialNewSelectIn.mutateAsync(sender)
            .then(({ data }) => {
                setIsSaved(true);
                setIsOpen(!isOpen);
                console.log("데이터 저장에 성공했습니다.");
            })
            .catch((error) => {
                console.log("데이터 저장에 실패했습니다.");
            });
    };

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const materialSearchHandler = (e) => {
        setMaterialSearch(e.target.value);
    };
    // const materialBadgeHandler = (e) => {
    //     setMaterialBadge(e.target.value);
    // };
    const materialColorHandler = (e) => {
        setMaterialColor(e.target.value);
    };
    const materialPatternHandler = (e) => {
        setMaterialPattern(e.target.value);
    };
    const CloseBtn = () => {
        setCurrentPage(1);
        setMaterialData([]);
        // setMaterialBadge(0);
        setMaterialSearch("");
        setMaterialColor("");
        setMaterialPattern("");
        setCeilingPopup(false);
        setMaterialClass01("");
        setMaterialCompany("");
        setPage(1);
        setIsOpen(!isOpen);
    };
    const onSaveClk = () => {
        setCurrentPage(1);
        MaterialIn({
            type: materialType,
            ceiling: CeilingPopup,
            materialThings: materialDataArr,
        });
        // SetSaveArr((prevArr) => {
        //     const isDuplicate = prevArr.some(
        //         (item) => item === materialDataArr
        //     );
        //     if (!isDuplicate) {
        //         return [...prevArr, materialDataArr];
        //     }
        //     return prevArr;
        // });
    };
    const MaterialrecommendApi = () => {
        let sender = {
            material_type: materialType,
            material_company: materialDataArr.material_company,
            material_class01: materialDataArr.material_class01,
            material_idx: materialDataArr.idx,
        };
        Materialrecommend.mutateAsync(sender)
            .then(({ data }) => {
                setFirstRecommend(data.firstThing);
                setSecondRecommend(data.secondThing);
                setThirdRecommend(data.thirdThing);
                setFourthRecommend(data.fourthThing);
            })
            .catch((error) => {});
    };
    const ChangeCompanyClk = (index) => {
        setClkbox(index);
        setMaterialSearch("");
        setMaterialColor("");
        setMaterialPattern("");
    };
    useEffect(() => {
        if (materialDataArr?.material_company) {
            MaterialrecommendApi();
        }
    }, [currentPage, materialDataArr]);
    return (
        <>
            {isOpen && (
                <div className="overlay">
                    <div
                        style={{
                            height: "70px",
                            position: "fixed",
                            top: "0",
                            width: "100%",
                            // zIndex: "1000000",
                        }}
                        onClick={() => CloseBtn()}
                    ></div>
                    <div
                        className="LongPopup"
                        style={{
                            top: "70px",
                            height: `calc(${innerHeight}px - 65px)`,
                            // minHeight: "unset",
                        }}
                    >
                        {currentPage === 1 && (
                            <div
                                style={{ justifyContent: "space-between" }}
                                className="popupTitle"
                            >
                                <h1>자재 검색</h1>
                                <img
                                    style={{ cursor: "pointer" }}
                                    onClick={() => CloseBtn()}
                                    src={imageData.exit}
                                    alt="exit"
                                />
                            </div>
                        )}
                        {currentPage === 2 && (
                            <div
                                style={{ justifyContent: "center" }}
                                className="popupTitle"
                            >
                                <h1>자재 선택</h1>
                                <img
                                    style={{
                                        cursor: "pointer",
                                        position: "absolute",
                                        left: "27.5px",
                                        height: "17px",
                                    }}
                                    onClick={() => setCurrentPage(1)}
                                    src={imageData.ArrowLeft}
                                    alt="exit"
                                />
                            </div>
                        )}
                        <div className="materialPopupContentWrap">
                            {currentPage === 1 && (
                                <>
                                    <div className="ClkContentWrap">
                                        {materialType === 1 ? (
                                            materialCompanyArr?.map(
                                                (item, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={`ClkContent ${
                                                                materialCompany ===
                                                                item
                                                                    ? "active"
                                                                    : ""
                                                            }`}
                                                            onClick={() => {
                                                                ChangeCompanyClk(
                                                                    index + 1
                                                                );

                                                                setMaterialCompany(
                                                                    item
                                                                );
                                                            }}
                                                        >
                                                            <p>{item}</p>
                                                        </div>
                                                    );
                                                }
                                            )
                                        ) : (
                                            <div
                                                key={0}
                                                className="ClkContent active"
                                                onClick={() => {
                                                    setMaterialCompany(
                                                        materialCompanyArr[0]
                                                    );
                                                }}
                                            >
                                                <p>
                                                    {materialCompanyArr[0]}{" "}
                                                    {zangpanMM}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    <div className="materialPopupContent">
                                        <div className="materialPopupSearch">
                                            <div className="searchInput">
                                                <input
                                                    value={materialSearch}
                                                    onChange={
                                                        materialSearchHandler
                                                    }
                                                    type="text"
                                                    placeholder="자재명을 입력해주세요"
                                                />
                                                <img
                                                    src={imageData.searchIcon}
                                                    alt="search"
                                                />
                                            </div>

                                            <div className="searchWrap">
                                                <select defaultValue={0}>
                                                    <option value={0}>
                                                        제품군
                                                    </option>
                                                    {/* <option value={1}>
        일반
    </option>
    <option value={2}>
        프리미엄
    </option>
    <option value={3}>
        하이엔드
    </option> */}
                                                </select>
                                                {/* <select
                                                    onChange={
                                                        materialBadgeHandler
                                                    }
                                                    value={materialBadge || 0}
                                                >
                                                    <option value={0}>
                                                        제품군
                                                    </option>
                                                    <option value={1}>
                                                        일반
                                                    </option>
                                                    <option value={2}>
                                                        프리미엄
                                                    </option>
                                                    <option value={3}>
                                                        하이엔드
                                                    </option>
                                                </select> */}
                                                <select
                                                    value={materialColor}
                                                    onChange={
                                                        materialColorHandler
                                                    }
                                                >
                                                    <option value={""}>
                                                        색상
                                                    </option>
                                                    {materialColorArr?.map(
                                                        (item, index) => (
                                                            <option
                                                                key={index}
                                                                value={item}
                                                            >
                                                                {item}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                                <select
                                                    value={materialPattern}
                                                    onChange={
                                                        materialPatternHandler
                                                    }
                                                >
                                                    <option value={""}>
                                                        재질
                                                    </option>
                                                    {materialPatternArr?.map(
                                                        (item, index) => (
                                                            <option
                                                                key={index}
                                                                value={item}
                                                            >
                                                                {item}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </div>
                                            <div className="Count Width100">
                                                <p>총 {materialCount || 0}개</p>
                                            </div>
                                        </div>
                                        <div className="materialList_wrap">
                                            <div className="materialList">
                                                {materialData?.map(
                                                    (item, index) => {
                                                        return (
                                                            <MaterialPopLook
                                                                key={index}
                                                                material_badge={
                                                                    item.material_badge
                                                                }
                                                                material_img={
                                                                    item.material_img
                                                                }
                                                                material_company={
                                                                    item.material_company
                                                                }
                                                                material_number={
                                                                    item.material_number
                                                                }
                                                                material_name={
                                                                    item.material_name
                                                                }
                                                                material_color={
                                                                    item.material_color
                                                                }
                                                                material_pattern={
                                                                    item.material_pattern
                                                                }
                                                                onClick={() => {
                                                                    setMaterialDataArr(
                                                                        item
                                                                    );
                                                                    setCurrentPage(
                                                                        2
                                                                    );
                                                                }}
                                                            />
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>

                                        <div className="PaginationContent">
                                            <PaginationCustom
                                                totalPages={
                                                    Math.ceil(
                                                        materialCount / 20
                                                    ) || 0
                                                }
                                                onPageChange={handlePageChange}
                                                page={page}
                                                setPage={setPage}
                                            />
                                            {/* <Pagination
                                                className="my-pagination"
                                                // 현제 보고있는 페이지
                                                activePage={page}
                                                // 한페이지에 출력할 아이템수
                                                itemsCountPerPage={20}
                                                // 총 아이템수
                                                totalItemsCount={
                                                    materialCount || 0
                                                }
                                                // 표시할 페이지수
                                                pageRangeDisplayed={3}
                                                // 함수
                                                onChange={handlePageChange}
                                                nextPageText="다음 >"
                                                prevPageText="< 이전"
                                                firstPageText=""
                                                lastPageText=""
                                            ></Pagination> */}
                                        </div>
                                    </div>
                                </>
                            )}
                            {currentPage === 2 && (
                                <div
                                    className="materialPopupContentWrap2"
                                    style={{
                                        maxHeight: `calc(${innerHeight}px - 125px)`,
                                    }}
                                >
                                    <div className="materialPopupContent">
                                        <MaterialFinalLook
                                            material_badge={
                                                materialDataArr.material_badge
                                            }
                                            material_img={
                                                materialDataArr.material_img
                                            }
                                            material_company={
                                                materialDataArr.material_company
                                            }
                                            material_number={
                                                materialDataArr.material_number
                                            }
                                            material_name={
                                                materialDataArr.material_name
                                            }
                                            material_pattern={
                                                materialDataArr.material_pattern
                                            }
                                            material_color={
                                                materialDataArr.material_color
                                            }
                                        />
                                        <div className="materialDetailEditor">
                                            <div
                                                className="editor-text"
                                                dangerouslySetInnerHTML={{
                                                    __html: materialDataArr.material_editor,
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                    <div className="materialRecommandWrap">
                                        {!CeilingPopup && (
                                            <>
                                                <div className="title">
                                                    {materialType === 1 ? (
                                                        <h1>추천 벽지</h1>
                                                    ) : (
                                                        <h1>추천 바닥재</h1>
                                                    )}
                                                </div>
                                                <div className="materialRecommand">
                                                    {firstRecommend?.material_img && (
                                                        <div
                                                            className="recommandThing"
                                                            onClick={() => {
                                                                setMaterialDataArr(
                                                                    firstRecommend
                                                                );
                                                            }}
                                                        >
                                                            <div className="ImgWrap">
                                                                <img
                                                                    src={
                                                                        consts.ImgState +
                                                                        firstRecommend?.material_img
                                                                    }
                                                                />
                                                                <div
                                                                    className={`badge ${
                                                                        // firstRecommend?.material_badge ===
                                                                        // 0
                                                                        //     ? "None"
                                                                        //     : firstRecommend?.material_badge ===
                                                                        //       1
                                                                        //     ? "NEW"
                                                                        //     : firstRecommend?.material_badge ===
                                                                        //       2
                                                                        //     ? "BEST"
                                                                        //     :
                                                                        firstRecommend?.material_badge ===
                                                                        3
                                                                            ? "PREMIUM"
                                                                            : firstRecommend?.material_badge ===
                                                                              4
                                                                            ? "HIGH-END"
                                                                            : "None"
                                                                    }`}
                                                                >
                                                                    {
                                                                        // firstRecommend?.material_badge ===
                                                                        // 1
                                                                        //     ? "NEW"
                                                                        //     : firstRecommend?.material_badge ===
                                                                        //       2
                                                                        //     ? "BEST"
                                                                        //     :
                                                                        firstRecommend?.material_badge ===
                                                                        3
                                                                            ? "PREMIUM"
                                                                            : firstRecommend?.material_badge ===
                                                                              4
                                                                            ? "HIGH-END"
                                                                            : ""
                                                                    }
                                                                </div>
                                                            </div>
                                                            {materialType ===
                                                            1 ? (
                                                                <h1>벽지</h1>
                                                            ) : (
                                                                <h1>바닥재</h1>
                                                            )}
                                                            <p>
                                                                {
                                                                    firstRecommend?.material_number
                                                                }
                                                            </p>
                                                        </div>
                                                    )}
                                                    {secondRecommend?.material_img && (
                                                        <div
                                                            className="recommandThing"
                                                            onClick={() => {
                                                                setMaterialDataArr(
                                                                    secondRecommend
                                                                );
                                                            }}
                                                        >
                                                            <div className="ImgWrap">
                                                                <img
                                                                    src={
                                                                        consts.ImgState +
                                                                        secondRecommend?.material_img
                                                                    }
                                                                />
                                                                <div
                                                                    className={`badge ${
                                                                        // secondRecommend?.material_badge ===
                                                                        // 0
                                                                        //     ? "None"
                                                                        //     : secondRecommend?.material_badge ===
                                                                        //       1
                                                                        //     ? "NEW"
                                                                        //     : secondRecommend?.material_badge ===
                                                                        //       2
                                                                        //     ? "BEST"
                                                                        //     :
                                                                        secondRecommend?.material_badge ===
                                                                        3
                                                                            ? "PREMIUM"
                                                                            : secondRecommend?.material_badge ===
                                                                              4
                                                                            ? "HIGH-END"
                                                                            : "None"
                                                                    }`}
                                                                >
                                                                    {
                                                                        // secondRecommend?.material_badge ===
                                                                        // 1
                                                                        //     ? "NEW"
                                                                        //     : secondRecommend?.material_badge ===
                                                                        //       2
                                                                        //     ? "BEST"
                                                                        //     :
                                                                        secondRecommend?.material_badge ===
                                                                        3
                                                                            ? "PREMIUM"
                                                                            : secondRecommend?.material_badge ===
                                                                              4
                                                                            ? "HIGH-END"
                                                                            : ""
                                                                    }
                                                                </div>
                                                            </div>
                                                            {materialType ===
                                                            1 ? (
                                                                <h1>벽지</h1>
                                                            ) : (
                                                                <h1>바닥재</h1>
                                                            )}
                                                            <p>
                                                                {
                                                                    secondRecommend?.material_number
                                                                }
                                                            </p>
                                                        </div>
                                                    )}
                                                    {thirdRecommend?.material_img && (
                                                        <div
                                                            className="recommandThing"
                                                            onClick={() => {
                                                                setMaterialDataArr(
                                                                    thirdRecommend
                                                                );
                                                            }}
                                                        >
                                                            <div className="ImgWrap">
                                                                <img
                                                                    src={
                                                                        consts.ImgState +
                                                                        thirdRecommend?.material_img
                                                                    }
                                                                />
                                                                <div
                                                                    className={`badge ${
                                                                        // thirdRecommend?.material_badge ===
                                                                        // 0
                                                                        //     ? "None"
                                                                        //     : thirdRecommend?.material_badge ===
                                                                        //       1
                                                                        //     ? "NEW"
                                                                        //     : thirdRecommend?.material_badge ===
                                                                        //       2
                                                                        //     ? "BEST"
                                                                        //     :
                                                                        thirdRecommend?.material_badge ===
                                                                        3
                                                                            ? "PREMIUM"
                                                                            : thirdRecommend?.material_badge ===
                                                                              4
                                                                            ? "HIGH-END"
                                                                            : "None"
                                                                    }`}
                                                                >
                                                                    {
                                                                        // thirdRecommend?.material_badge ===
                                                                        // 1
                                                                        //     ? "NEW"
                                                                        //     : thirdRecommend?.material_badge ===
                                                                        //       2
                                                                        //     ? "BEST"
                                                                        //     :
                                                                        thirdRecommend?.material_badge ===
                                                                        3
                                                                            ? "PREMIUM"
                                                                            : thirdRecommend?.material_badge ===
                                                                              4
                                                                            ? "HIGH-END"
                                                                            : ""
                                                                    }
                                                                </div>
                                                            </div>
                                                            {materialType ===
                                                            1 ? (
                                                                <h1>벽지</h1>
                                                            ) : (
                                                                <h1>바닥재</h1>
                                                            )}
                                                            <p>
                                                                {
                                                                    thirdRecommend?.material_number
                                                                }
                                                            </p>
                                                        </div>
                                                    )}
                                                    {fourthRecommend?.material_img && (
                                                        <div
                                                            className="recommandThing"
                                                            onClick={() => {
                                                                setMaterialDataArr(
                                                                    fourthRecommend
                                                                );
                                                            }}
                                                        >
                                                            <div className="ImgWrap">
                                                                <img
                                                                    src={
                                                                        consts.ImgState +
                                                                        fourthRecommend?.material_img
                                                                    }
                                                                />
                                                                <div
                                                                    className={`badge ${
                                                                        // fourthRecommend?.material_badge ===
                                                                        // 0
                                                                        //     ? "None"
                                                                        //     : fourthRecommend?.material_badge ===
                                                                        //       1
                                                                        //     ? "NEW"
                                                                        //     : fourthRecommend?.material_badge ===
                                                                        //       2
                                                                        //     ? "BEST"
                                                                        //     :
                                                                        fourthRecommend?.material_badge ===
                                                                        3
                                                                            ? "PREMIUM"
                                                                            : fourthRecommend?.material_badge ===
                                                                              4
                                                                            ? "HIGH-END"
                                                                            : "None"
                                                                    }`}
                                                                >
                                                                    {
                                                                        // fourthRecommend?.material_badge ===
                                                                        // 1
                                                                        //     ? "NEW"
                                                                        //     : fourthRecommend?.material_badge ===
                                                                        //       2
                                                                        //     ? "BEST"
                                                                        //     :
                                                                        fourthRecommend?.material_badge ===
                                                                        3
                                                                            ? "PREMIUM"
                                                                            : fourthRecommend?.material_badge ===
                                                                              4
                                                                            ? "HIGH-END"
                                                                            : ""
                                                                    }
                                                                </div>
                                                            </div>
                                                            {materialType ===
                                                            1 ? (
                                                                <h1>벽지</h1>
                                                            ) : (
                                                                <h1>바닥재</h1>
                                                            )}
                                                            <p>
                                                                {
                                                                    fourthRecommend?.material_number
                                                                }
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}

                                        <div
                                            style={{
                                                padding: "10px 0",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <Button
                                                title={"이 자재 선택하기"}
                                                style={{ marginBottom: "20px" }}
                                                onClick={() => onSaveClk()}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="goBack"
                                        onClick={() => setCurrentPage(1)}
                                    >
                                        <img src={imageData.threeLine} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
