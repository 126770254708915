import { useEffect, useState } from "react";
import { HeaderComp3 } from "../components/Header";
import { ProgressBar } from "../components/progressBar";
import { imageData } from "../libs/images";
import { resizeFile } from "../utils/util";
import consts from "../libs/consts";
import { Button } from "../components/Button";
import usePopup from "../zustands/usePopup";
import { SearchMaterialPop } from "../components/SearchMaterial";
import ApiData from "../libs/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { usePostData } from "../utils/service";
import { toast } from "react-toastify";
import moment from "moment";
import { getDecryptedParam } from "../components/navigateWithEncryption";

function UserNewAsInquire() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const index = encodeURIComponent(searchParams.get("sigongIdx"));
    const decryptIdx = getDecryptedParam("sigongIdx");
    const movementFlagVal = usePostData(ApiData.movementFlagVal);
    const AsSendIn = usePostData(ApiData.AsSendIn);
    const AsDetail = usePostData(ApiData.AsDetail);
    const [dobaeOn, setDobaeOn] = useState(0);
    const [zangpanOn, setZangpanOn] = useState(0);
    const [TypeClk, setTypeClk] = useState(1);
    const [AsDobaeImgs, setAsDobaeImgs] = useState([]);
    const [AsDobaeImgsDel, setAsDobaeImgsDel] = useState([]);
    const [AsZangpanImgs, setAsZangpanImgs] = useState([]);
    const [AsZangpanImgsDel, setAsZangpanImgsDel] = useState([]);
    const [AsMaruImgs, setAsMaruImgs] = useState([]);
    const [AsMaruImgsDel, setAsMaruImgsDel] = useState([]);

    const [AsDobaeMemo, setAsDobaeMemo] = useState("");
    const [AsZangpanMemo, setAsZangpanMemo] = useState("");
    const [createDt, setCreateDt] = useState([]);

    const [movementFlag, setMovementFlag] = useState([]);

    const {
        isPopup,
        setPopup,
        setPopTitle,
        setPopContent,
        setSubmitClk,
        isOneBtn = false,
        setOneBtn,
    } = usePopup();
    const textAreaChange = (e) => {
        TypeClk === 1
            ? setAsDobaeMemo(e.target.value)
            : setAsZangpanMemo(e.target.value);
    };

    const movementFlagValApi = () => {
        movementFlagVal
            .mutateAsync({ sigongIdx: decryptIdx || index })
            .then(({ data }) => {
                setMovementFlag(data);
            })
            .catch((error) => {});
    };

    const AsSendApi = () => {
        let sender = {
            sigongIdx: decryptIdx || index,
            dobaeMemo: AsDobaeMemo,
            zangpanMemo: AsZangpanMemo,
            dobaeImg: AsDobaeImgs.filter((item, i) =>
                item.includes("data:image")
            ),
            zangpanImg: AsZangpanImgs.filter((item, i) =>
                item.includes("data:image")
            ),
            DobaeImgDel: AsDobaeImgsDel?.filter((item, i) =>
                item.includes("as_img/")
            ),
            ZangpanImgDel: AsZangpanImgsDel?.filter((item, i) =>
                item.includes("as_img/")
            ),
            // memo: TypeClk === 1 ? AsDobaeMemo : AsZangpanMemo,
            // imgData:
            //     TypeClk === 1
            //         ? AsDobaeImgs.filter((item, i) =>
            //               item.includes("data:image")
            //           )
            //         : AsZangpanImgs.filter((item, i) =>
            //               item.includes("data:image")
            //           ),
            // imgDataDel:
            //     TypeClk === 1
            //         ? AsDobaeImgsDel?.filter((item, i) =>
            //               item.includes("as_img/")
            //           )
            //         : AsZangpanImgsDel?.filter((item, i) =>
            //               item.includes("as_img/")
            //           ),
        };

        AsSendIn.mutateAsync(sender)
            .then((data) => {
                AsDetailApi();
            })
            .catch((error) => {
                toast.error("A/S문의가 실패하였습니다.");
            });
    };

    const AsDetailApi = () => {
        AsDetail.mutateAsync({ sigongIdx: decryptIdx || index })
            .then(({ data }) => {
                setDobaeOn(data.findDetail.dobae);
                setZangpanOn(data.findDetail.zangpan);
                // setAsDobaeImgs(
                //     data.img
                //         .filter((item) => item.type === 1)
                //         .map((item) => item.path)
                // );
                // setAsZangpanImgs(
                //     data.img
                //         .filter((item) => item.type === 2)
                //         .map((item) => item.path)
                // );
                // setAsDobaeMemo(data.findDetail.dobae_as_memo);
                // setAsZangpanMemo(data.findDetail.zangpan_as_memo);
                setCreateDt(data.history);
            })
            .catch((error) => {});
    };

    const BtnClk = () => {
        setPopup(true);
        setPopTitle("제출여부 확인");
        setPopContent("A/S문의 제출하시겠습니까?");
        setOneBtn(false);
        setSubmitClk(() => {
            AsSendApi();
            BtnSubmit();
        });
    };
    const BtnSubmit = () => {
        setAsDobaeImgs([]);
        setAsZangpanImgs([]);
        setAsMaruImgs([]);
        setAsDobaeImgsDel([]);
        setAsZangpanImgsDel([]);
        setAsMaruImgsDel([]);
        setAsDobaeMemo("");
        setAsZangpanMemo("");
        setPopContent(`제출이 완료되었습니다. \n 확인 후 연락드리겠습니다.`);
        setPopTitle("제출여부 확인");
        setOneBtn(true);
        setPopup(true);
    };
    const DelImg = (index, type) => {
        if (type === 1) {
            setAsDobaeImgs((prev) => prev.filter((_, i) => i !== index));
            setAsDobaeImgsDel((prev) => {
                return [...prev, AsDobaeImgs[index]];
            });
        } else if (type === 2) {
            setAsZangpanImgs((prev) => prev.filter((_, i) => i !== index));
            setAsZangpanImgsDel((prev) => [...prev, AsZangpanImgs[index]]);
        } else {
            setAsMaruImgs((prev) => prev.filter((_, i) => i !== index));
            setAsMaruImgsDel((prev) => [...prev, AsMaruImgs[index]]);
        }
    };

    const handleChange = async (value) => {
        var reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;
        let imgVal = "";

        if (value.name.toLowerCase().match(reg)) {
            imgVal = await resizeFile(value, 500, 500);

            return imgVal;
        } else {
            // console.log("이미지 파일만 업로드 가능합니다,");
        }
    };

    const handleCameraClick = ({ setImages }) => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.capture = "camera";
        input.onchange = (event) => {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onloadend = async () => {
                const resizedImageUrl = await handleChange(file);
                setImages((prevImages) => [...prevImages, resizedImageUrl]);
            };
            reader.readAsDataURL(file);
        };
        input.click();
    };
    const handleGalleryClick = ({ setImages }) => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.onchange = (event) => {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onloadend = async () => {
                const resizedImageUrl = await handleChange(file);
                setImages((prevImages) => [...prevImages, resizedImageUrl]);
            };
            reader.readAsDataURL(file);
        };
        input.click();
    };

    useEffect(() => {
        movementFlagValApi();
        AsDetailApi();
    }, [index]);
    return (
        <>
            <HeaderComp3 title={"A/S 문의"} />
            <ProgressBar
                style={{ marginTop: "10px" }}
                progress={6}
                movementFlag={movementFlag}
                index={index}
            />
            <div className="ContentBody">
                <div className="ContentBodyText">
                    <p>
                        <span>시공상의 손상</span>이나 <span>하자</span>에
                        한하여
                    </p>
                    <p>
                        <span>1년간 무상 A/S</span>가 가능합니다.
                    </p>
                </div>
                <div className="cationWrap " style={{ marginTop: "15px" }}>
                    <h2>시공상의 하자가 아닌 경우</h2>
                    <ul>
                        <li>
                            - 고객 귀책 사유로 인한 문제(시공 후 건조 및 관리,
                            오염 등)
                        </li>
                        <li>
                            - 구조물로 인한 한계 및 손상 문제(기존 벽면 및 바닥
                            상태)
                        </li>
                        - 공사 후 외부 충격에 의한 손상(이사나 청소과정에서
                        <li>찢김, 찍힘, 오염)</li>
                    </ul>
                </div>
            </div>
            <div className="ClkContentWrap">
                <div className={`ClkContent active`}>
                    <p>A/S 진행순서</p>
                </div>
                <div className={`ClkContent `}>
                    <p>문의내용</p>
                </div>
                <div className={`ClkContent `}>
                    <p>제출내역</p>
                </div>
            </div>
            <div className="ContentBody">
                <div className="CircleUlWrap">
                    <div className="CircleUl">
                        <div className="Circle">1</div>
                        <p>시공현장 사진 업로드 및 설명 작성 후 A/S 문의</p>
                    </div>
                    <div className="CircleUl">
                        <div className="Circle">2</div>
                        <p>상담전화 받기. 유선을 통한 A/S 상담진행</p>
                    </div>
                </div>
            </div>
            <div className="ClkContentWrap">
                <div className={`ClkContent `}>
                    <p>A/S 진행순서</p>
                </div>
                <div className={`ClkContent active`}>
                    <p>문의내용</p>
                </div>
                <div className={`ClkContent `}>
                    <p>제출내역</p>
                </div>
            </div>
            <div className="ContentBody">
                <div className="ClkCheckWrap">
                    {dobaeOn === 1 && (
                        <div
                            className={`ClkCheck ${
                                TypeClk === 1 ? "active" : ""
                            }`}
                            onClick={() => {
                                setTypeClk(1);
                            }}
                        >
                            <div className="imgWrap">
                                {TypeClk === 1 ? (
                                    <img src={imageData.ClkCheckOn} />
                                ) : (
                                    <img src={imageData.ClkCheckOff} />
                                )}
                            </div>
                            <p>도배</p>
                        </div>
                    )}
                    {zangpanOn === 1 && (
                        <div
                            className={`ClkCheck ${
                                TypeClk === 2 ? "active" : ""
                            }`}
                            onClick={() => {
                                setTypeClk(2);
                            }}
                        >
                            <div className="imgWrap">
                                {TypeClk === 2 ? (
                                    <img src={imageData.ClkCheckOn} />
                                ) : (
                                    <img src={imageData.ClkCheckOff} />
                                )}
                            </div>
                            <p>장판</p>
                        </div>
                    )}

                    {/* <div
                        className={`ClkCheck ${TypeClk === 3 ? "active" : ""}`}
                        onClick={() => {
                            setTypeClk(3);
                        }}
                    >
                        <div className="imgWrap">
                            {TypeClk === 3 ? (
                                <img src={imageData.ClkCheckOn} />
                            ) : (
                                <img src={imageData.ClkCheckOff} />
                            )}
                        </div>
                        <p>마루</p>
                    </div> */}
                </div>
                <div className="FlexCenter" style={{ marginTop: "10px" }}>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            gap: "10px",
                        }}
                    >
                        <button
                            style={{
                                height: "44px",
                                borderRadius: "4px",
                            }}
                            className="btn btnBox "
                            onClick={() =>
                                handleCameraClick({
                                    setImages:
                                        TypeClk === 1
                                            ? setAsDobaeImgs
                                            : TypeClk === 2
                                            ? setAsZangpanImgs
                                            : setAsMaruImgs,
                                })
                            }
                        >
                            카메라 촬영
                        </button>
                        <button
                            style={{
                                height: "44px",
                                borderRadius: "4px",
                            }}
                            className="btn btnBox"
                            onClick={() =>
                                handleGalleryClick({
                                    setImages:
                                        TypeClk === 1
                                            ? setAsDobaeImgs
                                            : TypeClk === 2
                                            ? setAsZangpanImgs
                                            : setAsMaruImgs,
                                })
                            }
                        >
                            갤러리 업로드
                        </button>
                    </div>
                </div>
                <div className="contentPic" style={{ margin: "10px 0px" }}>
                    {TypeClk === 1
                        ? AsDobaeImgs?.map((item, i) => (
                              <div
                                  className="imgWrap"
                                  key={i}
                                  style={{ marginBottom: "10px" }}
                              >
                                  <img
                                      className="uploadImg"
                                      src={
                                          !item?.startsWith("data:")
                                              ? `${consts.ImgState}${item}`
                                              : item
                                      }
                                      alt="pic"
                                  />
                                  <img
                                      className="del"
                                      src={imageData.deleteBtn}
                                      alt="delete"
                                      onClick={() => {
                                          DelImg(i, 1);
                                      }}
                                  />
                              </div>
                          ))
                        : TypeClk === 2
                        ? AsZangpanImgs?.map((item, i) => (
                              <div className="imgWrap" key={i}>
                                  <img
                                      className="uploadImg"
                                      src={
                                          !item?.startsWith("data:")
                                              ? `${consts.ImgState}${item}`
                                              : item
                                      }
                                      alt="pic"
                                  />
                                  <img
                                      className="del"
                                      src={imageData.deleteBtn}
                                      alt="delete"
                                      onClick={() => {
                                          DelImg(i, 2);
                                      }}
                                  />
                              </div>
                          ))
                        : AsMaruImgs?.map((item, i) => (
                              <div className="imgWrap" key={i}>
                                  <img
                                      className="uploadImg"
                                      src={
                                          !item?.startsWith("data:")
                                              ? `${consts.ImgState}${item}`
                                              : item
                                      }
                                      alt="pic"
                                  />
                                  <img
                                      className="del"
                                      src={imageData.deleteBtn}
                                      alt="delete"
                                      onClick={() => {
                                          DelImg(i, 3);
                                      }}
                                  />
                              </div>
                          ))}
                </div>
                <div className="textareaWrap">
                    <textarea
                        placeholder="메모 입력"
                        className="textArea"
                        value={TypeClk === 1 ? AsDobaeMemo : AsZangpanMemo}
                        onChange={textAreaChange}
                    ></textarea>
                </div>
            </div>
            <div className="ClkContentWrap">
                <div className={`ClkContent `}>
                    <p>A/S 진행순서</p>
                </div>
                <div className={`ClkContent `}>
                    <p>문의내용</p>
                </div>
                <div className={`ClkContent active`}>
                    <p>제출내역</p>
                </div>
            </div>
            <div className="ContentBody">
                <div className="ContentUlWrap displayFlexDirect">
                    {createDt?.map((item, i) => (
                        <div className="Ul" key={i}>
                            <h2>{i + 1}</h2>
                            <p>
                                {moment(item?.create_dt).format(
                                    "YYYY-MM-DD HH:mm"
                                )}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="ContentBody">
                <Button title="제출" onClick={() => BtnClk()} />
            </div>
        </>
    );
}
export default UserNewAsInquire;
