export const Button = ({
    title,
    btnType,
    isWhite = false,
    onClick,
    active = true,
    style = {},
}) => {
    return (
        <button
            style={style}
            className={`btnBox ${isWhite ? "white" : ""} ${
                active ? "" : "active"
            }`}
            onClick={onClick}
        >
            {title}
        </button>
    );
};
