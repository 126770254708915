import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";

// 암호화 키 설정
const secretKey = "8amdobaeCCR";

// 암호화 함수
const encrypt = (text) => {
    return encodeURIComponent(CryptoJS.AES.encrypt(text, secretKey).toString());
};

// 복호화 함수
const decrypt = (cipherText) => {
    if (!cipherText) {
        return null;
    }

    try {
        const bytes = CryptoJS.AES.decrypt(
            decodeURIComponent(cipherText),
            secretKey
        );
        const decrypted = bytes.toString(CryptoJS.enc.Utf8);

        return decrypted;
    } catch (error) {
        return null;
    }
};

// 커스텀 훅 정의
const useNavigateWithEncryption = () => {
    const navigate = useNavigate();

    const navigateWithEncryption = (
        route,
        paramName,
        paramValue,
        paramName2,
        paramValue2
    ) => {
        const encryptedValue = encrypt(paramValue);
        navigate(
            `${route}?${paramName}=${encryptedValue}${
                paramName2 ? `&${paramName2}=${paramValue2}` : ""
            }`
        );
    };

    return navigateWithEncryption;
};

// URL에서 암호화된 값을 복호화하는 함수
const getDecryptedParam = (paramName) => {
    const urlParams = new URLSearchParams(window.location.search);
    const encryptedValue = urlParams.get(paramName);
    if (encryptedValue) {
        return decrypt(encryptedValue);
    }
    return null;
};

export { useNavigateWithEncryption, getDecryptedParam };
