import { useEffect, useRef, useState } from "react";
import { BottomBtn } from "../components/BottomBtn";
import { Button } from "../components/Button";
import { HeaderComp3 } from "../components/Header";
import { ProgressBar } from "../components/progressBar";
import ApiData from "../libs/api";
import { imageData } from "../libs/images";
import { usePostData } from "../utils/service";
import { useNavigate, useSearchParams } from "react-router-dom";
import routes from "../libs/routes";
import { toast } from "react-toastify";
import { getDecryptedParam } from "../components/navigateWithEncryption";
import useCustomer from "../zustands/useCustomer";

function UserNewPaymentInfo2() {
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const index = encodeURIComponent(searchParams.get("sigongIdx"));
    const decryptIdx = getDecryptedParam("sigongIdx");
    const looking = Boolean(searchParams.get("Looking"));
    const payerIn = usePostData(ApiData.payerIn);
    const movementFlagVal = usePostData(ApiData.movementFlagVal);
    const sigonginfoDetail = usePostData(ApiData.sigonginfoDetail);
    const movementFlagIn = usePostData(ApiData.movementFlagIn);

    const [movementFlag, setMovementFlag] = useState([]);
    const [AllPrice, setAllPrice] = useState(0);
    const [depositPrice, setDepositPrice] = useState(0);
    const [paymentUser, setPaymentUser] = useState("");
    const [cusmtomPaymentChk, setCusmtomPaymentChk] = useState(0);
    const [editName, setEditName] = useState(false);

    const { setUseCustomerPaymentName, setUseCustomerContractChk } =
        useCustomer();
    const payerInApi = () => {
        let sender = {
            sigongIdx: decryptIdx || index,
            payName: paymentUser,
        };
        if (paymentUser === "") {
            toast.error(
                <div style={{ fontSize: "12px", lineHeight: "19px" }}>
                    결제자명을 입력해주세요.
                </div>,
                {
                    className: "custom-toast-error",
                    position: "bottom-center",
                }
            );
            return;
        }

        payerIn
            .mutateAsync(sender)
            .then((res) => {
                movementFlagIn
                    .mutateAsync({
                        location: 4,
                        sigongIdx: decryptIdx || index,
                    })
                    .then((data) => {
                        toast.success(
                            <div
                                style={{
                                    fontSize: "12px",
                                    lineHeight: "19px",
                                }}
                            >
                                입금자명 변경이 완료되었습니다.
                            </div>,
                            {
                                className: "custom-toast-error-login addressIn",
                                position: "bottom-center",
                            }
                        );
                    })
                    .catch((error) => {
                        toast.error(
                            <div
                                style={{
                                    fontSize: "12px",
                                    lineHeight: "19px",
                                }}
                            >
                                오류 발생하였습니다.
                            </div>,
                            {
                                className: "custom-toast-error-login addressIn",
                                position: "bottom-center",
                            }
                        );
                    });
            })
            .catch((err) => {
                toast.error(err.response.data);
            });
    };
    const copyToClipboard = () => {
        const textToCopy = "기업은행 094-154587-04-022 김진환(여덟시도배)";
        navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
                toast.success(
                    <div
                        style={{
                            fontSize: "12px",
                            lineHeight: "19px",
                        }}
                    >
                        복사되었습니다.
                    </div>,
                    {
                        className: "custom-toast-error",
                        position: "bottom-center",
                    }
                );
            })
            .catch((err) => {
                console.error("복사 실패:", err);
            });
    };

    const BtnClk = () => {
        navigate(`${routes.UserNewMaterialSelect}?sigongIdx=${index}`);
        // movementFlagIn
        //     .mutateAsync({ location: 4, sigongIdx: index })
        //     .then((data) => {
        //         navigate(`${routes.UserNewMaterialSelect}?sigongIdx=${index}`);
        //     })
        //     .catch((error) => {
        //         toast.error(
        //             <div
        //                 style={{
        //                     fontSize: "12px",
        //                     lineHeight: "19px",
        //                 }}
        //             >
        //                 오류 발생하였습니다.
        //             </div>,
        //             {
        //                 className: "custom-toast-error-login addressIn",
        //                 position: "bottom-center",
        //             }
        //         );
        //     });
    };
    const sigonginfoDetailApi = () => {
        sigonginfoDetail
            .mutateAsync({ sigongIdx: decryptIdx || index })
            .then((data) => {
                setUseCustomerPaymentName(data.data.customer_payment_name);
                setUseCustomerContractChk(data.data.customer_payment_chk);

                setCusmtomPaymentChk(data.data.customer_payment_chk);
                setDepositPrice(data.data.customer_payment);
                setPaymentUser(data.data.customer_payment_name);
                setAllPrice(
                    parseInt(data.data.dobae_price || 0) +
                        parseInt(data.data.zangpan_price || 0) +
                        parseInt(data.data.maru_price || 0) +
                        parseInt(data.data.demolition_price || 0)
                );
                movementFlagIn
                    .mutateAsync({
                        location: 4,
                        sigongIdx: decryptIdx || index,
                    })
                    .then((data) => {})
                    .catch((error) => {
                        toast.error(
                            <div
                                style={{
                                    fontSize: "12px",
                                    lineHeight: "19px",
                                }}
                            >
                                오류 발생하였습니다.
                            </div>,
                            {
                                className: "custom-toast-error-login addressIn",
                                position: "bottom-center",
                            }
                        );
                    });
            })
            .catch((err) => {
                // toast.error(err.response.data);
            });
    };
    const movementFlagValApi = () => {
        movementFlagVal
            .mutateAsync({ sigongIdx: decryptIdx || index })
            .then(({ data }) => {
                setMovementFlag(data);
            })
            .catch((error) => {});
    };

    useEffect(() => {
        sigonginfoDetailApi();
        movementFlagValApi();
    }, [index]);

    return (
        <>
            <HeaderComp3 title={"결제안내"} />
            <ProgressBar
                style={{ marginTop: "10px" }}
                progress={4}
                movementFlag={movementFlag}
                index={index}
                paymentChk={cusmtomPaymentChk}
            />
            <div className="ContentBody" style={{ paddingBottom: "70px" }}>
                <div className="ContentBodyHeader">
                    <h1>결제 안내</h1>
                    {cusmtomPaymentChk ? null : (
                        <p>
                            아래 계좌로 계약금을 입금하시면 예약이 확정됩니다.
                        </p>
                    )}
                </div>
                <div className="displayFlexDirect" style={{ gap: "10px" }}>
                    {cusmtomPaymentChk ? null : (
                        <div className="ContentBodyUl">
                            <ul>
                                <li>현재 예약확정 상태가 아닙니다.</li>
                                <li>결제가 완료된 후 예약일이 확정됩니다.</li>
                            </ul>
                        </div>
                    )}
                    <div className="ContentBodyLineWrap Price">
                        <h1>총 계약 금액</h1>
                        <h1>
                            {(AllPrice || 0).toLocaleString()}
                            <span>원</span>
                        </h1>
                    </div>

                    <div className="ContentBodyLineWrap">
                        <div className="ContentBodyLine">
                            <h2>1차 결제 </h2>
                            <p>
                                {(depositPrice || 0).toLocaleString()}원
                                (계약금)
                            </p>
                        </div>
                        <div className="ContentBodyLine">
                            <h2>입금계좌</h2>
                            <div>
                                <p>기업은행 094-154587-04-022</p>
                                <p>김진환(여덟시도배)</p>
                            </div>
                            <img
                                onClick={copyToClipboard}
                                src={imageData.copyImg}
                                className="rightLocation"
                            />
                        </div>
                        <div
                            className="ContentBodyLine"
                            style={{
                                borderBottom: "none",
                                alignItems: "center",
                                paddingBottom: "0px",
                            }}
                        >
                            <h2>입금자명</h2>
                            <input
                                ref={inputRef}
                                value={paymentUser}
                                onChange={(e) => {
                                    setPaymentUser(e.target.value);
                                }}
                                readOnly={!editName}
                            />

                            <div
                                className="rightLocation editBtn"
                                onClick={() => {
                                    setEditName(!editName);
                                    if (!editName) {
                                        inputRef.current.focus();
                                    } else {
                                        payerInApi();
                                    }
                                }}
                            >
                                <p>{editName ? "확인" : "수정"}</p>
                            </div>
                        </div>
                    </div>
                    {cusmtomPaymentChk ? null : (
                        <div className="ContentBodyUl2">
                            <ul>
                                <li>
                                    결제가 완료되면 자재선택창으로 넘어갑니다.
                                </li>
                                <li>
                                    결제 확인까지 최대 2시간 정도 시간이
                                    소요됩니다.
                                </li>
                                <li>
                                    문의사항이 있으실 경우 고객센터(1522-5454)로
                                    연락주세요.
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
                {cusmtomPaymentChk === 1 && (
                    <Button
                        title={"다음"}
                        onClick={BtnClk}
                        style={{ marginTop: "35px" }}
                    />
                )}
            </div>
            <BottomBtn
                active={looking ? 4 : 3}
                movementFlag={movementFlag}
                index={index}
                paymentChk={cusmtomPaymentChk}
            />
        </>
    );
}

export default UserNewPaymentInfo2;
