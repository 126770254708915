import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import routes from "../libs/routes";
import { imageData } from "../libs/images";
import { useEffect, useState } from "react";
import useCustomer from "../zustands/useCustomer";

export function ProgressBar({
    progress,
    isGreen = false,
    movementFlag,
    isError = false,
    index,
    style = {},
    paymentChk = 0,
}) {
    const navigate = useNavigate();
    const location = useLocation();
    const { UseCustomerPaymentName } = useCustomer();
    const clkProgress = (progress, moveFlag) => {
        if (location.pathname === "/UserNewEstimate") {
            toast.error("아직 진행되지 않은 단계입니다.", {
                className: "custom-toast-error",
            });
            return;
        }
        if (movementFlag === null) {
            toast.error("아직 진행되지 않은 단계입니다.", {
                className: "custom-toast-error",
            });
            return;
        }
        if (moveFlag === 0) {
            toast.error("아직 진행되지 않은 단계입니다.", {
                className: "custom-toast-error",
            });
            return;
        } else {
            if (progress === 1) {
                navigate(
                    `${routes.UserNewConstrManagement}?sigongIdx=${index}`
                );
            } else if (progress === 2) {
                navigate(`${routes.UserNewContract}?sigongIdx=${index}`);
            } else if (progress === 3) {
                navigate(`${routes.UserNewAddressIn}?sigongIdx=${index}`);
            } else if (progress === 4) {
                if (movementFlag?.goPaymentInfo === 0) {
                    if (
                        UseCustomerPaymentName === "" ||
                        UseCustomerPaymentName === null
                    ) {
                        navigate(
                            `${routes.UserNewPaymentInfo}?sigongIdx=${index}`
                        );
                    } else if (UseCustomerPaymentName !== "") {
                        navigate(
                            `${routes.UserNewPaymentInfo2}?sigongIdx=${index}`
                        );
                    }
                } else if (movementFlag?.goPaymentInfo === 1) {
                    if (
                        UseCustomerPaymentName === "" ||
                        UseCustomerPaymentName === null
                    ) {
                        navigate(
                            `${routes.UserNewPaymentInfo}?sigongIdx=${index}`
                        );
                    } else if (UseCustomerPaymentName !== "") {
                        navigate(
                            `${routes.UserNewPaymentInfo2}?sigongIdx=${index}&Looking=true`
                        );
                    }
                }
            } else if (progress === 5) {
                navigate(`${routes.UserNewMaterialSelect}?sigongIdx=${index}`);
            } else if (progress === 6) {
                if (movementFlag?.goFinished === 1) {
                    navigate(`${routes.UserNewFinished}?sigongIdx=${index}`);
                } else if (movementFlag?.goFinished === 0) {
                    navigate(
                        `${routes.UserNewFinalConfirm}?sigongIdx=${index}`
                    );
                }
            }
        }
    };

    return (
        <div className="ProgressBar" style={style}>
            <div
                className="progressWrap"
                onClick={() => {
                    if (
                        location.pathname === "/UserNewEstimate" ||
                        location.pathname === "/UserNewConstrManagement"
                    ) {
                        return;
                    }
                    clkProgress(1, movementFlag?.goEstimate);
                }}
            >
                <div
                    className={`progress ${
                        progress === 1
                            ? "isGreenActive"
                            : progress === 6 && isGreen
                            ? "isGreenFinish"
                            : "finish"
                    }`}
                >
                    1
                </div>
                <p
                    className={`progressText ${
                        progress === 1
                            ? "isGreenActive"
                            : progress === 6 && isGreen
                            ? "isGreenFinish"
                            : "finish"
                    }`}
                >
                    {" "}
                    견적서
                </p>
            </div>
            <div
                className={`progressBarLine ${
                    progress === 1 || isGreen ? "greenActive" : "finish"
                }`}
                style={{
                    left: "10%",
                }}
            ></div>
            <div
                className="progressWrap"
                onClick={() => {
                    if (location.pathname === "/UserNewContract") {
                        return;
                    }
                    clkProgress(2, movementFlag?.goEstimate);
                }}
            >
                <div
                    className={`progress ${
                        progress === 2
                            ? "active"
                            : isGreen
                            ? movementFlag?.goContract ||
                              movementFlag?.goEstimate
                                ? "isGreenFinish"
                                : "isGreenNonFinish"
                            : movementFlag?.goContract ||
                              movementFlag?.goEstimate
                            ? "finish"
                            : "nonFinish"
                    }`}
                >
                    2
                </div>
                <p
                    className={`progressText ${
                        progress === 2
                            ? "active"
                            : isGreen
                            ? movementFlag?.goContract ||
                              movementFlag?.goEstimate
                                ? "isGreenFinish"
                                : "isGreenNonFinish"
                            : movementFlag?.goContract ||
                              movementFlag?.goEstimate
                            ? "finish"
                            : "nonFinish"
                    }`}
                >
                    계약서
                </p>
            </div>
            <div
                className={`progressBarLine ${
                    movementFlag?.goContract
                        ? isGreen
                            ? "greenActive"
                            : "finish"
                        : isGreen
                        ? "greenActive"
                        : "nonFinish"
                }`}
                style={{
                    left: "25%",
                }}
            ></div>
            <div
                className="progressWrap"
                onClick={() => {
                    if (location.pathname === "/UserNewAddressIn") {
                        return;
                    }
                    clkProgress(3, movementFlag?.goContract);
                }}
            >
                <div
                    className={`progress ${
                        progress === 3
                            ? "active"
                            : isGreen
                            ? movementFlag?.GoAddressIn ||
                              movementFlag?.goContract
                                ? "isGreenFinish"
                                : "isGreenNonFinish"
                            : movementFlag?.GoAddressIn ||
                              movementFlag?.goContract
                            ? "finish"
                            : "nonFinish"
                    }`}
                    style={{
                        position: "relative",
                    }}
                >
                    {isError && (
                        <img
                            src={imageData.warningOrange}
                            style={{
                                position: "absolute",
                                width: "11px",
                                top: "-3px",
                                right: "-5px",
                            }}
                        />
                    )}
                    3
                </div>
                <p
                    className={`progressText ${
                        progress === 3
                            ? "active"
                            : isGreen
                            ? movementFlag?.GoAddressIn ||
                              movementFlag?.goContract
                                ? "isGreenFinish"
                                : "isGreenNonFinish"
                            : movementFlag?.GoAddressIn ||
                              movementFlag?.goContract
                            ? "finish"
                            : "nonFinish"
                    }`}
                >
                    주소입력
                </p>
            </div>
            <div
                className={`progressBarLine ${
                    movementFlag?.GoAddressIn
                        ? isGreen
                            ? "greenActive"
                            : "finish"
                        : isGreen
                        ? "greenActive"
                        : "nonFinish"
                }`}
                style={{
                    left: "43%",
                }}
            ></div>
            <div
                className="progressWrap"
                onClick={() => {
                    if (
                        location.pathname === "/UserNewPaymentInfo" ||
                        location.pathname === "/UserNewPaymentInfo2"
                    ) {
                        return;
                    }
                    clkProgress(4, movementFlag?.GoAddressIn);
                }}
            >
                <div
                    className={`progress ${
                        progress === 4
                            ? "active"
                            : isGreen
                            ? movementFlag?.goPaymentInfo ||
                              movementFlag?.GoAddressIn
                                ? "isGreenFinish"
                                : "isGreenNonFinish"
                            : movementFlag?.goPaymentInfo ||
                              movementFlag?.GoAddressIn
                            ? "finish"
                            : "nonFinish"
                    }`}
                >
                    4
                </div>
                <p
                    className={`progressText ${
                        progress === 4
                            ? "active"
                            : isGreen
                            ? movementFlag?.goPaymentInfo ||
                              movementFlag?.GoAddressIn
                                ? "isGreenFinish"
                                : "isGreenNonFinish"
                            : movementFlag?.goPaymentInfo ||
                              movementFlag?.GoAddressIn
                            ? "finish"
                            : "nonFinish"
                    }`}
                >
                    결제정보
                </p>
            </div>
            <div
                className={`progressBarLine ${
                    paymentChk || movementFlag?.goMaterialSelect
                        ? isGreen
                            ? "greenActive"
                            : "finish"
                        : isGreen
                        ? "greenActive"
                        : "nonFinish"
                }`}
                style={{
                    left: "58%",
                }}
            ></div>
            <div
                className="progressWrap"
                onClick={() => {
                    if (location.pathname === "/UserNewMaterialSelect") {
                        return;
                    }
                    clkProgress(
                        5,
                        paymentChk || movementFlag?.goMaterialSelect
                    );
                }}
            >
                <div
                    className={`progress ${
                        progress === 5
                            ? "active"
                            : isGreen
                            ? paymentChk || movementFlag?.goMaterialSelect
                                ? "isGreenFinish"
                                : "isGreenNonFinish"
                            : paymentChk || movementFlag?.goMaterialSelect
                            ? "finish"
                            : "nonFinish"
                    }`}
                >
                    5
                </div>
                <p
                    className={`progressText ${
                        progress === 5
                            ? "active"
                            : isGreen
                            ? paymentChk || movementFlag?.goMaterialSelect
                                ? "isGreenFinish"
                                : "isGreenNonFinish"
                            : paymentChk || movementFlag?.goMaterialSelect
                            ? "finish"
                            : "nonFinish"
                    }`}
                >
                    벽지선택
                </p>
            </div>
            <div
                className={`progressBarLine ${
                    movementFlag?.goMaterialSelect
                        ? isGreen
                            ? "greenActive"
                            : "finish"
                        : isGreen
                        ? "greenActive"
                        : "nonFinish"
                }`}
                style={{
                    left: "75%",
                }}
            ></div>
            <div
                className="progressWrap"
                onClick={() => {
                    if (location.pathname === "/UserNewFinalConfirm") {
                        return;
                    }
                    clkProgress(6, movementFlag?.goMaterialSelect);
                }}
            >
                <div
                    className={`progress ${
                        progress === 6 && isGreen
                            ? "isGreenActive"
                            : progress === 6 && !isGreen
                            ? "active"
                            : isGreen
                            ? movementFlag?.goFinished ||
                              movementFlag?.goMaterialSelect
                                ? "isGreenFinish"
                                : "isGreenNonFinish"
                            : movementFlag?.goMaterialSelect
                            ? "finish"
                            : "nonFinish"
                    }`}
                >
                    6
                </div>
                <p
                    className={`progressText ${
                        progress === 6 && isGreen
                            ? "isGreenActive"
                            : progress === 6 && !isGreen
                            ? "active"
                            : isGreen
                            ? movementFlag?.goFinished ||
                              movementFlag?.goMaterialSelect
                                ? "isGreenFinish"
                                : "isGreenNonFinish"
                            : movementFlag?.goMaterialSelect
                            ? "finish"
                            : "nonFinish"
                    }`}
                >
                    최종확인
                </p>
            </div>
        </div>
    );
}
