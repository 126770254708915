import { useEffect, useState } from "react";
import { imageData } from "../libs/images";
import {
    MaterialFinalBaseBoardLook,
    MaterialFinalLook,
    MaterialPopLook,
} from "./BoxInfo";
import { Button } from "./Button";
import Pagination from "react-js-pagination";
import PaginationCustom from "./CustomPageNation";
import { set } from "lodash";
import { usePostData } from "../utils/service";
import ApiData from "../libs/api";
import consts from "../libs/consts";

export const SearchMaterialBaseboardPop = ({
    SigongIdx,
    isOpen,
    setIsOpen,
    materialData,
    setMaterialData,
    materialCount,
    materialCompany,
    setIsSaved,
    innerHeight,
    page,
    setPage,
}) => {
    const Materialrecommend = usePostData(ApiData.Materialrecommend);
    const MaterialNewSelectIn = usePostData(ApiData.MaterialNewSelectIn);
    const [currentPage, setCurrentPage] = useState(1);
    const [clkbox, setClkbox] = useState(1);
    const [materialDataArr, setMaterialDataArr] = useState([]);
    const [firstRecommend, setFirstRecommend] = useState([]);
    const [secondRecommend, setSecondRecommend] = useState([]);
    const [thirdRecommend, setThirdRecommend] = useState([]);
    const [fourthRecommend, setFourthRecommend] = useState([]);

    const MaterialIn = ({ type, materialThings, ceiling }) => {
        let sender = {
            sigongIdx: SigongIdx,
            material_type: type,
            material: materialThings,
            ceiling: ceiling,
        };
        MaterialNewSelectIn.mutateAsync(sender)
            .then(({ data }) => {
                setIsOpen(!isOpen);
                setIsSaved(true);
                console.log("데이터 저장에 성공했습니다.");
            })
            .catch((error) => {
                console.log("데이터 저장에 실패했습니다.");
            });
    };

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const CloseBtn = () => {
        setCurrentPage(1);
        setMaterialData([]);
        setPage(1);
        setIsOpen(!isOpen);
    };
    const onSaveClk = () => {
        setCurrentPage(1);
        MaterialIn({
            type: 4,
            materialThings: materialDataArr,
        });
        setIsOpen(!isOpen);
    };
    const MaterialrecommendApi = () => {
        let sender = {
            material_type: 4,
            material_company: materialDataArr.material_company,
            material_class01: materialDataArr.material_class01,
            material_idx: materialDataArr.idx,
        };
        Materialrecommend.mutateAsync(sender)
            .then(({ data }) => {
                setFirstRecommend(data.firstThing);
                setSecondRecommend(data.secondThing);
                setThirdRecommend(data.thirdThing);
                setFourthRecommend(data.fourthThing);
            })
            .catch((error) => {});
    };
    useEffect(() => {
        if (materialDataArr?.material_company) {
            MaterialrecommendApi();
        }
    }, [currentPage, materialDataArr]);
    return (
        <>
            {isOpen && (
                <div className="overlay">
                    <div
                        className="LongPopup"
                        style={{
                            top: "70px",
                            height: `calc(${innerHeight}px - 65px)`,
                            // minHeight: "unset",
                        }}
                    >
                        {currentPage === 1 && (
                            <div
                                style={{ justifyContent: "space-between" }}
                                className="popupTitle"
                            >
                                <h1>자재 검색</h1>
                                <img
                                    style={{ cursor: "pointer" }}
                                    onClick={() => CloseBtn()}
                                    src={imageData.exit}
                                    alt="exit"
                                />
                            </div>
                        )}
                        {currentPage === 2 && (
                            <div
                                style={{ justifyContent: "center" }}
                                className="popupTitle"
                            >
                                <h1>자재 선택</h1>
                                <img
                                    style={{
                                        cursor: "pointer",
                                        position: "absolute",
                                        left: "27.5px",
                                        height: "17px",
                                    }}
                                    onClick={() => setCurrentPage(1)}
                                    src={imageData.ArrowLeft}
                                    alt="exit"
                                />
                            </div>
                        )}
                        <div className="materialPopupContentWrap">
                            {currentPage === 1 && (
                                <>
                                    <div className="ClkContentWrap">
                                        <div className={"ClkContent active"}>
                                            {materialCompany}
                                        </div>
                                    </div>
                                    <div
                                        className="materialPopupContent"
                                        style={{ paddingTop: "0px" }}
                                    >
                                        <div className="materialPopupSearch">
                                            <div className="Count Width100">
                                                <p>총 {materialCount || 0}개</p>
                                            </div>
                                        </div>
                                        <div className="sub_materialList_wrap">
                                            <div className="sub_materialList">
                                                {materialData?.map(
                                                    (item, index) => {
                                                        return (
                                                            <MaterialPopLook
                                                                key={index}
                                                                material_badge={
                                                                    item.material_badge
                                                                }
                                                                material_img={
                                                                    item.material_img
                                                                }
                                                                material_company={
                                                                    item.material_company
                                                                }
                                                                material_number={
                                                                    item.material_number
                                                                }
                                                                material_name={
                                                                    item.material_name
                                                                }
                                                                material_color={
                                                                    item.material_color
                                                                }
                                                                material_pattern={
                                                                    item.material_pattern
                                                                }
                                                                onClick={() => {
                                                                    setMaterialDataArr(
                                                                        item
                                                                    );
                                                                    setCurrentPage(
                                                                        2
                                                                    );
                                                                }}
                                                            />
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>

                                        <div className="PaginationContent">
                                            <PaginationCustom
                                                totalPages={
                                                    Math.ceil(
                                                        materialCount / 20
                                                    ) || 0
                                                }
                                                onPageChange={handlePageChange}
                                                page={page}
                                                setPage={setPage}
                                            />
                                            {/* <Pagination
                                                className="my-pagination"
                                                // 현제 보고있는 페이지
                                                activePage={page}
                                                // 한페이지에 출력할 아이템수
                                                itemsCountPerPage={20}
                                                // 총 아이템수
                                                totalItemsCount={
                                                    materialCount || 0
                                                }
                                                // 표시할 페이지수
                                                pageRangeDisplayed={3}
                                                // 함수
                                                onChange={handlePageChange}
                                                nextPageText="다음 >"
                                                prevPageText="< 이전"
                                                firstPageText=""
                                                lastPageText=""
                                            ></Pagination> */}
                                        </div>
                                    </div>
                                </>
                            )}
                            {currentPage === 2 && (
                                <div
                                    className="materialPopupContentWrap2"
                                    style={{
                                        maxHeight: `calc(${innerHeight}px - 125px)`,
                                    }}
                                >
                                    <div className="materialPopupContent">
                                        <MaterialFinalBaseBoardLook
                                            material_img={
                                                materialDataArr.material_img
                                            }
                                            material_company={
                                                materialDataArr.material_company
                                            }
                                            material_number={
                                                materialDataArr.material_number
                                            }
                                            material_name={
                                                materialDataArr.material_name
                                            }
                                            material_pattern={
                                                materialDataArr.material_pattern
                                            }
                                            material_color={
                                                materialDataArr.material_color
                                            }
                                        />
                                        <div className="materialDetailEditor">
                                            <div
                                                className="editor-text"
                                                dangerouslySetInnerHTML={{
                                                    __html: materialDataArr.material_editor,
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                    <div className="materialRecommandWrap">
                                        {/* <div className="title">
                                            <h1>추천 걸레받이</h1>
                                        </div>
                                        <div className="materialRecommand">
                                            {firstRecommend?.material_img && (
                                                <div
                                                    className="recommandThing"
                                                    onClick={() => {
                                                        setMaterialDataArr(
                                                            firstRecommend
                                                        );
                                                    }}
                                                >
                                                    <div className="ImgWrap">
                                                        <img
                                                            src={
                                                                consts.ImgState +
                                                                firstRecommend?.material_img
                                                            }
                                                        />
                                                        <div
                                                            className={`badge ${
                                                                firstRecommend?.material_badge ===
                                                                0
                                                                    ? "None"
                                                                    : firstRecommend?.material_badge ===
                                                                      1
                                                                    ? "NEW"
                                                                    : firstRecommend?.material_badge ===
                                                                      2
                                                                    ? "BEST"
                                                                    : firstRecommend?.material_badge ===
                                                                      3
                                                                    ? "PREMIUM"
                                                                    : firstRecommend?.material_badge ===
                                                                      4
                                                                    ? "HIGHEND"
                                                                    : ""
                                                            }`}
                                                        >
                                                            {firstRecommend?.material_badge ===
                                                            1
                                                                ? "NEW"
                                                                : firstRecommend?.material_badge ===
                                                                  2
                                                                ? "BEST"
                                                                : firstRecommend?.material_badge ===
                                                                  3
                                                                ? "PREMIUM"
                                                                : firstRecommend?.material_badge ===
                                                                  4
                                                                ? "HIGHEND"
                                                                : ""}
                                                        </div>
                                                    </div>
                                                    <h1>걸레받이</h1>
                                                    <p>
                                                        {
                                                            firstRecommend?.material_number
                                                        }
                                                    </p>
                                                </div>
                                            )}
                                            {secondRecommend?.material_img && (
                                                <div
                                                    className="recommandThing"
                                                    onClick={() => {
                                                        setMaterialDataArr(
                                                            secondRecommend
                                                        );
                                                    }}
                                                >
                                                    <div className="ImgWrap">
                                                        <img
                                                            src={
                                                                consts.ImgState +
                                                                secondRecommend?.material_img
                                                            }
                                                        />
                                                        <div
                                                            className={`badge ${
                                                                secondRecommend?.material_badge ===
                                                                0
                                                                    ? "None"
                                                                    : secondRecommend?.material_badge ===
                                                                      1
                                                                    ? "NEW"
                                                                    : secondRecommend?.material_badge ===
                                                                      2
                                                                    ? "BEST"
                                                                    : secondRecommend?.material_badge ===
                                                                      3
                                                                    ? "PREMIUM"
                                                                    : secondRecommend?.material_badge ===
                                                                      4
                                                                    ? "HIGHEND"
                                                                    : ""
                                                            }`}
                                                        >
                                                            {secondRecommend?.material_badge ===
                                                            1
                                                                ? "NEW"
                                                                : secondRecommend?.material_badge ===
                                                                  2
                                                                ? "BEST"
                                                                : secondRecommend?.material_badge ===
                                                                  3
                                                                ? "PREMIUM"
                                                                : secondRecommend?.material_badge ===
                                                                  4
                                                                ? "HIGHEND"
                                                                : ""}
                                                        </div>
                                                    </div>
                                                    <h1>걸레받이</h1>
                                                    <p>
                                                        {
                                                            secondRecommend?.material_number
                                                        }
                                                    </p>
                                                </div>
                                            )}
                                            {thirdRecommend?.material_img && (
                                                <div
                                                    className="recommandThing"
                                                    onClick={() => {
                                                        setMaterialDataArr(
                                                            thirdRecommend
                                                        );
                                                    }}
                                                >
                                                    <div className="ImgWrap">
                                                        <img
                                                            src={
                                                                consts.ImgState +
                                                                thirdRecommend?.material_img
                                                            }
                                                        />
                                                        <div
                                                            className={`badge ${
                                                                thirdRecommend?.material_badge ===
                                                                0
                                                                    ? "None"
                                                                    : thirdRecommend?.material_badge ===
                                                                      1
                                                                    ? "NEW"
                                                                    : thirdRecommend?.material_badge ===
                                                                      2
                                                                    ? "BEST"
                                                                    : thirdRecommend?.material_badge ===
                                                                      3
                                                                    ? "PREMIUM"
                                                                    : thirdRecommend?.material_badge ===
                                                                      4
                                                                    ? "HIGHEND"
                                                                    : ""
                                                            }`}
                                                        >
                                                            {thirdRecommend?.material_badge ===
                                                            1
                                                                ? "NEW"
                                                                : thirdRecommend?.material_badge ===
                                                                  2
                                                                ? "BEST"
                                                                : thirdRecommend?.material_badge ===
                                                                  3
                                                                ? "PREMIUM"
                                                                : thirdRecommend?.material_badge ===
                                                                  4
                                                                ? "HIGHEND"
                                                                : ""}
                                                        </div>
                                                    </div>
                                                    <h1>걸레받이</h1>
                                                    <p>
                                                        {
                                                            thirdRecommend?.material_number
                                                        }
                                                    </p>
                                                </div>
                                            )}
                                            {fourthRecommend?.material_img && (
                                                <div
                                                    className="recommandThing"
                                                    onClick={() => {
                                                        setMaterialDataArr(
                                                            fourthRecommend
                                                        );
                                                    }}
                                                >
                                                    <div className="ImgWrap">
                                                        <img
                                                            src={
                                                                consts.ImgState +
                                                                fourthRecommend?.material_img
                                                            }
                                                        />
                                                        <div
                                                            className={`badge ${
                                                                fourthRecommend?.material_badge ===
                                                                0
                                                                    ? "None"
                                                                    : fourthRecommend?.material_badge ===
                                                                      1
                                                                    ? "NEW"
                                                                    : fourthRecommend?.material_badge ===
                                                                      2
                                                                    ? "BEST"
                                                                    : fourthRecommend?.material_badge ===
                                                                      3
                                                                    ? "PREMIUM"
                                                                    : fourthRecommend?.material_badge ===
                                                                      4
                                                                    ? "HIGHEND"
                                                                    : ""
                                                            }`}
                                                        >
                                                            {fourthRecommend?.material_badge ===
                                                            1
                                                                ? "NEW"
                                                                : fourthRecommend?.material_badge ===
                                                                  2
                                                                ? "BEST"
                                                                : fourthRecommend?.material_badge ===
                                                                  3
                                                                ? "PREMIUM"
                                                                : fourthRecommend?.material_badge ===
                                                                  4
                                                                ? "HIGHEND"
                                                                : ""}
                                                        </div>
                                                    </div>
                                                    <h1>걸레받이</h1>
                                                    <p>
                                                        {
                                                            fourthRecommend?.material_number
                                                        }
                                                    </p>
                                                </div>
                                            )}
                                        </div> */}
                                        <div
                                            style={{
                                                padding: "10px 0",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <Button
                                                title={"이 자재 선택하기"}
                                                onClick={() => onSaveClk()}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="goBack"
                                        onClick={() => setCurrentPage(1)}
                                    >
                                        <img src={imageData.threeLine} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
