import { create } from "zustand";

export const useCustomer = create((set) => ({
    UseCustomerPaymentName: "",
    UseCustomerContractChk: 0,

    setUseCustomerContractChk: (v) => set({ UseCustomerContractChk: v }),
    setUseCustomerPaymentName: (v) => set({ UseCustomerPaymentName: v }),
}));

export default useCustomer;
