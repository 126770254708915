export const resizeFile = (file, maxWidth, maxHeight) => {
    return new Promise((resolve) => {
        let img = document.createElement("img");
        img.src = URL.createObjectURL(file);
        img.onload = () => {
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");

            var MAX_WIDTH = maxWidth;
            var MAX_HEIGHT = maxHeight;
            var width = img.width;
            var height = img.height;

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);
            resolve(canvas.toDataURL());
        };
    });
};
export function imageDownFunc(url) {
    fetch(url, {
        method: "GET",
        headers: {},
    })
        .then((response) => {
            response.arrayBuffer().then(function (buffer) {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "download.png"); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
        })
        .catch((err) => {
            console.log(err);
        });
}
