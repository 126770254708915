import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useWidth = create((set) => ({
    zustandWidth: window.innerWidth / 360,
    zustandHeight: 0,
    setZustandWidth: (v) => set({ zustandWidth: v }),
    setZustandHeight: (v) =>
        set({
            zustandHeight: (v = {}),
        }),
}));


