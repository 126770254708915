import { toast } from "react-toastify";
import { imageData } from "../libs/images";
import routes from "../libs/routes";
import { useLocation, useNavigate } from "react-router-dom";
import useCustomer from "../zustands/useCustomer";
import { useEffect } from "react";
export function BottomBtn({ active, movementFlag, index, paymentChk = 0 }) {
    const navigate = useNavigate();
    const location = useLocation();

    const { UseCustomerContractChk, UseCustomerPaymentName } = useCustomer();

    const clkProgress = (progress) => {
        const navigateTo = (route) =>
            navigate(`${route}?sigongIdx=${index}&Looking=true`);

        const OriginNavigateTo = (route) =>
            navigate(`${route}?sigongIdx=${index}`);
        const showError = (locationNow) => {
            if (locationNow === location.pathname) {
                return;
            }
            toast.error("아직 진행되지 않은 단계입니다.", {
                className: "custom-toast-error",
            });
        };

        switch (progress) {
            case 1:
                navigateTo(routes.UserNewConstrManagement);
                break;
            case 2:
                if (movementFlag === null) {
                    showError("/UserNewContract");
                    return;
                }
                movementFlag?.goEstimate === 1
                    ? navigateTo(routes.UserNewContract)
                    : showError("/UserNewContract");
                break;
            case 3:
                if (movementFlag === null) {
                    OriginNavigateTo(routes.UserNewConstrManagement);
                    return;
                }
                if (movementFlag?.goEstimate === 0) {
                    OriginNavigateTo(routes.UserNewConstrManagement);
                } else if (movementFlag?.goContract === 0) {
                    OriginNavigateTo(routes.UserNewContract);
                } else if (movementFlag?.GoAddressIn === 0) {
                    OriginNavigateTo(routes.UserNewAddressIn);
                } else if (movementFlag?.goPaymentInfo === 0) {
                    if (
                        UseCustomerPaymentName === "" ||
                        UseCustomerPaymentName === null
                    ) {
                        OriginNavigateTo(routes.UserNewPaymentInfo);
                    } else {
                        OriginNavigateTo(routes.UserNewPaymentInfo2);
                    }
                } else if (movementFlag?.goMaterialSelect === 0) {
                    if (UseCustomerContractChk === 1) {
                        OriginNavigateTo(routes.UserNewMaterialSelect);
                    } else {
                        OriginNavigateTo(routes.UserNewPaymentInfo2);
                    }
                } else {
                    OriginNavigateTo(routes.UserNewFinalConfirm);
                }
                break;
            case 4:
                if (movementFlag === null) {
                    showError("/UserNewPaymentInfo2");
                    return;
                }
                if (movementFlag?.GoAddressIn === 0) {
                    showError("/UserNewPaymentInfo");
                    return;
                }
                if (
                    movementFlag?.GoAddressIn === 1 &&
                    (UseCustomerPaymentName === "" ||
                        UseCustomerPaymentName === null)
                ) {
                    navigateTo(routes.UserNewPaymentInfo);
                    return;
                }
                if (
                    movementFlag?.GoAddressIn === 1 &&
                    UseCustomerPaymentName !== ""
                ) {
                    navigateTo(routes.UserNewPaymentInfo2);
                    return;
                }
                break;
            case 5:
                if (movementFlag === null) {
                    showError("/UserNewMaterialSelect");
                    return;
                }
                UseCustomerContractChk === 1
                    ? navigateTo(routes.UserNewMaterialSelect)
                    : showError("/UserNewMaterialSelect");
                break;
            default:
                break;
        }
    };
    return (
        <div className="BottomBtnWrap">
            <div
                className="bottomBtn"
                onClick={() => {
                    clkProgress(1);
                }}
            >
                {active === 1 ? (
                    <img src={imageData.CalculaterOn} />
                ) : (
                    <img src={imageData.CalculaterOff} />
                )}

                <p className={active === 1 ? "active" : ""}>견적서</p>
            </div>
            <div
                className="bottomBtn"
                onClick={() => {
                    clkProgress(2);
                }}
            >
                {active === 2 ? (
                    <img src={imageData.documentOn} />
                ) : (
                    <img src={imageData.documentOff} />
                )}

                <p className={active === 2 ? "active" : ""}>계약서</p>
            </div>
            <div
                className="bottomBtn"
                onClick={() => {
                    clkProgress(3);
                }}
            >
                {active === 3 ? (
                    <img src={imageData.homeOn} />
                ) : (
                    <img src={imageData.homeOff} />
                )}

                <p className={active === 3 ? "active" : ""}>진행중</p>
            </div>
            <div
                className="bottomBtn"
                onClick={() => {
                    clkProgress(4);
                }}
            >
                {active === 4 ? (
                    <img src={imageData.paymentOn} />
                ) : (
                    <img src={imageData.paymentOff} />
                )}

                <p className={active === 4 ? "active" : ""}>결제정보</p>
            </div>
            <div
                className="bottomBtn"
                onClick={() => {
                    clkProgress(5);
                }}
            >
                {active === 5 ? (
                    <img src={imageData.searchOn} />
                ) : (
                    <img src={imageData.searchOff} />
                )}

                <p className={active === 5 ? "active" : ""}>벽지조회</p>
            </div>
        </div>
    );
}
