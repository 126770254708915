import { useEffect, useState } from "react";
import { ContentBox } from "../components/BoxInfo";
import { ProgressBar } from "../components/progressBar";
import { imageData } from "../libs/images";
import { Button } from "../components/Button";
import { BottomBtn } from "../components/BottomBtn";
import { usePostData } from "../utils/service";
import consts from "../libs/consts";
import ApiData from "../libs/api";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import routes from "../libs/routes";
import KakaoShareButton from "../components/kakaoBtn";
import { getDecryptedParam } from "../components/navigateWithEncryption";
import { useCustomer } from "../zustands/useCustomer";
import { Link, Element, animateScroll as scroll } from "react-scroll";

function UserNewConstrManagement() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const index = encodeURIComponent(searchParams.get("sigongIdx"));
    const decryptIdx = getDecryptedParam("sigongIdx");
    const looking = Boolean(searchParams.get("Looking"));

    const [agreeChk, setAgreeChk] = useState(false);
    const movementFlagVal = usePostData(ApiData.movementFlagVal);
    const movementFlagIn = usePostData(ApiData.movementFlagIn);
    const sigonginfoDetail = usePostData(ApiData.sigonginfoDetail);
    const [cusmtomPaymentChk, setCusmtomPaymentChk] = useState(0);
    const [customerName, setCustomerName] = useState("");
    const [customerPhone, setCustomerPhone] = useState("");
    const [address, setAddress] = useState("");
    const [addressDetail, setAddressDetail] = useState("");
    const [area, setArea] = useState(0);
    const [areaPyeong, setAreaPyeong] = useState(0);
    const [veranda, setVeranda] = useState(0);
    const [verandaMemo, setVerandaMemo] = useState("");
    const [todayStatus, setTodayStatus] = useState(0);
    const [todayMemo, setTodayMemo] = useState("");
    const [beforeMemo, setBeforeMemo] = useState("");
    const [planImg, setPlanImg] = useState("");

    const [dobaeOn, setDobaeOn] = useState(0);
    const [dobaePrice, setDobaePrice] = useState(0);
    const [dobaeDate, setDobaeDate] = useState(null);
    const [dobaeTime, setDobaeTime] = useState("");
    const [dobaeRange, setDobaeRange] = useState(0);
    const [dobaeRangeMemo, setDobaeRangeMemo] = useState("");
    const [dobaeProduct, setDobaeProduct] = useState(0);
    const [dobaeCnt, setDobaeCnt] = useState(0);
    const [dobaeCeiling, setDobaeCeiling] = useState(0);
    const [dobaeCreateCompanyName, setDobaeCreateCompanyName] = useState("");

    const [zangpanOn, setZangpanOn] = useState(0);
    const [zangpanPrice, setZangpanPrice] = useState(0);
    const [zangpanDate, setZangpanDate] = useState(null);
    const [zangpanTime, setZangpanTime] = useState("");
    const [zangpanRange, setZangpanRange] = useState(0);
    const [zangpanRangeMemo, setZangpanRangeMemo] = useState("");
    const [zangpanMM, setZangpanMM] = useState("");
    const [zangpanMolding, setZangpanMolding] = useState(0);
    const [zangpanMoldingMemo, setZangpanMoldingMemo] = useState("");
    const [zangpanMoldingRange, setZangpanMoldingRange] = useState(0);
    const [zangpanMoldingRangeMemo, setZangpanMoldingRangeMemo] = useState("");
    const [zangpanCreateCompanyName, setZangpanCreateCompanyName] =
        useState("");
    const [zangpanThreshold, setZangpanThreshold] = useState(0);

    const [maruOn, setMaruOn] = useState(0);
    const [maruPrice, setMaruPrice] = useState(0);
    const [maruDate, setMaruDate] = useState(null);
    const [maruTime, setMaruTime] = useState("");
    const [maruRange, setMaruRange] = useState(0);
    const [maruRangeMemo, setMaruRangeMemo] = useState("");
    const [maruCreateCompanyName, setMaruCreateCompanyName] = useState("");
    const [maruFlooring, setMaruFlooring] = useState(0);
    const [maruFlooringMemo, setMaruFlooringMemo] = useState("");
    const [maruOldProduct, setMaruOldProduct] = useState("");
    const [maruDownMolding, setMaruDownMolding] = useState(0);
    const [maruThreshold, setMaruThreshold] = useState(0);
    const [maruRepair, setMaruRepair] = useState(0);

    const [demolitionPrice, setDemolitionPrice] = useState(0);
    const [demolitionDate, setDemolitionDate] = useState(null);
    const [demolitionTime, setDemolitionTime] = useState("");
    const [demolitionRange, setDemolitionRange] = useState("");
    const [customerMemo, setCustomerMemo] = useState("");

    const [movementFlag, setMovementFlag] = useState([]);

    const {
        setUseCustomerPaymentName,
        setUseCustomerContractChk,
        UseCustomerContractChk,
    } = useCustomer();
    const GoNext = () => {
        if (!movementFlag.goEstimate && agreeChk) {
            movementFlagIn
                .mutateAsync({ location: 1, sigongIdx: decryptIdx || index })
                .then(() => {
                    navigate(`${routes.UserNewContract}?sigongIdx=${index}`);
                });
            return;
        } else {
            if (!agreeChk) {
                toast.error(
                    <div
                        style={{
                            fontSize: "12px",
                            lineHeight: "19px",
                        }}
                    >
                        시공정보가 실제현장과 다름이 없음을 확인해주세요.
                    </div>,
                    {
                        className: "custom-toast-error",
                        position: "bottom-center",
                    }
                );
            } else if (movementFlag.goEstimate) {
                navigate(`${routes.UserNewContract}?sigongIdx=${index}`);
                return;
            }
        }
    };
    // const handleShare = () => {
    //     const url = window.location.href;
    //     navigator.clipboard.writeText(url).then(() => {
    //         toast.success("URL이 복사되었습니다.", {
    //             className: "custom-toast-success",
    //             position: "top-center",
    //         });
    //     });
    // };

    const movementFlagValApi = () => {
        movementFlagVal
            .mutateAsync({ sigongIdx: decryptIdx || index })
            .then(({ data }) => {
                if (data === null) {
                    movementFlagIn
                        .mutateAsync({
                            location: 0,
                            sigongIdx: decryptIdx || index,
                        })
                        .then((data) => {
                            navigate(
                                `${routes.UserNewConstrManagement}?sigongIdx=${index}`
                            );
                            setMovementFlag(data);
                            setAgreeChk(Boolean(data.goEstimate));
                            return;
                        })
                        .catch((error) => {});
                    return;
                }
                setMovementFlag(data);
                setAgreeChk(Boolean(data.goEstimate));
            })
            .catch((error) => {});
    };
    const sigonginfoDetailApi = () => {
        sigonginfoDetail
            .mutateAsync({ sigongIdx: decryptIdx || index })
            .then((data) => {
                // console.log("data", data.data);

                setUseCustomerPaymentName(data.data.customer_payment_name);
                setUseCustomerContractChk(data.data.customer_payment_chk);

                setCustomerName(data.data.cutomer_name);
                setCustomerPhone(data.data.mb_phone);
                setAddress(data.data.address);
                setAddressDetail(data.data.address_detail);
                setArea(data.data.area);
                setAreaPyeong(data.data.area_pyeong);
                setVeranda(data.data.veranda);
                setVerandaMemo(data.data.veranda_memo);
                setTodayStatus(data.data.today_status);
                setTodayMemo(data.data.today_memo);
                setBeforeMemo(data.data.before_memo);
                setDobaeOn(data.data.dobae);
                setDobaePrice(data.data.dobae_price);
                setDobaeDate(data.data.dobae_date);
                setDobaeTime(data.data.dobae_time);
                setDobaeRange(data.data.dobae_range);
                setDobaeRangeMemo(data.data.dobae_range_memo);
                setDobaeProduct(data.data.dobae_product);
                setDobaeCnt(data.data.dobae_cnt);
                setDobaeCeiling(data.data.dobae_ceiling);
                setDobaeCreateCompanyName(data.data.dobae_company_name);
                setPlanImg(data.data.plan_file);

                setZangpanOn(data.data.zangpan);
                setZangpanPrice(data.data.zangpan_price);
                setZangpanDate(data.data.zangpan_date);
                setZangpanTime(data.data.zangpan_time);
                setZangpanRange(data.data.zangpan_range);
                setZangpanRangeMemo(data.data.zangpan_range_memo);
                setZangpanMM(data.data.zangpan_mm);
                setZangpanMolding(data.data.zangpan_molding);
                setZangpanMoldingMemo(data.data.zangpan_molding_memo);
                setZangpanMoldingRange(data.data.zangpan_molding_range);
                setZangpanMoldingRangeMemo(
                    data.data.zangpan_molding_range_memo
                );
                setZangpanCreateCompanyName(data.data.zangpan_company_name);
                setZangpanThreshold(data.data.zangpan_threshold);

                setMaruOn(data.data.maru);
                setMaruPrice(data.data.maru_price);
                setMaruDate(data.data.maru_date);
                setMaruTime(data.data.maru_time);
                setMaruRange(data.data.maru_range);
                setMaruRangeMemo(data.data.maru_range_memo);
                setMaruCreateCompanyName(data.data.maru_company_name);
                setMaruFlooring(data.data.maru_flooring);
                setMaruFlooringMemo(data.data.maru_flooring_memo);
                setMaruOldProduct(data.data.maru_ori_flooring);
                setMaruDownMolding(data.data.maru_molding);
                setMaruThreshold(data.data.maru_threshold);
                setMaruRepair(data.data.maru_cure);

                setDemolitionPrice(data.data.demolition_price);
                setDemolitionDate(data.data.demolition_date);
                setDemolitionTime(data.data.demolition_time);
                setDemolitionRange(data.data.demolition_range);
                setCustomerMemo(data.data.customer_direct_memo);
                setCusmtomPaymentChk(data.data.customer_payment_chk);
            })
            .catch((error) => {});
    };
    useEffect(() => {
        sigonginfoDetailApi();
        movementFlagValApi();
    }, [index]);

    return (
        <>
            <div className="EstimateHeader">
                <h2>견적서</h2>
                <ProgressBar
                    style={{ marginTop: "-5px" }}
                    progress={1}
                    movementFlag={movementFlag}
                    index={index}
                    isGreen={true}
                    paymentChk={cusmtomPaymentChk}
                />

                <div className="EstimateHeaderMainWrap">
                    <div className="EstimateHeaderMain">
                        <img className="estimateImg" src={imageData.estimate} />{" "}
                        <p>
                            <span>
                                {customerName || customerPhone.slice(-4)}
                            </span>
                            님의 견적서입니다.
                        </p>
                    </div>

                    <p className="estimatePrice">
                        <span style={{ marginRight: "5px" }}>{`${(
                            dobaePrice +
                            zangpanPrice +
                            maruPrice +
                            demolitionPrice
                        ).toLocaleString()}`}</span>
                        원{" "}
                    </p>
                </div>
            </div>

            <div className="EstimateBody">
                <Element name="estimateBodyMain">
                    <div className="ClkContentWrap ">
                        <Link
                            to="estimateBodyMain"
                            smooth={true}
                            duration={500}
                        >
                            <div className={`ClkContent active`}>
                                <p>기본정보</p>
                            </div>
                        </Link>
                        {dobaeOn === 1 && (
                            <Link to="dobaeOn" smooth={true} duration={500}>
                                <div className={`ClkContent `}>
                                    <p>도배</p>
                                </div>
                            </Link>
                        )}
                        {zangpanOn === 1 && (
                            <Link to="zangpanOn" smooth={true} duration={500}>
                                <div className={`ClkContent `}>
                                    <p>장판</p>
                                </div>
                            </Link>
                        )}
                        {maruOn === 1 && (
                            <Link to="maruOn" smooth={true} duration={500}>
                                <div className={`ClkContent `}>
                                    <p>마루</p>
                                </div>
                            </Link>
                        )}
                        {maruOn === 1 && demolitionPrice !== 0 && (
                            <Link to="demolition" smooth={true} duration={500}>
                                <div className={`ClkContent `}>
                                    <p>철거</p>
                                </div>
                            </Link>
                        )}
                    </div>
                </Element>

                <div className="EstimateBodyMain">
                    <ContentBox
                        title={"주소/시공지"}
                        content={`${
                            address !== null && addressDetail !== null
                                ? address + " " + addressDetail
                                : address !== null && addressDetail === null
                                ? address
                                : address === null && addressDetail !== null
                                ? addressDetail
                                : "-"
                        }`}
                    />
                    <ContentBox
                        title={"면적"}
                        content={`${area == 1 ? "공급면적" : "전용면적"} ${
                            areaPyeong ? areaPyeong : 0
                        }평`}
                    />
                    <ContentBox
                        title={"베란다"}
                        content={`${
                            veranda == 1
                                ? "베란다 확장 있음"
                                : veranda == 2
                                ? "베란다 확장 없음"
                                : "베란다 확장 미정"
                        }${verandaMemo ? `/${verandaMemo}` : ""}`}
                    />
                    <ContentBox
                        title={"거주상태"}
                        content={`${
                            todayStatus == 1
                                ? "빈집"
                                : todayStatus == 2
                                ? "거주중 가구 있음"
                                : "이사 당일"
                        }${todayMemo ? `/${todayMemo} ` : ""}`}
                    />{" "}
                    {/* <ContentBox
                        title={"사전시공여부"}
                        content={beforeMemo || "-"}
                    /> */}
                    <ContentBox
                        title={"고객메모/ \n 요청사항"}
                        content={customerMemo ? customerMemo : null}
                    />
                </div>

                {dobaeOn === 1 && (
                    <>
                        <div className="cutLine" />
                        <Element name="dobaeOn">
                            <div className="ClkContentWrap ">
                                <Link
                                    to="estimateBodyMain"
                                    smooth={true}
                                    duration={500}
                                >
                                    <div className={`ClkContent `}>
                                        <p>기본정보</p>
                                    </div>
                                </Link>
                                {dobaeOn === 1 && (
                                    <Link
                                        to="dobaeOn"
                                        smooth={true}
                                        duration={500}
                                    >
                                        <div className={`ClkContent active`}>
                                            <p>도배</p>
                                        </div>
                                    </Link>
                                )}
                                {zangpanOn === 1 && (
                                    <Link
                                        to="zangpanOn"
                                        smooth={true}
                                        duration={500}
                                    >
                                        <div className={`ClkContent `}>
                                            <p>장판</p>
                                        </div>
                                    </Link>
                                )}
                                {maruOn === 1 && (
                                    <Link
                                        to="maruOn"
                                        smooth={true}
                                        duration={500}
                                    >
                                        <div className={`ClkContent `}>
                                            <p>마루</p>
                                        </div>
                                    </Link>
                                )}
                                {maruOn === 1 && demolitionPrice !== 0 && (
                                    <Link
                                        to="demolition"
                                        smooth={true}
                                        duration={500}
                                    >
                                        <div className={`ClkContent `}>
                                            <p>철거</p>
                                        </div>
                                    </Link>
                                )}
                            </div>
                            <div className="EstimateBodyMain">
                                <ContentBox
                                    title={"시공일"}
                                    content={`${moment(dobaeDate).format(
                                        "YYYY-MM-DD"
                                    )} (${
                                        consts.dayText[moment(dobaeDate).day()]
                                    }요일) ${
                                        dobaeTime
                                            ? moment(dobaeTime, "HH:mm:ss")
                                                  .format("A h:mm")
                                                  .replace("AM", "오전")
                                                  .replace("PM", "오후")
                                            : "-"
                                    }`}
                                />
                                <ContentBox
                                    title={"시공범위"}
                                    content={`${
                                        dobaeRange == 1 ? "전체" : "일부"
                                    }${
                                        dobaeRangeMemo
                                            ? `/${dobaeRangeMemo}`
                                            : ""
                                    }/${
                                        dobaeCeiling == 1
                                            ? "천장도배포함"
                                            : "천장도배없음"
                                    }  `}
                                />
                                <ContentBox
                                    title={"자재"}
                                    content={`${
                                        dobaeCreateCompanyName || "-"
                                    }/${
                                        dobaeProduct == 1
                                            ? "실크"
                                            : dobaeProduct == 2
                                            ? "합지"
                                            : "혼합"
                                    }/${dobaeCnt || 0}개`}
                                />
                                <ContentBox
                                    title={"견적금액"}
                                    content={`${(
                                        dobaePrice || 0
                                    ).toLocaleString()}원`}
                                />{" "}
                            </div>
                        </Element>
                    </>
                )}
                {zangpanOn === 1 && (
                    <>
                        <div className="cutLine" />
                        <Element name="zangpanOn">
                            <div className="ClkContentWrap ">
                                <Link
                                    to="estimateBodyMain"
                                    smooth={true}
                                    duration={500}
                                >
                                    <div className={`ClkContent `}>
                                        <p>기본정보</p>
                                    </div>
                                </Link>
                                {dobaeOn === 1 && (
                                    <Link
                                        to="dobaeOn"
                                        smooth={true}
                                        duration={500}
                                    >
                                        <div className={`ClkContent `}>
                                            <p>도배</p>
                                        </div>
                                    </Link>
                                )}
                                {zangpanOn === 1 && (
                                    <Link
                                        to="zangpanOn"
                                        smooth={true}
                                        duration={500}
                                    >
                                        <div className={`ClkContent active`}>
                                            <p>장판</p>
                                        </div>
                                    </Link>
                                )}
                                {maruOn === 1 && (
                                    <Link
                                        to="maruOn"
                                        smooth={true}
                                        duration={500}
                                    >
                                        <div className={`ClkContent `}>
                                            <p>마루</p>
                                        </div>
                                    </Link>
                                )}
                                {maruOn === 1 && demolitionPrice !== 0 && (
                                    <Link
                                        to="demolition"
                                        smooth={true}
                                        duration={500}
                                    >
                                        <div className={`ClkContent `}>
                                            <p>철거</p>
                                        </div>
                                    </Link>
                                )}
                            </div>
                            <div className="EstimateBodyMain">
                                <ContentBox
                                    title={"시공일"}
                                    content={` ${moment(zangpanDate).format(
                                        "YYYY-MM-DD"
                                    )} (${
                                        consts.dayText[
                                            moment(zangpanDate).day()
                                        ]
                                    }요일) ${
                                        zangpanTime
                                            ? moment(zangpanTime, "HH:mm:ss")
                                                  .format("A h:mm")
                                                  .replace("AM", "오전")
                                                  .replace("PM", "오후")
                                            : "-"
                                    }`}
                                />
                                <ContentBox
                                    title={"시공범위"}
                                    content={`${
                                        zangpanRange == 1 ? "전체" : "일부"
                                    }${
                                        zangpanRangeMemo
                                            ? `/${zangpanRangeMemo}`
                                            : ""
                                    }
                             `}
                                />
                                <ContentBox
                                    title={"자재"}
                                    content={`${
                                        zangpanCreateCompanyName || "-"
                                    }/${zangpanMM}`}
                                />
                                <ContentBox
                                    title={"하단몰딩"}
                                    content={` ${
                                        zangpanMolding == 0
                                            ? "없음"
                                            : zangpanMolding == 1
                                            ? zangpanMoldingMemo
                                            : zangpanMolding == 2
                                            ? "굽도리"
                                            : "걸레받이"
                                    }${
                                        zangpanMoldingRange == 2
                                            ? "/전체"
                                            : zangpanMoldingRangeMemo
                                            ? `/${zangpanMoldingRangeMemo}`
                                            : ""
                                    }`}
                                />
                                <ContentBox
                                    title={"문턱시공"}
                                    content={
                                        zangpanThreshold == 1
                                            ? "시공"
                                            : "시공 없음"
                                    }
                                />{" "}
                                <ContentBox
                                    title={"견적금액"}
                                    content={`${(
                                        zangpanPrice || 0
                                    ).toLocaleString()}원`}
                                />{" "}
                            </div>
                        </Element>
                    </>
                )}
                {maruOn === 1 && (
                    <>
                        <div className="cutLine" />
                        <Element name="maruOn">
                            <div className="ClkContentWrap ">
                                <Link
                                    to="estimateBodyMain"
                                    smooth={true}
                                    duration={500}
                                >
                                    <div className={`ClkContent `}>
                                        <p>기본정보</p>
                                    </div>
                                </Link>
                                {dobaeOn === 1 && (
                                    <Link
                                        to="dobaeOn"
                                        smooth={true}
                                        duration={500}
                                    >
                                        <div className={`ClkContent `}>
                                            <p>도배</p>
                                        </div>
                                    </Link>
                                )}
                                {zangpanOn === 1 && (
                                    <Link
                                        to="zangpanOn"
                                        smooth={true}
                                        duration={500}
                                    >
                                        <div className={`ClkContent `}>
                                            <p>장판</p>
                                        </div>
                                    </Link>
                                )}
                                {maruOn === 1 && (
                                    <Link
                                        to="maruOn"
                                        smooth={true}
                                        duration={500}
                                    >
                                        <div className={`ClkContent active`}>
                                            <p>마루</p>
                                        </div>
                                    </Link>
                                )}
                                {maruOn === 1 && demolitionPrice !== 0 && (
                                    <Link
                                        to="demolition"
                                        smooth={true}
                                        duration={500}
                                    >
                                        <div className={`ClkContent `}>
                                            <p>철거</p>
                                        </div>
                                    </Link>
                                )}
                            </div>
                            <div className="EstimateBodyMain">
                                <ContentBox
                                    title={"시공일"}
                                    content={
                                        maruDate
                                            ? `${moment(maruDate).format(
                                                  "YYYY-MM-DD"
                                              )} (${
                                                  consts.dayText[
                                                      moment(maruDate).day()
                                                  ]
                                              }요일) ${
                                                  maruTime
                                                      ? moment(
                                                            maruTime,
                                                            "HH:mm:ss"
                                                        )
                                                            .format("A h:mm")
                                                            .replace(
                                                                "AM",
                                                                "오전"
                                                            )
                                                            .replace(
                                                                "PM",
                                                                "오후"
                                                            )
                                                      : "-"
                                              }`
                                            : "-"
                                    }
                                />
                                <ContentBox
                                    title={"시공범위"}
                                    content={`${
                                        maruRange == 1 ? "전체" : "일부"
                                    }${
                                        maruRangeMemo ? `/${maruRangeMemo}` : ""
                                    }`}
                                />
                                <ContentBox
                                    title={"자재"}
                                    content={`${maruCreateCompanyName}/${
                                        maruFlooring == 1
                                            ? "강마루"
                                            : maruFlooring == 2
                                            ? "강화마루"
                                            : maruFlooring == 3
                                            ? "SB마루"
                                            : "-"
                                    } ${
                                        maruFlooringMemo
                                            ? `/${maruFlooringMemo}`
                                            : ""
                                    }  `}
                                />
                                <ContentBox
                                    title={"기존 바닥재"}
                                    content={maruOldProduct || "-"}
                                />
                                <ContentBox
                                    title={"하단몰딩"}
                                    content={
                                        maruDownMolding == 1
                                            ? "시공"
                                            : "시공없음"
                                    }
                                />{" "}
                                <ContentBox
                                    title={"보양작업"}
                                    content={
                                        maruRepair == 1 ? "시공" : "시공없음"
                                    }
                                />{" "}
                                <ContentBox
                                    title={"견적금액"}
                                    content={`${(
                                        maruPrice || 0
                                    ).toLocaleString()}원`}
                                />{" "}
                            </div>
                        </Element>
                    </>
                )}
                {maruOn === 1 && demolitionPrice !== 0 && (
                    <>
                        <div className="cutLine" />
                        <Element name="demolition">
                            <div className="ClkContentWrap ">
                                <Link
                                    to="estimateBodyMain"
                                    smooth={true}
                                    duration={500}
                                >
                                    <div className={`ClkContent `}>
                                        <p>기본정보</p>
                                    </div>
                                </Link>
                                {dobaeOn === 1 && (
                                    <Link
                                        to="dobaeOn"
                                        smooth={true}
                                        duration={500}
                                    >
                                        <div className={`ClkContent `}>
                                            <p>도배</p>
                                        </div>
                                    </Link>
                                )}
                                {zangpanOn === 1 && (
                                    <Link
                                        to="zangpanOn"
                                        smooth={true}
                                        duration={500}
                                    >
                                        <div className={`ClkContent `}>
                                            <p>장판</p>
                                        </div>
                                    </Link>
                                )}
                                {maruOn === 1 && (
                                    <Link
                                        to="maruOn"
                                        smooth={true}
                                        duration={500}
                                    >
                                        <div className={`ClkContent `}>
                                            <p>마루</p>
                                        </div>
                                    </Link>
                                )}
                                {maruOn === 1 && demolitionPrice !== 0 && (
                                    <Link
                                        to="demolition"
                                        smooth={true}
                                        duration={500}
                                    >
                                        <div className={`ClkContent active`}>
                                            <p>철거</p>
                                        </div>
                                    </Link>
                                )}
                            </div>
                            <div className="EstimateBodyMain">
                                <ContentBox
                                    title={"시공일"}
                                    content={
                                        demolitionDate
                                            ? `${moment(demolitionDate).format(
                                                  "YYYY-MM-DD"
                                              )} (${
                                                  consts.dayText[
                                                      moment(
                                                          demolitionDate
                                                      ).day()
                                                  ]
                                              }요일) ${
                                                  demolitionTime
                                                      ? moment(
                                                            demolitionTime,
                                                            "HH:mm:ss"
                                                        )
                                                            .format("A h:mm")
                                                            .replace(
                                                                "AM",
                                                                "오전"
                                                            )
                                                            .replace(
                                                                "PM",
                                                                "오후"
                                                            )
                                                      : "-"
                                              }`
                                            : "-"
                                    }
                                />
                                <ContentBox
                                    title={"시공범위"}
                                    content={demolitionRange || "-"}
                                />
                                <ContentBox
                                    title={"견적금액"}
                                    content={`${(
                                        demolitionPrice || 0
                                    ).toLocaleString()}원`}
                                />{" "}
                            </div>
                        </Element>
                    </>
                )}

                <>
                    <div className="cutLine" />
                    <div className="EstimateBodyMain">
                        <div style={{ padding: " 7px 0px 17px 0 " }}>
                            <input
                                type="checkbox"
                                className={`checkbox2 ${
                                    movementFlag?.goEstimate ? "finish" : ""
                                }`}
                                id="agreeChk"
                                checked={agreeChk}
                                value={movementFlag?.goEstimate ? 1 : agreeChk}
                                onChange={(e) =>
                                    setAgreeChk(
                                        movementFlag?.goEstimate
                                            ? true
                                            : e.target.checked
                                    )
                                }
                            />
                            <label
                                htmlFor="agreeChk"
                                style={{
                                    color: movementFlag?.goEstimate
                                        ? "#666666"
                                        : "#393939",
                                }}
                            >
                                시공정보가 실제현장과 다름이 없음을
                                확인했습니다.
                            </label>
                        </div>
                        <Button
                            title={"다음"}
                            active={agreeChk}
                            onClick={GoNext}
                            style={{ marginBottom: "50px" }}
                        />
                    </div>
                </>

                <BottomBtn
                    active={looking ? 1 : 3}
                    movementFlag={movementFlag}
                    index={index}
                />
            </div>
        </>
    );
}

export default UserNewConstrManagement;
