import { useEffect, useRef } from "react";
import Router from "./Router";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, cssTransition } from "react-toastify";
import { useWidth, useUser } from "./zustands/useWidth";
import { PopupComp } from "./components/PopUp";
// import HelmetWrap from "./components/Helmet";
// import { HelmetProvider } from "react-helmet-async";

function App() {
    const slide = cssTransition({
        enter: "slideInUp",
        exit: "slideOutDown",
        duration: [300, 500], // enter: 0.3초, exit: 0.5초
    });
    return (
        <>
            <PopupComp />
            <Router />
            <ToastContainer
                position="bottom-center"
                autoClose={1500}
                hideProgressBar={true}
                newestOnTop
                draggable={false} // 드래그가 안되도록 설정
                // closeOnClick
                // draggablePercent={1}
                theme="colored"
                closeButton={false}
                toastClassName="custom-toast"
                pauseOnHover={false}
                transition={slide}
            />
        </>
    );
}

export default App;
