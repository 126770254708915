import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../components/Button";
import { ProgressBar } from "../components/progressBar";
import { imageData } from "../libs/images";
import { usePostData } from "../utils/service";
import ApiData from "../libs/api";
import { useEffect, useState } from "react";
import { set } from "lodash";
import routes from "../libs/routes";
import { toast } from "react-toastify";
import { getDecryptedParam } from "../components/navigateWithEncryption";

function UserLogin() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const index = encodeURIComponent(searchParams.get("sigongIdx"));
    const decryptIdx = getDecryptedParam("sigongIdx");
    const movementFlagVal = usePostData(ApiData.movementFlagVal);
    const movementFlagIn = usePostData(ApiData.movementFlagIn);
    const loginUser = usePostData(ApiData.loginUser);
    const [phoneNum, setPhoneNum] = useState("");
    const [loginNum, setLoginNum] = useState("");
    const [error, setError] = useState(false);

    const [movementFlag, setMovementFlag] = useState([]);

    const movementFlagValApi = () => {
        movementFlagVal
            .mutateAsync({ sigongIdx: decryptIdx || index })
            .then(({ data }) => {
                setMovementFlag(data);
            })
            .catch((error) => {});
    };

    const Login = () => {
        console.log(index);
        if (phoneNum.slice(-4) === loginNum) {
            if (!movementFlag) {
                movementFlagIn
                    .mutateAsync({
                        location: 0,
                        sigongIdx: decryptIdx || index,
                    })
                    .then((data) => {
                        navigate(
                            `${routes.UserNewConstrManagement}?sigongIdx=${index}`
                        );
                        return;
                    })
                    .catch((error) => {});
                return;
            }
            if (movementFlag.goEstimate === 0) {
                navigate(
                    `${routes.UserNewConstrManagement}?sigongIdx=${index}`
                );
                return;
            } else if (movementFlag.goContract === 0) {
                navigate(`${routes.UserNewContract}?sigongIdx=${index}`);
                return;
            } else if (movementFlag.GoAddressIn === 0) {
                navigate(`${routes.UserNewAddressIn}?sigongIdx=${index}`);
                return;
            } else if (movementFlag.goPaymentInfo === 0) {
                navigate(`${routes.UserNewPaymentInfo}?sigongIdx=${index}`);
                return;
            } else if (movementFlag.goMaterialSelect === 0) {
                navigate(`${routes.UserNewMaterialSelect}?sigongIdx=${index}`);
                return;
            } else {
                navigate(`${routes.UserNewFinalConfirm}?sigongIdx=${index}`);
                return;
            }
        } else {
            toast.error(
                <div
                    style={{
                        fontSize: "12px",
                        lineHeight: "19px",
                    }}
                >
                    번호를 확인해주세요.
                    <br />
                    전화번호 뒤 4자리를 입력해주세요.
                </div>,
                {
                    className: "custom-toast-error-login",
                    position: "bottom-center",
                }
            );
        }
    };

    const loginNumHandler = (e) => {
        setLoginNum(e.target.value);
    };

    const sigonginfoDetailData = () => {
        loginUser
            .mutateAsync({ sigongIdx: decryptIdx || index })
            .then(({ data }) => {
                setPhoneNum(data);
            })
            .catch((err) => {
                toast.error(err.response.data, {
                    className: "custom-toast-error-login",
                });
            });
    };
    useEffect(() => {
        sigonginfoDetailData();
        movementFlagValApi();
    }, [index]);

    return (
        <>
            <div className="loginHeader">
                <h2>고객페이지</h2>
                <h2>로그인</h2>
                <img className="DobaeImg" src={imageData.DobaeImg} />
            </div>
            <div className="loginMainWrap">
                <div className="loginMain">
                    <p>핸드폰 번호 뒤 4자리를 입력해주세요</p>
                    <div className="loginInput">
                        <input
                            className="inputBox"
                            type="text"
                            value={loginNum}
                            placeholder="1234"
                            onChange={(e) => {
                                const value = e.target.value;
                                // 숫자만 입력되도록 필터링
                                if (/^\d*$/.test(value) && value.length <= 4) {
                                    loginNumHandler(e);
                                }
                            }}
                            maxLength={4} // 최대 4글자까지만 입력되도록 설정
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    Login();
                                }
                            }}
                        />
                        <Button title={"로그인"} onClick={Login} />
                    </div>
                </div>
            </div>
            {error && (
                <div className="LoginError">
                    <img className="warningImg" src={imageData.warning} />
                    <div>
                        <p>번호를 확인해주세요.</p>
                        <p>전화번호 뒤 4자리를 입력해주세요.</p>
                    </div>
                </div>
            )}
        </>
    );
}

export default UserLogin;
