export const theme = {
    colors: {
        colWhite: "#Ffff",
        colMain: "#51BAA4", // 오류 색상
        colBlue: "#0656CD", // 정보 색상
        colDark: "#333", // 텍스트 색상
        colBlack: "#000", // 배경 색상
        colLine: "#ccc", // 선 색상
    },
};
