import { useEffect, useState } from "react";
import DaumPostcode from "react-daum-postcode";
import { HeaderComp3 } from "../components/Header";
import { ProgressBar } from "../components/progressBar";
import { imageData } from "../libs/images";
import { Button } from "../components/Button";
import { BottomBtn } from "../components/BottomBtn";
import { useNavigate, useSearchParams } from "react-router-dom";
import { usePostData } from "../utils/service";
import ApiData from "../libs/api";
import { toast } from "react-toastify";
import routes from "../libs/routes";
import { getDecryptedParam } from "../components/navigateWithEncryption";
import useCustomer from "../zustands/useCustomer";
function UserNewAddressIn() {
    const navigate = useNavigate();
    const infoDetail = usePostData(ApiData.sigonginfoDetail);
    const movementFlagIn = usePostData(ApiData.movementFlagIn);
    const movementFlagVal = usePostData(ApiData.movementFlagVal);
    const infoIn = usePostData(ApiData.infoIn);
    const [searchParams, setSearchParams] = useSearchParams();
    const index = encodeURIComponent(searchParams.get("sigongIdx"));
    const decryptIdx = getDecryptedParam("sigongIdx");
    const Looking = searchParams.get("Looking");
    const [cusmtomPaymentChk, setCusmtomPaymentChk] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [zonecode, setZonecode] = useState("");
    const [addressValue, setAddressValue] = useState("");
    const [addressDetailValue, setAddressDetailValue] = useState("");
    const [sigongInfo, setSigongInfo] = useState("");
    const [accessNumber, setAccessNumber] = useState("");
    const [accessInfo, setAccessInfo] = useState("");
    const [firstClk, setFirstClk] = useState(false);
    const [customerName, setCustomerName] = useState("");

    const [movementFlag, setMovementFlag] = useState([]);
    const {
        UseCustomerPaymentName,
        setUseCustomerPaymentName,
        setUseCustomerContractChk,
    } = useCustomer();
    const completeHandler = (data) => {
        const { address, zonecode } = data;
        setZonecode(zonecode);
        setAddressValue(address);
    };
    const closeHandler = (state) => {
        if (state === "FORCE_CLOSE") {
            setIsOpen(false);
        } else if (state === "COMPLETE_CLOSE") {
            setIsOpen(false);
        }
    };

    const infoInApi = () => {
        let sender = {
            sigongIdx: decryptIdx || index,
            address: addressValue,
            address_detail: addressDetailValue,
            door_pass: sigongInfo,
            entrance_pass: accessNumber,
            customer_memo: accessInfo,
        };

        infoIn
            .mutateAsync(sender)
            .then((data) => {
                movementFlagIn
                    .mutateAsync({
                        location: 3,
                        sigongIdx: decryptIdx || index,
                    })
                    .then((data) => {
                        if (UseCustomerPaymentName) {
                            navigate(
                                `${routes.UserNewPaymentInfo2}?sigongIdx=${index}`
                            );
                        } else {
                            navigate(
                                `${routes.UserNewPaymentInfo}?sigongIdx=${index}`
                            );
                        }
                    })
                    .catch((error) => {
                        toast.error(
                            <div
                                style={{
                                    fontSize: "12px",
                                    lineHeight: "19px",
                                }}
                            >
                                오류 발생하였습니다.
                            </div>,
                            {
                                className: "custom-toast-error-login addressIn",
                                position: "bottom-center",
                            }
                        );
                    });
            })
            .catch((error) => {
                toast.error(
                    <div
                        style={{
                            fontSize: "12px",
                            lineHeight: "19px",
                        }}
                    >
                        주소/출입방법 입력에 실패했습니다.
                    </div>,
                    {
                        className: "custom-toast-error-login addressIn",
                        position: "bottom-center",
                    }
                );

                // toast.error("주소/출입방법 입력에 실패했습니다.");
            });
    };
    const inputChangeHandler = (event) => {
        setAddressDetailValue(event.target.value);
    };
    const toggleHandler = () => {
        setIsOpen((prevOpenState) => !prevOpenState);
    };
    const sigongInfoHandler = (event) => {
        setSigongInfo(event.target.value);
    };
    const accessNumberHandler = (event) => {
        setAccessNumber(event.target.value);
    };
    const accessInfoHandler = (event) => {
        setAccessInfo(event.target.value);
    };
    const movementFlagValApi = () => {
        movementFlagVal
            .mutateAsync({ sigongIdx: decryptIdx || index })
            .then(({ data }) => {
                setMovementFlag(data);
            })
            .catch((error) => {
                toast.error(
                    <div
                        style={{
                            fontSize: "12px",
                            lineHeight: "19px",
                        }}
                    >
                        오류가 발생하였습니다.
                        <br /> 다시 시도해주세요.
                    </div>,
                    {
                        className: "custom-toast-error-login addressIn",
                        position: "bottom-center",
                    }
                );
            });
    };
    const infoDetailApi = () => {
        infoDetail
            .mutateAsync({ sigongIdx: decryptIdx || index })
            .then((data) => {
                setUseCustomerPaymentName(data.data.customer_payment_name);
                setUseCustomerContractChk(data.data.customer_payment_chk);
                setCustomerName(data.data.cutomer_name);
                setAddressValue(data.data.address);
                setAddressDetailValue(data.data.address_detail);
                setSigongInfo(data.data.door_pass);
                setAccessNumber(data.data.entrance_pass);
                setAccessInfo(data.data.customer_memo);
                setCusmtomPaymentChk(data.data.customer_payment_chk);
            })
            .catch((error) => {
                toast.error(
                    <div
                        style={{
                            fontSize: "12px",
                            lineHeight: "19px",
                        }}
                    >
                        오류가 발생하였습니다.
                        <br /> 다시 시도해주세요.
                    </div>,
                    {
                        className: "custom-toast-error-login addressIn",
                        position: "bottom-center",
                    }
                );
            });
    };
    const submitClk = () => {
        if (!addressValue) {
            toast.error(
                <div
                    style={{
                        fontSize: "12px",
                        lineHeight: "19px",
                    }}
                >
                    주소를 입력해주세요.
                </div>,
                {
                    className: "custom-toast-error-login addressIn ",
                    position: "bottom-center",
                }
            );
            return;
        } else if (!addressDetailValue) {
            toast.error(
                <div
                    style={{
                        fontSize: "12px",
                        lineHeight: "19px",
                    }}
                >
                    세부주소를 입력해주세요.
                </div>,
                {
                    className: "custom-toast-error-login addressIn",
                    position: "bottom-center",
                }
            );

            return;
        }
        if (firstClk === false) {
            if (!sigongInfo || !accessNumber || !accessInfo) {
                toast.error(
                    <div
                        style={{
                            fontSize: "12px",
                            lineHeight: "19px",
                        }}
                    >
                        출입방법이 입력되지 않았습니다.
                        <br />
                        다음 페이지로 넘어가시겠습니까?
                    </div>,
                    {
                        className: "custom-toast-error-login addressIn",
                        position: "bottom-center",
                    }
                );
                setFirstClk(true);
                return;
            }

            infoInApi();
            return;
        }
        infoInApi();
    };
    useEffect(() => {
        movementFlagValApi();
        infoDetailApi();
    }, [index]);
    useEffect(() => {
        document.body.style.overflow = isOpen ? "hidden" : "auto";
    }, [isOpen]);

    return (
        <>
            <HeaderComp3 title={"주소입력"} />
            <ProgressBar
                style={{ marginTop: "10px" }}
                progress={3}
                movementFlag={movementFlag}
                index={index}
                paymentChk={cusmtomPaymentChk}
            />
            <div className="ContentBody" style={{ paddingBottom: "70px" }}>
                <div className="ContentBodyHeader">
                    <h1>주소/출입방법 입력</h1>
                    <p>공사하는 집의 주소와 출입방법을 입력해주세요.</p>
                </div>
                <div className="ContentBodyUl">
                    <ul>
                        <li>공사하는 집의 주소를 입력해주세요</li>
                        <li>시공을 위한 자재가 시공 1~2일 전 배송됩니다.</li>
                        <li>
                            주차공간을 확보해주셔야 합니다 (빌라/주택) <br />
                            시공전 주차등록/결제해주셔야 합니다.(오피스텔)
                        </li>
                    </ul>
                </div>
                <div className="InnerContentWrapper">
                    <div className="InnerContentWrap">
                        <h1 style={{ fontSize: "12px" }}>
                            {" "}
                            공사하는 집 주소입력{" "}
                        </h1>
                        <div className="InputWrapper">
                            <div
                                className="InputWrap "
                                style={{ position: "relative" }}
                            >
                                {isOpen && (
                                    <div
                                        className="popupAddress"
                                        style={{
                                            height: "400px",
                                            position: "fixed",
                                            zIndex: "100",
                                            top: "66px",
                                        }}
                                    >
                                        <div
                                            className="header"
                                            style={{ background: "#f1f1f1" }}
                                        >
                                            <p>주소</p>
                                            <div className="closeBtn">
                                                <img
                                                    src={imageData.exit}
                                                    alt="exit"
                                                    onClick={() => {
                                                        setIsOpen(!isOpen);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <DaumPostcode
                                            onComplete={completeHandler}
                                            onClose={closeHandler}
                                        />
                                    </div>
                                )}
                                <div
                                    style={{
                                        background: "#fff",
                                        padding: "12px 10px 10px 10px",
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                        fontSize: "12px",
                                        width: "65%",
                                        marginRight: "10px",
                                        borderRadius: "7px",
                                        border: "1px solid #ddd",
                                        minHeight: "40px",
                                        color: addressValue
                                            ? "#393939"
                                            : "#999",
                                    }}
                                >
                                    {addressValue ||
                                        "주소검색 버튼을 눌러주세요."}
                                </div>
                                <button
                                    style={{
                                        width: "35%",
                                        padding: "12px 10px 10px 10px",
                                        minHeight: "40px",
                                        borderRadius: "7px",
                                        backgroundColor: "#393939",
                                        fontSize: "12px",
                                        fontWeight: "700",

                                        color: "#fff",
                                    }}
                                    onClick={toggleHandler}
                                >
                                    주소검색
                                </button>
                            </div>
                        </div>
                        <div className="InputWrap ">
                            <input
                                style={{
                                    minHeight: "40px",
                                    borderRadius: "7px",
                                    border: "1px solid #ddd",
                                    padding: "12px 10px 10px 10px",
                                    width: "100%",
                                    fontSize: "12px",
                                }}
                                placeholder={"세부주소를 입력해주세요. "}
                                value={addressDetailValue || ""}
                                onChange={inputChangeHandler}
                            />
                        </div>
                    </div>
                    <div className="InnerContentWrap">
                        <h1 style={{ fontSize: "12px" }}> 공동현관 출입번호</h1>
                        <div className="InputWrap ">
                            <input
                                style={{
                                    minHeight: "40px",
                                    borderRadius: "7px",
                                    border: "1px solid #ddd",
                                    padding: "12px 10px 10px 10px",
                                    width: "100%",
                                    fontSize: "12px",
                                }}
                                placeholder={
                                    "예시 : [동호수 + 0000 + 키버튼],[없음] 등 "
                                }
                                value={sigongInfo || ""}
                                onChange={sigongInfoHandler}
                            />
                        </div>
                    </div>
                    <div className="InnerContentWrap">
                        <h1 style={{ fontSize: "12px" }}> 세대현관 출입번호</h1>
                        <div className="InputWrap ">
                            <input
                                style={{
                                    minHeight: "40px",
                                    borderRadius: "7px",
                                    border: "1px solid #ddd",
                                    padding: "12px 10px 10px 10px",
                                    width: "100%",
                                    fontSize: "12px",
                                }}
                                placeholder={"예시 : # + 0000 "}
                                value={accessNumber || ""}
                                onChange={accessNumberHandler}
                            />
                        </div>
                    </div>
                    <div className="InnerContentWrap">
                        <h1 style={{ fontSize: "12px" }}>
                            출입/배송 관련 전달사항
                        </h1>
                        <div className="InputWrap ">
                            <textarea
                                style={{
                                    minHeight: "120px",
                                    borderRadius: "7px",
                                    border: "1px solid #ddd",
                                    padding: "12px 10px 10px 10px",
                                    width: "100%",
                                    fontSize: "12px",
                                    resize: "none",
                                }}
                                placeholder={
                                    "주차공간 위치 / 건물출입 방법 / 추가 전화번호 등"
                                }
                                value={accessInfo || ""}
                                onChange={accessInfoHandler}
                            />
                        </div>
                    </div>
                </div>
                <Button title={"다음"} onClick={submitClk} />
            </div>
            <BottomBtn active={3} movementFlag={movementFlag} index={index} />
        </>
    );
}

export default UserNewAddressIn;
