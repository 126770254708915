import { useEffect, useState } from "react";
import { HeaderComp3 } from "../components/Header";
import { ProgressBar } from "../components/progressBar";
import { imageData } from "../libs/images";
import {
    ContentBox,
    MaterialFinalLook,
    MaterialFinalLook2,
} from "../components/BoxInfo";
import { BottomBtn } from "../components/BottomBtn";
import { Button } from "../components/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { usePostData } from "../utils/service";
import ApiData from "../libs/api";
import moment from "moment";
import consts from "../libs/consts";
import routes from "../libs/routes";
import { toast } from "react-toastify";
import { getDecryptedParam } from "../components/navigateWithEncryption";
import useCustomer from "../zustands/useCustomer";
import { Link, Element, animateScroll as scroll } from "react-scroll";

function UserNewFinished() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const index = encodeURIComponent(searchParams.get("sigongIdx"));
    const decryptIdx = getDecryptedParam("sigongIdx");
    const looking = Boolean(searchParams.get("Looking"));
    // const [clkbox, setClkbox] = useState(1);
    const sigonginfoDetail = usePostData(ApiData.sigonginfoDetail);
    const movementFlagVal = usePostData(ApiData.movementFlagVal);
    const MaterialFinishNewDetail = usePostData(
        ApiData.MaterialFinishNewDetail
    );
    const movementFlagIn = usePostData(ApiData.movementFlagIn);
    const [customerName, setCustomerName] = useState("");
    const [customerPhone, setCustomerPhone] = useState("");
    const [address, setAddress] = useState("");
    const [addressDetail, setAddressDetail] = useState("");
    const [area, setArea] = useState(0);
    const [areaPyeong, setAreaPyeong] = useState(0);
    const [veranda, setVeranda] = useState(0);
    const [verandaMemo, setVerandaMemo] = useState("");
    const [todayStatus, setTodayStatus] = useState(0);
    const [todayMemo, setTodayMemo] = useState("");
    const [beforeMemo, setBeforeMemo] = useState("");
    const [planImg, setPlanImg] = useState("");

    const [dobaeOn, setDobaeOn] = useState(0);
    const [dobaePrice, setDobaePrice] = useState(0);
    const [dobaeDate, setDobaeDate] = useState(null);
    const [dobaeTime, setDobaeTime] = useState("");
    const [dobaeRange, setDobaeRange] = useState(0);
    const [dobaeRangeMemo, setDobaeRangeMemo] = useState("");
    const [dobaeProduct, setDobaeProduct] = useState(0);
    const [dobaeCnt, setDobaeCnt] = useState(0);
    const [dobaeCeiling, setDobaeCeiling] = useState(0);
    const [dobaeCreateCompanyName, setDobaeCreateCompanyName] = useState("");

    const [zangpanOn, setZangpanOn] = useState(0);
    const [zangpanPrice, setZangpanPrice] = useState(0);
    const [zangpanDate, setZangpanDate] = useState(null);
    const [zangpanTime, setZangpanTime] = useState("");
    const [zangpanRange, setZangpanRange] = useState(0);
    const [zangpanRangeMemo, setZangpanRangeMemo] = useState("");
    const [zangpanMM, setZangpanMM] = useState("");
    const [zangpanMolding, setZangpanMolding] = useState(0);
    const [zangpanMoldingMemo, setZangpanMoldingMemo] = useState("");
    const [zangpanMoldingRange, setZangpanMoldingRange] = useState(0);
    const [zangpanMoldingRangeMemo, setZangpanMoldingRangeMemo] = useState("");
    const [zangpanCreateCompanyName, setZangpanCreateCompanyName] =
        useState("");
    const [zangpanThreshold, setZangpanThreshold] = useState(0);

    const [maruOn, setMaruOn] = useState(0);
    const [maruPrice, setMaruPrice] = useState(0);
    const [maruDate, setMaruDate] = useState(null);
    const [maruTime, setMaruTime] = useState("");
    const [maruRange, setMaruRange] = useState(0);
    const [maruRangeMemo, setMaruRangeMemo] = useState("");
    const [maruCreateCompanyName, setMaruCreateCompanyName] = useState("");
    const [maruFlooring, setMaruFlooring] = useState(0);
    const [maruFlooringMemo, setMaruFlooringMemo] = useState("");
    const [maruOldProduct, setMaruOldProduct] = useState("");
    const [maruDownMolding, setMaruDownMolding] = useState(0);
    const [maruThreshold, setMaruThreshold] = useState(0);
    const [maruRepair, setMaruRepair] = useState(0);

    const [demolitionPrice, setDemolitionPrice] = useState(0);
    const [demolitionDate, setDemolitionDate] = useState(null);
    const [demolitionTime, setDemolitionTime] = useState("");
    const [demolitionRange, setDemolitionRange] = useState("");
    const [customerMemo, setCustomerMemo] = useState("");
    const [balancePayed, setBalancePayed] = useState(false);

    const [movementFlag, setMovementFlag] = useState([]);
    const [materialDobaeList, setMaterialDobaeList] = useState([]);
    const [materialSubList, setMaterialSubList] = useState([]);
    const [zangpanList, setZangpanList] = useState([]);
    const [baseBoardList, setBaseBoardList] = useState([]);
    const [workEnd, setWorkEnd] = useState(true);

    const { setUseCustomerPaymentName, setUseCustomerContractChk } =
        useCustomer();

    const BtnClk = () => {
        navigate(`${routes.UserNewAsInquire}?sigongIdx=${index}`);
    };
    const MaterialNewDetailApi = () => {
        MaterialFinishNewDetail.mutateAsync({
            sigongIdx: decryptIdx || index,
        }).then(({ data }) => {
            setMaterialDobaeList(data.dobaeMate);
            setMaterialSubList(data.dobaeSub);
            setZangpanList(data.zangpanList);
            setBaseBoardList(data.baseBoardList);
        });
    };
    const movementFlagValApi = () => {
        movementFlagVal
            .mutateAsync({ sigongIdx: decryptIdx || index })
            .then(({ data }) => {
                setMovementFlag(data);
            })
            .catch((error) => {});
    };
    const sigonginfoDetailApi = () => {
        sigonginfoDetail
            .mutateAsync({ sigongIdx: decryptIdx || index })
            .then((data) => {
                // console.log("data", data.data);
                setUseCustomerPaymentName(data.data.customer_payment_name);
                setUseCustomerContractChk(data.data.customer_payment_chk);

                setCustomerName(data.data.cutomer_name);
                setCustomerPhone(data.data.mb_phone);
                setAddress(data.data.address);
                setAddressDetail(data.data.address_detail);
                setArea(data.data.area);
                setAreaPyeong(data.data.area_pyeong);
                setVeranda(data.data.veranda);
                setVerandaMemo(data.data.veranda_memo);
                setTodayStatus(data.data.today_status);
                setTodayMemo(data.data.today_memo);
                setBeforeMemo(data.data.before_memo);
                setDobaeOn(data.data.dobae);
                setDobaePrice(data.data.dobae_price);
                setDobaeDate(data.data.dobae_date);
                setDobaeTime(data.data.dobae_time);
                setDobaeRange(data.data.dobae_range);
                setDobaeRangeMemo(data.data.dobae_range_memo);
                setDobaeProduct(data.data.dobae_product);
                setDobaeCnt(data.data.dobae_cnt);
                setDobaeCeiling(data.data.dobae_ceiling);
                setDobaeCreateCompanyName(data.data.dobae_company_name);
                setPlanImg(data.data.plan_file);

                setZangpanOn(data.data.zangpan);
                setZangpanPrice(data.data.zangpan_price);
                setZangpanDate(data.data.zangpan_date);
                setZangpanTime(data.data.zangpan_time);
                setZangpanRange(data.data.zangpan_range);
                setZangpanRangeMemo(data.data.zangpan_range_memo);
                setZangpanMM(data.data.zangpan_mm);
                setZangpanMolding(data.data.zangpan_molding);
                setZangpanMoldingMemo(data.data.zangpan_molding_memo);
                setZangpanMoldingRange(data.data.zangpan_molding_range);
                setZangpanMoldingRangeMemo(
                    data.data.zangpan_molding_range_memo
                );
                setZangpanCreateCompanyName(data.data.zangpan_company_name);
                setZangpanThreshold(data.data.zangpan_threshold);

                setMaruOn(data.data.maru);
                setMaruPrice(data.data.maru_price);
                setMaruDate(data.data.maru_date);
                setMaruTime(data.data.maru_time);
                setMaruRange(data.data.maru_range);
                setMaruRangeMemo(data.data.maru_range_memo);
                setMaruCreateCompanyName(data.data.maru_company_name);
                setMaruFlooring(data.data.maru_flooring);
                setMaruFlooringMemo(data.data.maru_flooring_memo);
                setMaruOldProduct(data.data.maru_ori_flooring);
                setMaruDownMolding(data.data.maru_molding);
                setMaruThreshold(data.data.maru_threshold);
                setMaruRepair(data.data.maru_cure);

                setDemolitionPrice(data.data.demolition_price);
                setDemolitionDate(data.data.demolition_date);
                setDemolitionTime(data.data.demolition_time);
                setDemolitionRange(data.data.demolition_range);
                setCustomerMemo(data.data.customer_direct_memo);

                setBalancePayed(data.data.balance_paydate ? true : false);
                setWorkEnd(data.data.work_end_date ? true : false);
            })
            .catch((error) => {});
    };

    const copyToClipboard = () => {
        const textToCopy = "기업은행 094-154587-04-022 김진환(여덟시도배)";
        navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
                toast.success(
                    <div
                        style={{
                            fontSize: "12px",
                            lineHeight: "19px",
                        }}
                    >
                        복사되었습니다.
                    </div>,
                    {
                        className: "custom-toast-error",
                        position: "bottom-center",
                    }
                );
            })
            .catch((err) => {
                console.error("복사 실패:", err);
            });
    };
    useEffect(() => {
        sigonginfoDetailApi();
        movementFlagValApi();
        MaterialNewDetailApi();
    }, [index]);
    useEffect(() => {
        if (workEnd === false) {
            movementFlagIn
                .mutateAsync({
                    location: 6,
                    sigongIdx: decryptIdx || index,
                    rollback: 0,
                })
                .then(() => {
                    navigate(
                        `${routes.UserNewFinalConfirm}?sigongIdx=${index}`
                    );
                });
        }
    }, [workEnd]);
    return (
        <>
            <HeaderComp3 title={"시공완료"} isGreen={true} noLine={true} />
            <div className="finishedHeader">
                <ProgressBar
                    style={{ marginTop: "-5px" }}
                    progress={6}
                    isGreen={true}
                    movementFlag={movementFlag}
                    index={index}
                />
                {balancePayed ? (
                    <div className="balancePayYesWrap">
                        <p>
                            <span>시공</span>이 <span>종료</span>되었습니다
                            <br />
                            시공 후 주의사항을 확인해주세요
                        </p>
                    </div>
                ) : (
                    <>
                        <div className="balancePayWrap">
                            <p>
                                <span>잔금</span>을 입금해주세요.
                            </p>
                            <h2>
                                850,000<span>원</span>
                            </h2>
                        </div>
                        <div className="accountment">
                            <p>094-154587-04-022 기업은행</p>
                            <div className="imgWrap" onClick={copyToClipboard}>
                                <img src={imageData.copyGreen} />
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className="ContentBody">
                <div className="cautionWrap" style={{ marginBottom: "0px" }}>
                    <h2>시공 후 관리방법 및 주의사항</h2>
                    <ul>
                        <li>- 창문과 문을 열어두지 마세요</li>
                        <li>- 에어컨, 선풍기, 보일러, 난로를 틀지 마세요</li>
                        <li>
                            - 바람이 치거나 온도차가 발생하면 벽지가 찢어질 수
                            있습니다. 최소 3~4일간 벽지 자연건조 되는 환경을
                            만들어주세요.
                        </li>
                    </ul>
                </div>
            </div>
            <Element name="startThings">
                <div className="ClkContentWrap ">
                    <Link
                        to="startThings"
                        smooth={true}
                        duration={500}
                        offset={-70}
                    >
                        <div className={`ClkContent active`}>
                            <p>기본정보</p>
                        </div>
                    </Link>
                    {dobaeOn === 1 && (
                        <Link
                            to="dobaeOn"
                            smooth={true}
                            duration={500}
                            offset={-70}
                        >
                            <div className={`ClkContent `}>
                                <p>도배</p>
                            </div>
                        </Link>
                    )}
                    {zangpanOn === 1 && (
                        <Link
                            to="zangpanOn"
                            smooth={true}
                            duration={500}
                            offset={-70}
                        >
                            <div className={`ClkContent `}>
                                <p>장판</p>
                            </div>
                        </Link>
                    )}
                    {maruOn === 1 && (
                        <Link
                            to="maruOn"
                            smooth={true}
                            duration={500}
                            offset={-70}
                        >
                            <div className={`ClkContent `}>
                                <p>마루</p>
                            </div>
                        </Link>
                    )}
                    {maruOn === 1 && demolitionPrice !== 0 && (
                        <Link
                            to="demolition"
                            smooth={true}
                            duration={500}
                            offset={-70}
                        >
                            <div className={`ClkContent `}>
                                <p>철거</p>
                            </div>
                        </Link>
                    )}
                </div>

                <div
                    className="ContentBody displayFlexDirect"
                    style={{ gap: "10px" }}
                >
                    {dobaeOn == 1 &&
                        materialDobaeList &&
                        materialDobaeList.length > 0 && (
                            <>
                                <h2>도배</h2>
                                {materialDobaeList?.map((item, index) => {
                                    return (
                                        <MaterialFinalLook2
                                            material_company={
                                                item.material_company
                                            }
                                            material_number={
                                                item.material_number
                                            }
                                            material_name={item.material_name}
                                            material_pattern={
                                                item.material_pattern
                                            }
                                            material_color={item.material_color}
                                            material_img={item.material_img}
                                            finalConfirm={true}
                                            memo={item.memo}
                                            key={index}
                                        />
                                    );
                                })}

                                {/* {materialSubList?.map((item, index) => {
                                    return (
                                        <MaterialFinalLook2
                                            material_company={
                                                item.material_company
                                            }
                                            material_number={
                                                item.material_number
                                            }
                                            material_name={item.material_name}
                                            material_pattern={
                                                item.material_pattern
                                            }
                                            material_color={item.material_color}
                                            material_img={item.material_img}
                                            memo={item.memo}
                                            finalConfirm={true}
                                            key={index}
                                        />
                                    );
                                })} */}
                            </>
                        )}
                    {zangpanOn == 1 &&
                        zangpanList &&
                        zangpanList.length > 0 && (
                            <>
                                <h2>장판</h2>
                                {zangpanList?.map((item, index) => {
                                    return (
                                        <MaterialFinalLook2
                                            material_company={
                                                item.material_company
                                            }
                                            material_number={
                                                item.material_number
                                            }
                                            material_name={item.material_name}
                                            material_pattern={
                                                item.material_pattern
                                            }
                                            material_color={item.material_color}
                                            material_img={item.material_img}
                                            finalConfirm={true}
                                            memo={item.memo}
                                            key={index}
                                        />
                                    );
                                })}
                                {(zangpanMolding == 2 ||
                                    zangpanMolding == 3) && (
                                    <>
                                        <h2>걸레받이</h2>
                                        {baseBoardList?.map((item, index) => {
                                            return (
                                                <MaterialFinalLook2
                                                    material_company={
                                                        item.material_company
                                                    }
                                                    material_number={
                                                        item.material_number
                                                    }
                                                    material_name={
                                                        item.material_name
                                                    }
                                                    material_pattern={
                                                        item.material_pattern
                                                    }
                                                    material_color={
                                                        item.material_color
                                                    }
                                                    material_img={
                                                        item.material_img
                                                    }
                                                    finalConfirm={true}
                                                    memo={item.memo}
                                                    key={index}
                                                />
                                            );
                                        })}
                                    </>
                                )}
                            </>
                        )}
                    <ContentBox
                        title={"주소/시공지"}
                        content={`${
                            address !== null && addressDetail !== null
                                ? address + " " + addressDetail
                                : address !== null && addressDetail === null
                                ? address
                                : address === null && addressDetail !== null
                                ? addressDetail
                                : "-"
                        }`}
                    />
                    <ContentBox
                        title={"면적"}
                        content={`${area == 1 ? "공급면적" : "전용면적"} ${
                            areaPyeong ? areaPyeong : 0
                        }평`}
                    />
                    <ContentBox
                        title={"베란다"}
                        content={`${
                            veranda == 1
                                ? "베란다 확장 있음"
                                : veranda == 2
                                ? "베란다 확장 없음"
                                : "베란다 확장 미정"
                        }${verandaMemo ? `/${verandaMemo}` : ""}`}
                    />
                    <ContentBox
                        title={"거주상태"}
                        content={`${
                            todayStatus == 1
                                ? "빈집"
                                : todayStatus == 2
                                ? "거주중 가구 있음"
                                : "이사 당일"
                        }${todayMemo ? `/${todayMemo}` : ""}`}
                    />{" "}
                    {/* <ContentBox
                        title={"사전시공여부"}
                        content={beforeMemo || "-"}
                    /> */}
                    <ContentBox
                        title={"고객메모/ \n 요청사항"}
                        content={customerMemo ? customerMemo : null}
                    />
                </div>
            </Element>
            {dobaeOn === 1 && (
                <>
                    <div className="cutLine" />
                    <Element name="dobaeOn">
                        <div className="ClkContentWrap ">
                            <Link
                                to="startThings"
                                smooth={true}
                                duration={500}
                                offset={-70}
                            >
                                <div className={`ClkContent `}>
                                    <p>기본정보</p>
                                </div>
                            </Link>
                            {dobaeOn === 1 && (
                                <Link
                                    to="dobaeOn"
                                    smooth={true}
                                    duration={500}
                                    offset={-70}
                                >
                                    <div className={`ClkContent active`}>
                                        <p>도배</p>
                                    </div>
                                </Link>
                            )}
                            {zangpanOn === 1 && (
                                <Link
                                    to="zangpanOn"
                                    smooth={true}
                                    duration={500}
                                    offset={-70}
                                >
                                    <div className={`ClkContent `}>
                                        <p>장판</p>
                                    </div>
                                </Link>
                            )}
                            {maruOn === 1 && (
                                <Link
                                    to="maruOn"
                                    smooth={true}
                                    duration={500}
                                    offset={-70}
                                >
                                    <div className={`ClkContent `}>
                                        <p>마루</p>
                                    </div>
                                </Link>
                            )}
                            {maruOn === 1 && demolitionPrice !== 0 && (
                                <Link
                                    to="demolition"
                                    smooth={true}
                                    duration={500}
                                    offset={-70}
                                >
                                    <div className={`ClkContent `}>
                                        <p>철거</p>
                                    </div>
                                </Link>
                            )}
                        </div>
                        <div
                            className="ContentBody displayFlexDirect"
                            style={{ gap: "10px" }}
                        >
                            <ContentBox
                                title={"시공일"}
                                content={`${moment(dobaeDate).format(
                                    "YYYY-MM-DD"
                                )} (${
                                    consts.dayText[moment(dobaeDate).day()]
                                }요일) ${
                                    dobaeTime
                                        ? moment(dobaeTime, "HH:mm:ss")
                                              .format("A h:mm")
                                              .replace("AM", "오전")
                                              .replace("PM", "오후")
                                        : "-"
                                }`}
                            />
                            <ContentBox
                                title={"시공범위"}
                                content={`${dobaeRange == 1 ? "전체" : "일부"}${
                                    dobaeRangeMemo ? `/${dobaeRangeMemo}` : ""
                                }/${
                                    dobaeCeiling == 1
                                        ? "천장도배포함"
                                        : "천장도배없음"
                                }  `}
                            />
                            <ContentBox
                                title={"자재"}
                                content={`${dobaeCreateCompanyName || "-"}/${
                                    dobaeProduct == 1
                                        ? "실크"
                                        : dobaeProduct == 2
                                        ? "합지"
                                        : "혼합"
                                }/${dobaeCnt || 0}개`}
                            />
                            <ContentBox
                                title={"견적금액"}
                                content={`${(
                                    dobaePrice || 0
                                ).toLocaleString()}원`}
                            />{" "}
                        </div>
                    </Element>
                </>
            )}
            {zangpanOn === 1 && (
                <>
                    <div className="cutLine" />
                    <Element name="zangpanOn" />
                    <div className="ClkContentWrap ">
                        <Link
                            to="startThings"
                            smooth={true}
                            duration={500}
                            offset={-70}
                        >
                            <div className={`ClkContent `}>
                                <p>기본정보</p>
                            </div>
                        </Link>
                        {dobaeOn === 1 && (
                            <Link
                                to="dobaeOn"
                                smooth={true}
                                duration={500}
                                offset={-70}
                            >
                                <div className={`ClkContent `}>
                                    <p>도배</p>
                                </div>
                            </Link>
                        )}
                        {zangpanOn === 1 && (
                            <Link
                                to="zangpanOn"
                                smooth={true}
                                duration={500}
                                offset={-70}
                            >
                                <div className={`ClkContent active`}>
                                    <p>장판</p>
                                </div>
                            </Link>
                        )}
                        {maruOn === 1 && (
                            <Link
                                to="maruOn"
                                smooth={true}
                                duration={500}
                                offset={-70}
                            >
                                <div className={`ClkContent `}>
                                    <p>마루</p>
                                </div>
                            </Link>
                        )}
                        {maruOn === 1 && demolitionPrice !== 0 && (
                            <Link
                                to="demolition"
                                smooth={true}
                                duration={500}
                                offset={-70}
                            >
                                <div className={`ClkContent `}>
                                    <p>철거</p>
                                </div>
                            </Link>
                        )}
                    </div>
                    <div
                        className="ContentBody displayFlexDirect"
                        style={{ gap: "10px" }}
                    >
                        <ContentBox
                            title={"시공일"}
                            content={` ${moment(zangpanDate).format(
                                "YYYY-MM-DD"
                            )} (${
                                consts.dayText[moment(zangpanDate).day()]
                            }요일) ${
                                zangpanTime
                                    ? moment(zangpanTime, "HH:mm:ss")
                                          .format("A h:mm")
                                          .replace("AM", "오전")
                                          .replace("PM", "오후")
                                    : "-"
                            }`}
                        />
                        <ContentBox
                            title={"시공범위"}
                            content={`${zangpanRange == 1 ? "전체" : "일부"}${
                                zangpanRangeMemo ? `/${zangpanRangeMemo}` : ""
                            }
                             `}
                        />
                        <ContentBox
                            title={"자재"}
                            content={`${
                                zangpanCreateCompanyName || "-"
                            }/${zangpanMM}`}
                        />
                        <ContentBox
                            title={"하단몰딩"}
                            content={`${
                                zangpanMolding == 0
                                    ? "없음"
                                    : zangpanMolding == 1
                                    ? zangpanMoldingMemo
                                    : zangpanMolding == 2
                                    ? "굽도리"
                                    : "걸레받이"
                            }${
                                zangpanMoldingRange == 1
                                    ? "/전체"
                                    : zangpanMoldingRangeMemo
                                    ? `/${zangpanMoldingRangeMemo}`
                                    : ""
                            }`}
                        />
                        <ContentBox
                            title={"문턱시공"}
                            content={
                                zangpanThreshold == 1 ? "시공" : "시공 없음"
                            }
                        />{" "}
                        <ContentBox
                            title={"견적금액"}
                            content={`${(
                                zangpanPrice || 0
                            ).toLocaleString()}원`}
                        />{" "}
                    </div>
                </>
            )}
            {maruOn === 1 && (
                <>
                    <div className="cutLine" />
                    <Element name="maruOn" />
                    <div className="ClkContentWrap ">
                        <Link
                            to="startThings"
                            smooth={true}
                            duration={500}
                            offset={-70}
                        >
                            <div className={`ClkContent `}>
                                <p>기본정보</p>
                            </div>
                        </Link>
                        {dobaeOn === 1 && (
                            <Link
                                to="dobaeOn"
                                smooth={true}
                                duration={500}
                                offset={-70}
                            >
                                <div className={`ClkContent `}>
                                    <p>도배</p>
                                </div>
                            </Link>
                        )}
                        {zangpanOn === 1 && (
                            <Link
                                to="zangpanOn"
                                smooth={true}
                                duration={500}
                                offset={-70}
                            >
                                <div className={`ClkContent `}>
                                    <p>장판</p>
                                </div>
                            </Link>
                        )}
                        {maruOn === 1 && (
                            <Link
                                to="maruOn"
                                smooth={true}
                                duration={500}
                                offset={-70}
                            >
                                <div className={`ClkContent active`}>
                                    <p>마루</p>
                                </div>
                            </Link>
                        )}
                        {maruOn === 1 && demolitionPrice !== 0 && (
                            <Link
                                to="demolition"
                                smooth={true}
                                duration={500}
                                offset={-70}
                            >
                                <div className={`ClkContent `}>
                                    <p>철거</p>
                                </div>
                            </Link>
                        )}
                    </div>
                    <div
                        className="ContentBody displayFlexDirect"
                        style={{ gap: "10px" }}
                    >
                        <ContentBox
                            title={"시공일"}
                            content={
                                maruDate
                                    ? `${moment(maruDate).format(
                                          "YYYY-MM-DD"
                                      )} (${
                                          consts.dayText[moment(maruDate).day()]
                                      }요일) ${
                                          maruTime
                                              ? moment(maruTime, "HH:mm:ss")
                                                    .format("A h:mm")
                                                    .replace("AM", "오전")
                                                    .replace("PM", "오후")
                                              : "-"
                                      }`
                                    : "-"
                            }
                        />
                        <ContentBox
                            title={"시공범위"}
                            content={`${maruRange == 1 ? "전체" : "일부"}${
                                maruRangeMemo ? `/${maruRangeMemo}` : ""
                            }`}
                        />
                        <ContentBox
                            title={"자재"}
                            content={`${maruCreateCompanyName}/${
                                maruFlooring == 1
                                    ? "강마루"
                                    : maruFlooring == 2
                                    ? "강화마루"
                                    : maruFlooring == 3
                                    ? "SB마루"
                                    : "-"
                            } ${
                                maruFlooringMemo ? `/${maruFlooringMemo}` : ""
                            }  `}
                        />
                        <ContentBox
                            title={"기존 바닥재"}
                            content={maruOldProduct || "-"}
                        />
                        <ContentBox
                            title={"하단몰딩"}
                            content={maruDownMolding == 1 ? "시공" : "시공없음"}
                        />{" "}
                        <ContentBox
                            title={"보양작업"}
                            content={maruRepair == 1 ? "시공" : "시공없음"}
                        />{" "}
                        <ContentBox
                            title={"견적금액"}
                            content={`${(maruPrice || 0).toLocaleString()}원`}
                        />{" "}
                    </div>
                </>
            )}
            {maruOn === 1 && demolitionPrice !== 0 && (
                <>
                    <div className="cutLine" />
                    <Element name="demolition" />
                    <div className="ClkContentWrap ">
                        <Link
                            to="startThings"
                            smooth={true}
                            duration={500}
                            offset={-70}
                        >
                            <div className={`ClkContent `}>
                                <p>기본정보</p>
                            </div>
                        </Link>
                        {dobaeOn === 1 && (
                            <Link
                                to="dobaeOn"
                                smooth={true}
                                duration={500}
                                offset={-70}
                            >
                                <div className={`ClkContent `}>
                                    <p>도배</p>
                                </div>
                            </Link>
                        )}
                        {zangpanOn === 1 && (
                            <Link
                                to="zangpanOn"
                                smooth={true}
                                duration={500}
                                offset={-70}
                            >
                                <div className={`ClkContent `}>
                                    <p>장판</p>
                                </div>
                            </Link>
                        )}
                        {maruOn === 1 && (
                            <Link
                                to="maruOn"
                                smooth={true}
                                duration={500}
                                offset={-70}
                            >
                                <div className={`ClkContent `}>
                                    <p>마루</p>
                                </div>
                            </Link>
                        )}
                        {maruOn === 1 && demolitionPrice !== 0 && (
                            <Link
                                to="demolition"
                                smooth={true}
                                duration={500}
                                offset={-70}
                            >
                                <div className={`ClkContent active`}>
                                    <p>철거</p>
                                </div>
                            </Link>
                        )}
                    </div>
                    <div
                        className="ContentBody displayFlexDirect"
                        style={{ gap: "10px" }}
                    >
                        <ContentBox
                            title={"시공일"}
                            content={
                                demolitionDate
                                    ? `${moment(demolitionDate).format(
                                          "YYYY-MM-DD"
                                      )} (${
                                          consts.dayText[
                                              moment(demolitionDate).day()
                                          ]
                                      }요일) ${
                                          demolitionTime
                                              ? moment(
                                                    demolitionTime,
                                                    "HH:mm:ss"
                                                )
                                                    .format("A h:mm")
                                                    .replace("AM", "오전")
                                                    .replace("PM", "오후")
                                              : "-"
                                      }`
                                    : "-"
                            }
                        />
                        <ContentBox
                            title={"시공범위"}
                            content={demolitionRange || "-"}
                        />
                        <ContentBox
                            title={"견적금액"}
                            content={`${(
                                demolitionPrice || 0
                            ).toLocaleString()}원`}
                        />{" "}
                    </div>
                </>
            )}
            <div className="cutLine" />
            {looking === false && (
                <>
                    <div
                        className="ContentBody"
                        style={{ paddingBottom: "70px" }}
                    >
                        <Button
                            title={"A/S 문의하기"}
                            isWhite={true}
                            onClick={BtnClk}
                        />
                    </div>
                </>
            )}
            {looking === false && (
                <BottomBtn
                    active={3}
                    movementFlag={movementFlag}
                    index={index}
                />
            )}
        </>
    );
}
export default UserNewFinished;
