import exit from "../assets/images/exit.svg";
import calendar from "../assets/images/calendar.svg";
import location from "../assets/images/location.svg";
import ImgDel from "../assets/images/ImgDel.svg";
import deleteLine from "../assets/images/deleteLine.svg";
import AccordianDown from "../assets/images/accordianDown.svg";
import sample1 from "../assets/images/sample1.png";
import sample2 from "../assets/images/sample2.png";
import ArrowLeft from "../assets/images/ArrowLeft.svg";
import HabjiCommon from "../assets/images/HabjiCommon.jpg";
import HabjiHighClass from "../assets/images/HabjiHighClass.jpg";
import SilkCommon from "../assets/images/SilkCommon.jpg";
import SilkHighClassAvenue from "../assets/images/SilkHighClassAvenue.png";
import SilkHighClassLohas from "../assets/images/SilkHighClassLohas.jpg";
import brushBlack from "../assets/images/brushBlack.svg";
import CalculaterOff from "../assets/images/CalculaterOff.svg";
import CalculaterOn from "../assets/images/CalculaterOn.svg";
import documentOff from "../assets/images/documentOff.svg";
import homeOn from "../assets/images/homeOn.svg";
import paymentOff from "../assets/images/paymentOff.svg";
import searchOff from "../assets/images/searchOff.svg";
import DobaeImg from "../assets/images/DobaeImg.svg";
import warning from "../assets/images/warning.svg";
import estimate from "../assets/images/estimate.svg";
import contractOff from "../assets/images/contract_off.svg";
import contractOn from "../assets/images/contract_on.svg";
import rightArrowOn from "../assets/images/rightArrowOn.svg";
import rightArrowOff from "../assets/images/rightArrowOff.svg";
import copyImg from "../assets/images/copyImg.svg";
import materialCancel from "../assets/images/materialCancel.svg";
import testing from "../assets/images/testing.png";
import Plus from "../assets/images/Plus.svg";
import arrowLeftWhite from "../assets/images/ArrowLeftWhite.svg";
import copyGreen from "../assets/images/copyGreen.svg";
import ClkCheckOff from "../assets/images/ClkCheckOff.svg";
import ClkCheckOn from "../assets/images/ClkCheckOn.svg";
import deleteBtn from "../assets/images/deleteBtn.svg";
import searchIcon from "../assets/images/searchIcon.svg";
import testing2 from "../assets/images/testing2.png";
import threeLine from "../assets/images/threeLine.svg";
import contractOff2 from "../assets/images/contractOff.svg";
import warningOrange from "../assets/images/warningOrange.svg";
import warningWhite from "../assets/images/warningWhite.svg";
import documentOn from "../assets/images/documentOn.svg";
import homeOff from "../assets/images/homeOff.svg";
import paymentOn from "../assets/images/paymentOn.svg";
import searchOn from "../assets/images/searchOn.svg";
export const imageData = {
    brushBlack,
    exit,
    calendar,
    location,
    ImgDel,
    deleteLine,
    AccordianDown,
    sample1,
    sample2,
    ArrowLeft,
    HabjiCommon,
    HabjiHighClass,
    SilkCommon,
    SilkHighClassAvenue,
    SilkHighClassLohas,
    CalculaterOff,
    documentOff,
    homeOn,
    paymentOff,
    searchOff,
    DobaeImg,
    warning,
    estimate,
    contractOff,
    contractOn,
    rightArrowOn,
    rightArrowOff,
    copyImg,
    materialCancel,
    testing,
    testing2,
    Plus,
    arrowLeftWhite,
    copyGreen,
    ClkCheckOff,
    ClkCheckOn,
    deleteBtn,
    searchIcon,
    threeLine,
    contractOff2,
    warningOrange,
    warningWhite,
    documentOn,
    CalculaterOn,
    homeOff,
    paymentOn,
    searchOn,
};
