import { useEffect, useRef, useState } from "react";
import { HeaderComp3 } from "../components/Header";
import { ProgressBar } from "../components/progressBar";
import { MaterialBaseBoardBox, MaterialBox } from "../components/BoxInfo";
import { imageData } from "../libs/images";
import { Button } from "../components/Button";
import { SearchMaterialPop } from "../components/SearchMaterial";
import ApiData from "../libs/api";
import { usePostData } from "../utils/service";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { ceil, set } from "lodash";
import { toast } from "react-toastify";
import routes from "../libs/routes";
import moment from "moment/moment";
import { BottomBtn } from "../components/BottomBtn";
import { SearchMaterialBaseboardPop } from "../components/SearchMaterialBaseboardPop";
import { getDecryptedParam } from "../components/navigateWithEncryption";
import useCustomer from "../zustands/useCustomer";
import usePopup from "../zustands/usePopup";

function UserNewMaterialSelect() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const index = encodeURIComponent(searchParams.get("sigongIdx"));
    const decryptIdx = getDecryptedParam("sigongIdx");
    const looking = Boolean(searchParams.get("Looking"));

    const MaterialNewSubmit = usePostData(ApiData.MaterialNewSubmit);
    const MaterialNewDelete = usePostData(ApiData.MaterialNewDelete);
    const MaterialNewSelectIn = usePostData(ApiData.MaterialNewSelectIn);
    const MaterialDropDownList = usePostData(ApiData.MaterialDropDownList);

    const MaterialNewSelectDetail = usePostData(
        ApiData.MaterialNewSelectDetail
    );
    const MaterialNewBaseBoardSelect = usePostData(
        ApiData.MaterialNewBaseBoardSelect
    );

    const MaterialNewZangpanSelectDetail = usePostData(
        ApiData.MaterialNewZangpanSelectDetail
    );
    const MaterialCompany = usePostData(ApiData.MaterialCompany);
    const MaterialNewSearch = usePostData(ApiData.MaterialNewSearch);

    const movementFlagVal = usePostData(ApiData.movementFlagVal);
    const sigonginfoDetail = usePostData(ApiData.sigonginfoDetail);

    const [cusmtomPaymentChk, setCusmtomPaymentChk] = useState(0);
    const [clkbox, setClkbox] = useState(2);
    const [materialPopup, setMaterialPopup] = useState(false);
    const [innerHeight, setInnerHeight] = useState(window.innerHeight);

    const [dobaeOn, setDobaeOn] = useState(0);
    const [zangpanOn, setZangpanOn] = useState(0);

    const [materialEndDate, setMaterialEndDate] = useState("");
    const [materialEndDateDays, setMaterialEndDateDays] = useState(0);
    const [materialEndDateHours, setMaterialEndDateHours] = useState(0);
    const [materialEndDateMinutes, setMaterialEndDateMinutes] = useState(0);
    const [materialEndDateSeconds, setMaterialEndDateSeconds] = useState(0);

    const [dobaeProduct, setDobaeProduct] = useState(0);

    const [zangpanMM, setZangpanMM] = useState("");
    const [zangpanMolding, setZangpanMolding] = useState(0);
    const [zangpanCompanyName, setZangpanCompanyName] = useState("");

    const [materialZangpanPopup, setMaterialZangpanPopup] = useState(false);
    const [materialZangpanSearch, setMaterialZangpanSearch] = useState("");
    const [materialZangapanData, setMaterialZangapanData] = useState([]);
    const [materialZangpanCount, setMaterialZangpanCount] = useState(0);
    const [materialZangpanBadge, setMaterialZangpanBadge] = useState(0);
    const [materialZangpanCompanyArr, setMaterialZangpanCompanyArr] = useState(
        []
    );
    const [materialZangpanChooseArr, setMaterialZangpanChooseArr] = useState(
        []
    );
    const [materialZangpanCompany, setMaterialZangpanCompany] = useState("");
    const [materialZangpanColorArr, setMaterialZangpanColorArr] = useState([]);
    const [materialZangpanColor, setMaterialZangpanColor] = useState("");
    const [materialZangpanPatternArr, setMaterialZangpanPatternArr] = useState(
        []
    );
    const [materialZangpanPattern, setMaterialZangpanPattern] = useState("");
    const [materialZangpanClass01, setMaterialZangpanClass01] = useState("");
    const [zangpanPage, setZangpanPage] = useState(1);

    const [ceiling, setCeiling] = useState(0);
    const [dobaeWallCnt, setDobaeWallCnt] = useState(0);
    const [dobaeCompanyName, setDobaeCompanyName] = useState("");
    const [movementFlag, setMovementFlag] = useState([]);

    const [saveLocation, setSaveLocation] = useState(0);
    const [ceilingSilk, setCeilingSilk] = useState([]);
    const [ceilingHapji, setCeilingHapji] = useState([]);
    const [SilkArr, setSilkArr] = useState([]);
    const [HapjiArr, setHapjiArr] = useState([]);
    const [page, setPage] = useState(1);
    const [ceilingPopup, setCeilingPopup] = useState(false);
    const [materialClass01, setMaterialClass01] = useState("");
    const [materialSearch, setMaterialSearch] = useState("");
    const [materialCount, setMaterialCount] = useState(0);
    const [materialData, setMaterialData] = useState([]);
    const [materialBadge, setMaterialBadge] = useState(0);
    const [materialCompanyArr, setMaterialCompanyArr] = useState([]);
    const [materialCompany, setMaterialCompany] = useState("");
    const [materialColorArr, setMaterialColorArr] = useState([]);
    const [materialPatternArr, setMaterialPatternArr] = useState([]);
    const [materialColor, setMaterialColor] = useState("");
    const [materialPattern, setMaterialPattern] = useState("");

    const [wallpaperCountWarning, setWallpaperCountWarning] = useState(false);
    const [silkIsPremium, setSilkIsPremium] = useState(false);
    const [habjiIsPremium, setHapjiIsPremium] = useState(false);
    const [diffSilkCompany, setDiffSilkCompany] = useState(false);
    const [diffHabjiCompany, setDiffHabjiCompany] = useState(false);

    const [baseboardPopOpen, setBaseboardPopOpen] = useState(false);
    const [baseboardmaterialData, setBaseboardMaterialData] = useState([]);
    const [baseboardmaterialCount, setBaseboardMaterialCount] = useState(0);

    const [baseboardMaterialChooseArr, setBaseboardMaterialChooseArr] =
        useState([]);
    const [baseboardMaterialCompany, setBaseboardMaterialCompany] =
        useState("");
    const [baseboardPage, setBaseboardPage] = useState(1);

    const [materialChk, setMaterialChk] = useState(0);

    const [isSaved, setIsSaved] = useState(false);
    const {
        isPopup,
        setPopup,
        setPopTitle,
        setPopContent,
        setSubmitClk,
        isOneBtn = false,
        setOneBtn,
    } = usePopup();

    const { setUseCustomerPaymentName, setUseCustomerContractChk } =
        useCustomer();
    const hasSubmittedRef = useRef(false);
    const today = dayjs();
    const NextClk = () => {
        navigate(`${routes.UserNewFinalConfirm}?sigongIdx=${index}`);
    };

    const BtnClk = () => {
        if (dobaeOn === 1) {
            if (dobaeProduct === 1) {
                if (SilkArr.length === 0) {
                    toast.error(
                        <div
                            style={{
                                fontSize: "12px",
                                lineHeight: "19px",
                            }}
                        >
                            벽지를 입력해 주세요.
                        </div>,
                        {
                            className:
                                "custom-toast-error-login materialSelect",
                            position: "bottom-center",
                        }
                    );
                    return;
                }
            }
            if (dobaeProduct === 2) {
                if (HapjiArr.length === 0) {
                    toast.error(
                        <div
                            style={{
                                fontSize: "12px",
                                lineHeight: "19px",
                            }}
                        >
                            벽지를 입력해 주세요.
                        </div>,
                        {
                            className:
                                "custom-toast-error-login  materialSelect",
                            position: "bottom-center",
                        }
                    );
                    return;
                }
            }
            if (dobaeProduct === 3) {
                if (SilkArr.length + HapjiArr.length === 0) {
                    toast.error(
                        <div
                            style={{
                                fontSize: "12px",
                                lineHeight: "19px",
                            }}
                        >
                            벽지를 입력해 주세요.
                        </div>,
                        {
                            className:
                                "custom-toast-error-login materialSelect",
                            position: "bottom-center",
                        }
                    );
                    return;
                }
            }
            if (
                SilkArr.length > 0 &&
                SilkArr.every(
                    (item) =>
                        item.memo === undefined ||
                        item.memo === "" ||
                        item.memo === null
                )
            ) {
                console.log("??!???");
                toast.error(
                    <div
                        style={{
                            fontSize: "12px",
                            lineHeight: "19px",
                        }}
                    >
                        벽지 위치를 입력해주세요.
                    </div>,
                    {
                        className: "custom-toast-error-login  materialSelect",
                        position: "bottom-center",
                    }
                );
                return;
            }
            if (
                HapjiArr.length > 0 &&
                HapjiArr.every(
                    (item) =>
                        item.memo === undefined ||
                        item.memo === "" ||
                        item.memo === null
                )
            ) {
                toast.error(
                    <div
                        style={{
                            fontSize: "12px",
                            lineHeight: "19px",
                        }}
                    >
                        벽지 위치를 입력해주세요.
                    </div>,
                    {
                        className: "custom-toast-error-login  materialSelect",
                        position: "bottom-center",
                    }
                );
                return;
            }
        }
        if (zangpanOn === 1) {
            if (materialZangpanChooseArr.length === 0) {
                toast.error(
                    <div
                        style={{
                            fontSize: "12px",
                            lineHeight: "19px",
                        }}
                    >
                        바닥재를 입력해 주세요.
                    </div>,
                    {
                        className: "custom-toast-error-login materialSelect",
                        position: "bottom-center",
                    }
                );
                return;
            }

            if (zangpanMolding === 2 || zangpanMolding === 3) {
                if (baseboardMaterialChooseArr.length === 0) {
                    toast.error(
                        <div
                            style={{
                                fontSize: "12px",
                                lineHeight: "19px",
                            }}
                        >
                            걸레받이를 입력해주세요.
                        </div>,
                        {
                            className:
                                "custom-toast-error-login materialSelect",
                            position: "bottom-center",
                        }
                    );
                    return;
                }
            }
        }
        setPopup(true);
        setPopTitle("제출여부 확인");
        setPopContent("선택자재를 제출하시겠습니까?");
        setOneBtn(false);
        setSubmitClk(() => {
            SubmitClk();
        });
    };
    const SubmitClk = () => {
        let sender = {
            sigongIdx: decryptIdx || index,
        };

        MaterialNewSubmit.mutateAsync(sender)
            .then(({ data }) => {
                setPopup(false);
                toast.success(
                    <div
                        style={{
                            fontSize: "14px",
                            lineHeight: "19px",
                        }}
                    >
                        자재 제출이 완료되었습니다.
                    </div>,
                    {
                        className: "custom-toast-error",
                        position: "bottom-center",
                    }
                );

                navigate(`${routes.UserNewFinalConfirm}?sigongIdx=${index}`);
            })
            .catch((error) => {
                console.log("데이터 저장에 실패했습니다.");
            });
    };
    const MaterialIn = ({
        type,
        materialThings,
        ceiling,
        sigongInfoMateIdx,
    }) => {
        let sender = {
            sigongIdx: decryptIdx || index,
            material_type: type,
            material: materialThings,
            ceiling: ceiling,
            sigongInfoMateIdx: sigongInfoMateIdx,
        };
        MaterialNewSelectIn.mutateAsync(sender)
            .then(({ data }) => {
                console.log("데이터 저장에 성공했습니다.");
            })
            .catch((error) => {
                console.log("데이터 저장에 실패했습니다.");
            });
    };
    const MaterialDelete = ({ idx }) => {
        let sender = {
            sigongIdx: decryptIdx || index,
            materialIdx: idx,
        };
        MaterialNewDelete.mutateAsync(sender)
            .then(({ data }) => {
                console.log("데이터 삭제에 성공했습니다.");
            })
            .catch((error) => {
                console.log("데이터 삭제에 실패했습니다.");
            });
    };

    //합지 실크 장판 삭제
    const handleSilkDelete = (index) => {
        setCeilingSilk(ceilingSilk.filter((_, i) => i !== index));
        MaterialDelete({ idx: ceilingSilk[index].idx });
    };
    const handleHapjiDelete = (index) => {
        setCeilingHapji(ceilingHapji.filter((_, i) => i !== index));
        MaterialDelete({ idx: ceilingHapji[index].idx });
    };
    const handleSilkArrDelete = (index) => {
        console.log("???", SilkArr[index]);
        setSilkArr(SilkArr.filter((_, i) => i !== index));
        MaterialDelete({ idx: SilkArr[index].idx });
    };
    const handleHapjiArrDelete = (index) => {
        setHapjiArr(HapjiArr.filter((_, i) => i !== index));
        MaterialDelete({ idx: HapjiArr[index].idx });
    };
    const handleZangpanArrDelete = (index) => {
        setMaterialZangpanChooseArr(
            materialZangpanChooseArr.filter((_, i) => i !== index)
        );
        MaterialDelete({ idx: materialZangpanChooseArr[index].idx });
    };
    const handleBaseboardMaterialDelete = (index) => {
        setBaseboardMaterialChooseArr(
            baseboardMaterialChooseArr.filter((_, i) => i !== index)
        );
        MaterialDelete({ idx: baseboardMaterialChooseArr[index].idx });
    };

    //합지 실크 장판 내용 변경

    const setSilkArrChange = (index, newMemo) => {
        const updatedSilkArr = SilkArr.map((item, i) =>
            i === index ? { ...item, memo: newMemo } : item
        );
        setSilkArr(updatedSilkArr);
    };
    const setHapjiArrChange = (index, newMemo) => {
        const updatedHapjiArr = HapjiArr.map((item, i) =>
            i === index ? { ...item, memo: newMemo } : item
        );
        setHapjiArr(updatedHapjiArr);
    };
    // const setZangpanArrChange = (index, newMemo) => {
    //     const updatedZangpanArr = materialZangpanChooseArr.map((item, i) =>
    //         i === index ? { ...item, memo: newMemo } : item
    //     );
    //     setMaterialZangpanChooseArr(updatedZangpanArr);
    // };
    const setBaseboardMaterialChange = (index, newMemo) => {
        const updatedBaseboardMaterial = baseboardMaterialChooseArr.map(
            (item, i) => (i === index ? { ...item, memo: newMemo } : item)
        );
        setBaseboardMaterialChooseArr(updatedBaseboardMaterial);
    };

    const handleSilkUpdate = (arrData) => {
        MaterialIn({
            type: 1,
            materialThings: arrData,
            ceiling: 0,
            sigongInfoMateIdx: arrData.idx,
        });
    };
    const handleHapjiUpdate = (arrData) => {
        MaterialIn({
            type: 1,
            materialThings: arrData,
            ceiling: 0,
            sigongInfoMateIdx: arrData.idx,
        });
    };
    // const handleZangpanUpdate = (arrData) => {
    //     MaterialIn({
    //         type: 2,
    //         materialThings: arrData,
    //         ceiling: 0,
    //         sigongInfoMateIdx: arrData.idx,
    //     });
    // };
    // const handleBaseboardMaterialUpdate = (arrData) => {
    //     MaterialIn({
    //         type: 4,
    //         materialThings: arrData,
    //         ceiling: 0,
    //         sigongInfoMateIdx: arrData.idx,
    //     });
    // };

    //합지 실크 기업 어레이
    const MaterialCompanyApi = () => {
        MaterialCompany.mutateAsync({ material_type: 1 }).then(({ data }) => {
            setMaterialCompanyArr([
                ...new Set(data?.map((item) => item.material_company)),
            ]);
        });
    };
    //장판 기업 어레이
    const MaterialZangpanCompanyApi = () => {
        MaterialCompany.mutateAsync({ material_type: 2 }).then(({ data }) => {
            setMaterialZangpanCompanyArr([
                ...new Set(data?.map((item) => `${item.material_company}`)),
            ]);
            setMaterialZangpanCompany(data[0]?.material_company);
        });
    };
    //벽지 자재 검색
    const MaterialNewSearchApi = () => {
        let sender = {
            page: page - 1,
            isCeiling: ceilingPopup,
            search: materialSearch,
            material_badge: parseInt(materialBadge),
            material_color: materialColor,
            material_pattern: materialPattern,
            material_company: materialCompany,
            material_class01: materialClass01,
            material_type: 1,
        };
        // console.log("sender", sender);

        MaterialNewSearch.mutateAsync(sender)
            .then(({ data }) => {
                setMaterialData(data.rows);
                setMaterialCount(data.count);

                // setMaterialColorArr([
                //     ...new Set(data.rows.map((item) => item.material_color)),
                // ]);
                // setMaterialPatternArr([
                //     ...new Set(data.rows.map((item) => item.material_pattern)),
                // ]);
            })
            .catch((error) => {});
    };

    const MaterialDropDownSearchApi = () => {
        //여기서 바꾸면 됩니다..
        setMaterialColorArr(["화이트", "그레이", "아이보리", "기타"]);
        setMaterialPatternArr(["무지", "패턴"]);
        //     let sender = {
        //         isCeiling: ceilingPopup,
        //         material_company: materialCompany,
        //         material_class01: materialClass01,
        //         material_type: 1,
        //     };

        //     MaterialDropDownList.mutateAsync(sender)
        //         .then(({ data }) => {
        //             setMaterialColorArr([
        //                 ...new Set(data.map((item) => item.material_color)),
        //             ]);
        //             setMaterialPatternArr([
        //                 ...new Set(data.map((item) => item.material_pattern)),
        //             ]);
        //         })
        //         .catch((error) => {});
    };
    // 장판 검색
    const MaterialNewbaseboardSearchApi = () => {
        let sender = {
            page: baseboardPage - 1,
            material_class01: zangpanMolding === 2 ? "굽도리" : "걸레받이",
            material_type: 4,
        };

        MaterialNewBaseBoardSelect.mutateAsync(sender)
            .then(({ data }) => {
                setBaseboardMaterialData(data.rows);
                setBaseboardMaterialCount(data.count);
            })
            .catch((error) => {});
    };
    const MaterialNewZangpanSearchApi = () => {
        let sender = {
            page: zangpanPage - 1,
            search: materialZangpanSearch,
            material_badge: parseInt(materialZangpanBadge),
            material_color: materialZangpanColor,
            material_pattern: materialZangpanPattern,
            material_company: materialZangpanCompany,
            material_class01: materialZangpanClass01,
            material_type: 2,
        };

        MaterialNewSearch.mutateAsync(sender)
            .then(({ data }) => {
                setMaterialZangapanData(data.rows);
                setMaterialZangpanCount(data.count);

                // setMaterialZangpanColorArr([
                //     ...new Set(data.rows.map((item) => item.material_color)),
                // ]);
                // setMaterialZangpanPatternArr([
                //     ...new Set(data.rows.map((item) => item.material_pattern)),
                // ]);
            })
            .catch((error) => {});
    };

    const MaterialZangpanDropDownSearchApi = () => {
        //여기서 바꾸면 됩니다..
        setMaterialZangpanColorArr(["화이트", "그레이", "아이보리", "기타"]);
        setMaterialZangpanPatternArr(["우드", "마블", "스톤", "기타"]);
        // let sender = {
        //     // isCeiling: ceilingPopup,
        //     material_company: materialZangpanCompany,
        //     material_class01: materialZangpanClass01,
        //     material_type: 2,
        // };

        // MaterialDropDownList.mutateAsync(sender)
        //     .then(({ data }) => {
        //         setMaterialZangpanColorArr([
        //             ...new Set(data.map((item) => item.material_color)),
        //         ]);
        //         setMaterialZangpanPatternArr([
        //             ...new Set(data.map((item) => item.material_pattern)),
        //         ]);
        //     })
        //     .catch((error) => {});
    };
    const MaterialZangpanSelectDetailApi = () => {
        MaterialNewZangpanSelectDetail.mutateAsync({
            sigongIdx: decryptIdx || index,
        })
            .then(({ data }) => {
                setMaterialZangpanChooseArr(
                    data.zangpan.filter(
                        (item) => item.material_class01 === zangpanMM
                    )
                );
                setBaseboardMaterialChooseArr(
                    data.Baseboard.filter(
                        (item) =>
                            item.material_class01 ===
                            (zangpanMolding === 2
                                ? "굽도리"
                                : zangpanMolding === 3
                                ? "걸레받이"
                                : "")
                    )
                );
                setIsSaved(false);
            })
            .catch((err) => {
                // toast.error(err.response.data);
            });
    };

    useEffect(() => {
        const handleResize = () => {
            setInnerHeight(window.innerHeight);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [window.innerHeight]);
    const MaterialNewSelectDetailApi = () => {
        MaterialNewSelectDetail.mutateAsync({ sigongIdx: decryptIdx || index })
            .then(({ data }) => {
                setCeilingSilk(
                    data.ceiling.filter(
                        (item) => item.material_class01 === "실크"
                    )
                );
                setCeilingHapji(
                    data.ceiling.filter(
                        (item) => item.material_class01 === "합지"
                    )
                );
                setSilkArr(
                    data.material.filter(
                        (item) => item.material_class01 === "실크"
                    )
                );
                setHapjiArr(
                    data.material.filter(
                        (item) => item.material_class01 === "합지"
                    )
                );
                setIsSaved(false);
            })
            .catch((err) => {
                // toast.error(err.response.data);
            });
    };

    const movementFlagValApi = () => {
        movementFlagVal
            .mutateAsync({ sigongIdx: decryptIdx || index })
            .then(({ data }) => {
                setMovementFlag(data);
            })
            .catch((error) => {});
    };
    const sigonginfoDetailApi = () => {
        sigonginfoDetail
            .mutateAsync({ sigongIdx: decryptIdx || index })
            .then((data) => {
                console.log("data", data.data);
                setMaterialChk(data.data.material_chk);

                setUseCustomerPaymentName(data.data.customer_payment_name);
                setUseCustomerContractChk(data.data.customer_payment_chk);

                setMaterialEndDate(data.data.material_date);
                setClkbox(data.data.dobae === 1 ? 1 : 2);
                setDobaeOn(data.data.dobae);
                setZangpanOn(data.data.zangpan);
                setDobaeProduct(data.data.dobae_product);
                setDobaeWallCnt(data.data.dobae_cnt);
                setCeiling(data.data.dobae_ceiling);
                setDobaeCompanyName(data.data.dobae_company_name);
                setZangpanMolding(data.data.zangpan_molding);
                setZangpanCompanyName(data.data.zangpan_company_name);
                setZangpanMM(data.data.zangpan_mm);
                setZangpanMolding(data.data.zangpan_molding);
                setCusmtomPaymentChk(data.data.customer_payment_chk);
            })
            .catch((err) => {
                // toast.error(err.response.data);
            });
    };

    const materialChoose = ({ ceiling, location, class01 }) => {
        setMaterialPopup(true);
        setCeilingPopup(ceiling);
        setSaveLocation(parseInt(location));
        setMaterialClass01(class01);
        setMaterialCompany(dobaeCompanyName);
        setPage(1);
    };
    const zangpanChoose = () => {
        setMaterialZangpanPopup(true);
        setSaveLocation(parseInt(1));
        setMaterialZangpanClass01(zangpanMM);
        setZangpanPage(1);
    };
    const baseboardMaterialChoose = () => {
        setBaseboardPopOpen(true);
        setBaseboardMaterialCompany(
            zangpanMolding === 2 ? "걸레받이-굽도리" : "걸레받이"
        );
        setBaseboardPage(1);
    };

    useEffect(() => {
        let interval;

        if (materialChk === 1) {
            setMaterialEndDateDays(0);
            setMaterialEndDateHours(0);
            setMaterialEndDateMinutes(0);
            setMaterialEndDateSeconds(0);
            return;
        }
        if (materialEndDate) {
            interval = setInterval(() => {
                const now = moment();
                if (moment(materialEndDate).isBefore(now)) {
                    setMaterialEndDateDays(0);
                    setMaterialEndDateHours(0);
                    setMaterialEndDateMinutes(0);
                    setMaterialEndDateSeconds(0);
                    if (!hasSubmittedRef.current) {
                        MaterialNewSubmit.mutateAsync({
                            sigongIdx: decryptIdx || index,
                        })
                            .then(({ data }) => {
                                console.log("자동저장됨");
                                hasSubmittedRef.current = true;
                                setMaterialChk(1);
                                return;
                            })
                            .catch((error) => {});
                    }
                } else {
                    setMaterialEndDateDays(
                        moment(materialEndDate).diff(now, "days")
                    );
                    setMaterialEndDateHours(
                        moment(materialEndDate).diff(now, "hours") % 24
                    );
                    setMaterialEndDateMinutes(
                        moment(materialEndDate).diff(now, "minutes") % 60
                    );
                    setMaterialEndDateSeconds(
                        moment(materialEndDate).diff(now, "seconds") % 60
                    );
                }
            }, 1000);
        } else {
            setMaterialEndDateDays(0);
            setMaterialEndDateHours(0);
            setMaterialEndDateMinutes(0);
            setMaterialEndDateSeconds(0);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [materialEndDate]);

    useEffect(() => {
        // MaterialTimeApi();
        movementFlagValApi();
        sigonginfoDetailApi();
        MaterialCompanyApi();

        //장판
        MaterialZangpanCompanyApi();
    }, [index]);

    useEffect(() => {
        MaterialNewSelectDetailApi();
    }, [movementFlag]);

    useEffect(() => {
        MaterialZangpanSelectDetailApi();
    }, [zangpanMolding, zangpanMM]);
    useEffect(() => {
        MaterialNewSearchApi();
        MaterialDropDownSearchApi();
    }, [
        ceilingPopup,
        materialSearch,
        materialBadge,
        materialColor,
        materialCompany,
        materialPattern,
        materialClass01,
        page,
    ]);

    useEffect(() => {
        MaterialNewZangpanSearchApi();
        MaterialZangpanDropDownSearchApi();
    }, [
        zangpanPage,
        materialZangpanSearch,
        materialZangpanBadge,
        materialZangpanColor,
        materialZangpanPattern,
        materialZangpanCompany,
        materialZangpanClass01,
    ]);

    useEffect(() => {
        MaterialNewbaseboardSearchApi();
    }, [baseboardPage, baseboardMaterialCompany, baseboardPopOpen]);

    useEffect(() => {
        if (
            (SilkArr.length || 0) + (HapjiArr.length || 0) >
            (dobaeProduct === 3 && dobaeWallCnt === 1
                ? dobaeWallCnt + 1
                : dobaeWallCnt)
        ) {
            setWallpaperCountWarning(true);
        } else {
            setWallpaperCountWarning(false);
        }
        if (
            SilkArr.find(
                (item) => item.material_badge === 3 || item.material_badge === 4
            )
        ) {
            setSilkIsPremium(true);
            // material_badge가 3 또는 4인 항목이 있을 때 실행할 코드
        } else {
            setSilkIsPremium(false);
        }
        if (
            HapjiArr.find(
                (item) => item.material_badge === 3 || item.material_badge === 4
            )
        ) {
            setHapjiIsPremium(true);
        } else {
            setHapjiIsPremium(false);
        }

        if (SilkArr.length > 0) {
            if (
                SilkArr.every(
                    (item) => item.material_company === dobaeCompanyName
                )
            ) {
                setDiffSilkCompany(false);
            } else {
                setDiffSilkCompany(true);
            }
        } else {
            setDiffSilkCompany(false);
        }
        if (HapjiArr.length > 0) {
            if (
                HapjiArr.every(
                    (item) => item.material_company === dobaeCompanyName
                )
            ) {
                setDiffHabjiCompany(false);
            } else {
                setDiffHabjiCompany(true);
            }
        } else {
            setDiffHabjiCompany(false);
        }
    }, [SilkArr, HapjiArr]);

    // useEffect(() => {
    //     if (materialPopup || materialZangpanPopup || baseboardPopOpen) {
    //         setIsSaved(false);
    //         document.body.style.overflow = "hidden";
    //     } else {
    //         document.body.style.overflow = "auto";
    //     }
    // }, [materialPopup, materialZangpanPopup, baseboardPopOpen]);

    useEffect(() => {
        console.log("isSaved", isSaved);
        if (isSaved) {
            MaterialNewSelectDetailApi();
            MaterialZangpanSelectDetailApi();
        }
    }, [isSaved]);

    return (
        <>
            {/* 벽지 검색 모달 */}
            <SearchMaterialPop
                SigongIdx={decryptIdx || index}
                materialType={1}
                isOpen={materialPopup}
                setIsOpen={setMaterialPopup}
                materialSearch={materialSearch}
                setMaterialSearch={setMaterialSearch}
                materialData={materialData}
                setMaterialData={setMaterialData}
                materialCount={materialCount}
                materialBadge={materialBadge}
                setMaterialBadge={setMaterialBadge}
                materialCompanyArr={materialCompanyArr}
                materialCompany={materialCompany}
                setMaterialCompany={setMaterialCompany}
                materialColorArr={materialColorArr}
                materialColor={materialColor}
                setMaterialColor={setMaterialColor}
                materialPatternArr={materialPatternArr}
                materialPattern={materialPattern}
                setMaterialPattern={setMaterialPattern}
                CeilingPopup={ceilingPopup}
                setCeilingPopup={setCeilingPopup}
                materialClass01={materialClass01}
                setMaterialClass01={setMaterialClass01}
                setIsSaved={setIsSaved}
                innerHeight={innerHeight}
                // SetSaveArr={
                //     saveLocation === 1
                //         ? setCeilingSilk
                //         : saveLocation === 2
                //         ? setSilkArr
                //         : saveLocation === 3
                //         ? setCeilingHapji
                //         : setHapjiArr
                // }
                page={page}
                setPage={setPage}
            />
            {/* 장판 검색 모달 */}
            <SearchMaterialPop
                SigongIdx={decryptIdx || index}
                materialType={2}
                isOpen={materialZangpanPopup}
                setIsOpen={setMaterialZangpanPopup}
                materialSearch={materialZangpanSearch}
                setMaterialSearch={setMaterialZangpanSearch}
                materialData={materialZangapanData}
                setMaterialData={setMaterialZangapanData}
                materialCount={materialZangpanCount}
                materialBadge={materialZangpanBadge}
                setMaterialBadge={setMaterialZangpanBadge}
                materialCompanyArr={materialZangpanCompanyArr}
                materialCompany={materialZangpanCompany}
                setMaterialCompany={setMaterialZangpanCompany}
                materialColorArr={materialZangpanColorArr}
                materialColor={materialZangpanColor}
                setMaterialColor={setMaterialZangpanColor}
                materialPatternArr={materialZangpanPatternArr}
                materialPattern={materialZangpanPattern}
                setMaterialPattern={setMaterialZangpanPattern}
                // setCeilingPopup={setCeilingPopup}
                materialClass01={materialZangpanClass01}
                setMaterialClass01={setMaterialZangpanClass01}
                // SetSaveArr={setMaterialZangpanChooseArr}
                setIsSaved={setIsSaved}
                zangpanMM={zangpanMM}
                page={zangpanPage}
                setPage={setZangpanPage}
                innerHeight={innerHeight}
            />
            <SearchMaterialBaseboardPop
                SigongIdx={decryptIdx || index}
                isOpen={baseboardPopOpen}
                setIsOpen={setBaseboardPopOpen}
                materialData={baseboardmaterialData}
                setMaterialData={setBaseboardMaterialData}
                materialCount={baseboardmaterialCount}
                materialCompany={baseboardMaterialCompany}
                // SetSaveArr={setBaseboardMaterialChooseArr}
                setIsSaved={setIsSaved}
                page={baseboardPage}
                setPage={setBaseboardPage}
                innerHeight={innerHeight}
            />

            <HeaderComp3 title={"자재선택"} />
            <ProgressBar
                progress={5}
                style={{ marginTop: "10px" }}
                movementFlag={movementFlag}
                index={index}
                paymentChk={cusmtomPaymentChk}
            />
            <div className="ClkContentWrap">
                {dobaeOn === 1 && (
                    <div
                        className={`ClkContent ${clkbox === 1 ? "active" : ""}`}
                        onClick={() => setClkbox(1)}
                    >
                        <p>도배</p>
                    </div>
                )}
                {zangpanOn === 1 && (
                    <div
                        className={`ClkContent ${clkbox === 2 ? "active" : ""}`}
                        onClick={() => setClkbox(2)}
                    >
                        <p>바닥재</p>
                    </div>
                )}
            </div>
            {clkbox === 1 && (
                <>
                    <div className="ContentBody">
                        <h2
                            style={{
                                fontSize: "17px",
                                marginBottom: "20px",
                                textAlign: "center",
                                marginTop: "10px",
                            }}
                        >
                            자재 제출이 완료되었습니다.
                        </h2>
                        <div className="timerWrap">
                            <div className="timer">
                                <h1>입력기한</h1>
                                <div className="alignLine" />
                                <p>
                                    {moment(materialEndDate).format(
                                        "YYYY년 MM월 DD일 HH:mm"
                                    )}
                                </p>
                            </div>
                            <div className="timer">
                                <h1>남은시간</h1>
                                <div className="alignLine" />
                                <p>
                                    {" "}
                                    {materialEndDateDays > 1 ? (
                                        <span>{`${materialEndDateDays}일 ${materialEndDateHours}시간 ${materialEndDateMinutes}분 ${materialEndDateSeconds}초`}</span>
                                    ) : (
                                        <span
                                            style={{ color: "red" }}
                                        >{`${materialEndDateDays}일 ${materialEndDateHours}시간 ${materialEndDateMinutes}분 ${materialEndDateSeconds}초`}</span>
                                    )}
                                </p>
                            </div>
                        </div>
                        <div className="ContentBodyUl">
                            <ul>
                                <li>
                                    온라인 샘플북 확인 후 벽지번호와 시공위치를
                                    입력해주세요.
                                </li>
                                <li>
                                    샘플북 조회는 컴퓨터 모니터(큰 화면) 사용을
                                    권장합니다.
                                </li>
                                <li>
                                    여덟시도배는 개나리벽지를 사용하고 있습니다.
                                </li>
                                <li>
                                    모든 제조사의 자재를 취급하고 있지 않습니다.
                                    해당 페이지에 없는 자재가 사전에 계획되어
                                    있으신 경우 별도 문의해주세요.
                                </li>
                            </ul>
                        </div>
                    </div>
                    {(dobaeProduct === 1 || dobaeProduct === 3) && (
                        <>
                            <div className="ContentHeader">
                                <h1>도배</h1>
                                <div className="alignLine" />
                                <p>실크</p>
                            </div>

                            {ceiling === 1 && (
                                <div className="ContentBody">
                                    <div className="warningWrap">
                                        {diffSilkCompany && (
                                            <div className="warningOrange">
                                                <img
                                                    src={
                                                        imageData.warningOrange
                                                    }
                                                />
                                                <div className="warningText">
                                                    <h2>
                                                        선택한 브랜드에 따라
                                                        가격이 변동될 수
                                                        있습니다.
                                                    </h2>
                                                </div>
                                            </div>
                                        )}

                                        {wallpaperCountWarning && (
                                            <div className="warningGreen">
                                                <img
                                                    src={imageData.warningWhite}
                                                />
                                                <div className="warningText">
                                                    <h2>
                                                        벽지추가금이 발생해요!
                                                    </h2>
                                                    <p>
                                                        추가로 벽지 선택 시 색상
                                                        당 합지 15,000원/실크
                                                        30,000원이 추가됩니다.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        {silkIsPremium && (
                                            <div className="warningOrange">
                                                <img
                                                    src={
                                                        imageData.warningOrange
                                                    }
                                                />
                                                <div className="warningText">
                                                    <h2>
                                                        프리미엄 자재 선택시
                                                        색상 당 추가금
                                                    </h2>
                                                    <ul>
                                                        <li>
                                                            트렌디(합지) +
                                                            18,000원
                                                        </li>
                                                        <li>
                                                            로하스/에비뉴(실크)
                                                            + 37,000원
                                                        </li>
                                                        <li>
                                                            베스티/지아패브릭(실크)
                                                            + 43,000원
                                                        </li>
                                                        <li>
                                                            디아망(실크) +
                                                            69,000원
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <h2
                                        style={{
                                            fontSize: "13px",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        천장
                                    </h2>
                                    <div className="ContentBodyText2">
                                        <p>천장은 화이트 벽지로 시공됩니다.</p>
                                        <p>
                                            특별한 선호가 있다면 벽지선택 후
                                            시공위치에 기재해주세요.
                                        </p>
                                    </div>
                                    {(ceilingSilk?.length === 0 ||
                                        ceilingSilk === undefined) && (
                                        <div
                                            className="firstAdd"
                                            style={{ marginTop: "10px" }}
                                            onClick={() => {
                                                !materialChk &&
                                                    materialChoose({
                                                        ceiling: true,
                                                        location: 1,
                                                        class01: "실크",
                                                    });
                                            }}
                                        >
                                            <img src={imageData.Plus} />
                                            <p>추가</p>
                                        </div>
                                    )}
                                    {ceilingSilk?.map((item, index) => {
                                        return (
                                            <MaterialBox
                                                key={index}
                                                material_img={
                                                    item?.material_img
                                                }
                                                material_company={
                                                    item?.material_company
                                                }
                                                material_number={
                                                    item?.material_number
                                                }
                                                material_name={
                                                    item?.material_name
                                                }
                                                material_pattern={
                                                    item?.material_pattern
                                                }
                                                material_color={
                                                    item?.material_color
                                                }
                                                material_badge={
                                                    item?.material_badge
                                                }
                                                value={"천장"}
                                                material_chk={materialChk}
                                                readOnly={true}
                                                onDelete={() =>
                                                    handleSilkDelete(index)
                                                }
                                                // onBlur={() => handleBlur(index)}
                                            />
                                        );
                                    })}
                                </div>
                            )}

                            <div
                                className="ContentBody"
                                style={{ borderTop: "1px solid #ececef" }}
                            >
                                {ceiling === 2 && (
                                    <div className="warningWrapNoCeiling">
                                        {diffSilkCompany && (
                                            <div className="warningOrange">
                                                <img
                                                    src={
                                                        imageData.warningOrange
                                                    }
                                                />
                                                <div className="warningText">
                                                    <h2>
                                                        선택한 브랜드에 따라
                                                        가격이 변동될 수
                                                        있습니다.
                                                    </h2>
                                                </div>
                                            </div>
                                        )}

                                        {wallpaperCountWarning && (
                                            <div className="warningGreen">
                                                <img
                                                    src={imageData.warningWhite}
                                                />
                                                <div className="warningText">
                                                    <h2>
                                                        벽지추가금이 발생해요!
                                                    </h2>
                                                    <p>
                                                        추가로 벽지 선택 시 색상
                                                        당 합지 15,000원/실크
                                                        30,000원이 추가됩니다.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        {silkIsPremium && (
                                            <div className="warningOrange">
                                                <img
                                                    src={
                                                        imageData.warningOrange
                                                    }
                                                />
                                                <div className="warningText">
                                                    <h2>
                                                        프리미엄 자재 선택시
                                                        색상 당 추가금
                                                    </h2>
                                                    <ul>
                                                        <li>
                                                            트렌디(합지) +
                                                            18,000원
                                                        </li>
                                                        <li>
                                                            로하스/에비뉴(실크)
                                                            + 37,000원
                                                        </li>
                                                        <li>
                                                            베스티/지아패브릭(실크)
                                                            + 43,000원
                                                        </li>
                                                        <li>
                                                            디아망(실크) +
                                                            69,000원
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                                <h2
                                    style={{
                                        fontSize: "13px",
                                        marginBottom: "10px",
                                    }}
                                >
                                    벽면 - 실크
                                </h2>

                                {SilkArr?.length === 0 ||
                                SilkArr === undefined ? (
                                    <div
                                        className="firstAdd"
                                        style={{ marginTop: "10px" }}
                                        onClick={() => {
                                            !materialChk &&
                                                materialChoose({
                                                    ceiling: false,
                                                    location: 2,
                                                    class01: "실크",
                                                });
                                        }}
                                    >
                                        <img src={imageData.Plus} />
                                        <p>추가</p>
                                    </div>
                                ) : (
                                    <>
                                        {SilkArr?.map((item, index) => {
                                            return (
                                                <MaterialBox
                                                    key={index}
                                                    material_img={
                                                        item?.material_img
                                                    }
                                                    material_company={
                                                        item?.material_company
                                                    }
                                                    material_number={
                                                        item?.material_number
                                                    }
                                                    material_name={
                                                        item?.material_name
                                                    }
                                                    material_pattern={
                                                        item?.material_pattern
                                                    }
                                                    material_color={
                                                        item?.material_color
                                                    }
                                                    material_badge={
                                                        item?.material_badge
                                                    }
                                                    value={item?.memo}
                                                    material_chk={materialChk}
                                                    onChange={(newMemo) =>
                                                        setSilkArrChange(
                                                            index,
                                                            newMemo
                                                        )
                                                    }
                                                    readOnly={
                                                        materialChk
                                                            ? true
                                                            : false
                                                    }
                                                    onBlur={() =>
                                                        handleSilkUpdate(item)
                                                    }
                                                    onDelete={() =>
                                                        handleSilkArrDelete(
                                                            index
                                                        )
                                                    }
                                                />
                                            );
                                        })}
                                        <div
                                            className="secondAdd"
                                            style={{ marginTop: "20px" }}
                                            onClick={() => {
                                                !materialChk &&
                                                    materialChoose({
                                                        ceiling: false,
                                                        location: 2,
                                                        class01: "실크",
                                                    });
                                            }}
                                        >
                                            <img src={imageData.Plus} />
                                            <p>추가</p>
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                    {(dobaeProduct === 2 || dobaeProduct === 3) && (
                        <>
                            <div className="ContentHeader">
                                <h1>도배</h1>
                                <div className="alignLine" />
                                <p>합지</p>
                            </div>

                            {ceiling === 1 && (
                                <div className="ContentBody">
                                    <div className="warningWrap">
                                        {diffHabjiCompany && (
                                            <div className="warningOrange">
                                                <img
                                                    src={
                                                        imageData.warningOrange
                                                    }
                                                />
                                                <div className="warningText">
                                                    <h2>
                                                        선택한 브랜드에 따라
                                                        가격이 변동될 수
                                                        있습니다.
                                                    </h2>
                                                </div>
                                            </div>
                                        )}

                                        {wallpaperCountWarning && (
                                            <div className="warningGreen">
                                                <img
                                                    src={imageData.warningWhite}
                                                />
                                                <div className="warningText">
                                                    <h2>
                                                        벽지추가금이 발생해요!
                                                    </h2>
                                                    <p>
                                                        추가로 벽지 선택 시 색상
                                                        당 합지 15,000원/실크
                                                        30,000원이 추가됩니다.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        {habjiIsPremium && (
                                            <div className="warningOrange">
                                                <img
                                                    src={
                                                        imageData.warningOrange
                                                    }
                                                />
                                                <div className="warningText">
                                                    <h2>
                                                        프리미엄 자재 선택시
                                                        색상 당 추가금
                                                    </h2>
                                                    <ul>
                                                        <li>
                                                            트렌디(합지) +
                                                            18,000원
                                                        </li>
                                                        <li>
                                                            로하스/에비뉴(실크)
                                                            + 37,000원
                                                        </li>
                                                        <li>
                                                            베스티/지아패브릭(실크)
                                                            + 43,000원
                                                        </li>
                                                        <li>
                                                            디아망(실크) +
                                                            69,000원
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <h2
                                        style={{
                                            fontSize: "13px",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        천장
                                    </h2>
                                    <div className="ContentBodyText2">
                                        <p>천장은 화이트 벽지로 시공됩니다.</p>
                                        <p>
                                            특별한 선호가 있다면 벽지선택 후
                                            시공위치에 기재해주세요.
                                        </p>
                                    </div>
                                    {(ceilingHapji?.length === 0 ||
                                        ceilingHapji === undefined) && (
                                        <div
                                            className="firstAdd"
                                            style={{ marginTop: "10px" }}
                                            onClick={() => {
                                                !materialChk &&
                                                    materialChoose({
                                                        ceiling: true,
                                                        location: 3,
                                                        class01: "합지",
                                                    });
                                            }}
                                        >
                                            <img src={imageData.Plus} />
                                            <p>추가</p>
                                        </div>
                                    )}
                                    {ceilingHapji?.map((item, index) => {
                                        return (
                                            <MaterialBox
                                                key={index}
                                                material_img={
                                                    item?.material_img
                                                }
                                                material_company={
                                                    item?.material_company
                                                }
                                                material_number={
                                                    item?.material_number
                                                }
                                                material_name={
                                                    item?.material_name
                                                }
                                                material_pattern={
                                                    item?.material_pattern
                                                }
                                                material_color={
                                                    item?.material_color
                                                }
                                                material_badge={
                                                    item?.material_badge
                                                }
                                                material_chk={materialChk}
                                                value={"천장"}
                                                readOnly={true}
                                                onDelete={() =>
                                                    handleHapjiDelete(index)
                                                }
                                            />
                                        );
                                    })}
                                </div>
                            )}
                            <div
                                className="ContentBody"
                                style={{ borderTop: "1px solid #ececef" }}
                            >
                                {ceiling === 2 && (
                                    <div className="warningWrapNoCeiling">
                                        {diffHabjiCompany && (
                                            <div className="warningOrange">
                                                <img
                                                    src={
                                                        imageData.warningOrange
                                                    }
                                                />
                                                <div className="warningText">
                                                    <h2>
                                                        선택한 브랜드에 따라
                                                        가격이 변동될 수
                                                        있습니다.
                                                    </h2>
                                                </div>
                                            </div>
                                        )}

                                        {wallpaperCountWarning && (
                                            <div className="warningGreen">
                                                <img
                                                    src={imageData.warningWhite}
                                                />
                                                <div className="warningText">
                                                    <h2>
                                                        벽지추가금이 발생해요!
                                                    </h2>
                                                    <p>
                                                        추가로 벽지 선택 시 색상
                                                        당 합지 15,000원/실크
                                                        30,000원이 추가됩니다.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        {habjiIsPremium && (
                                            <div className="warningOrange">
                                                <img
                                                    src={
                                                        imageData.warningOrange
                                                    }
                                                />
                                                <div className="warningText">
                                                    <h2>
                                                        프리미엄 자재 선택시
                                                        색상 당 추가금
                                                    </h2>
                                                    <ul>
                                                        <li>
                                                            트렌디(합지) +
                                                            18,000원
                                                        </li>
                                                        <li>
                                                            로하스/에비뉴(실크)
                                                            + 37,000원
                                                        </li>
                                                        <li>
                                                            베스티/지아패브릭(실크)
                                                            + 43,000원
                                                        </li>
                                                        <li>
                                                            디아망(실크) +
                                                            69,000원
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                                <h2
                                    style={{
                                        fontSize: "13px",
                                        marginBottom: "10px",
                                    }}
                                >
                                    벽면 - 합지
                                </h2>
                                {HapjiArr?.length === 0 ||
                                HapjiArr === undefined ? (
                                    <div
                                        className="firstAdd"
                                        style={{ marginTop: "10px" }}
                                        onClick={() => {
                                            !materialChk &&
                                                materialChoose({
                                                    ceiling: false,
                                                    location: 4,
                                                    class01: "합지",
                                                });
                                        }}
                                    >
                                        <img src={imageData.Plus} />
                                        <p>추가</p>
                                    </div>
                                ) : (
                                    <>
                                        {HapjiArr?.map((item, index) => {
                                            return (
                                                <MaterialBox
                                                    key={index}
                                                    material_img={
                                                        item?.material_img
                                                    }
                                                    material_company={
                                                        item?.material_company
                                                    }
                                                    material_number={
                                                        item?.material_number
                                                    }
                                                    material_name={
                                                        item?.material_name
                                                    }
                                                    material_pattern={
                                                        item?.material_pattern
                                                    }
                                                    material_color={
                                                        item?.material_color
                                                    }
                                                    material_badge={
                                                        item?.material_badge
                                                    }
                                                    material_chk={materialChk}
                                                    value={item?.memo}
                                                    onChange={(newMemo) =>
                                                        setHapjiArrChange(
                                                            index,
                                                            newMemo
                                                        )
                                                    }
                                                    onBlur={() =>
                                                        handleHapjiUpdate(item)
                                                    }
                                                    readOnly={
                                                        materialChk
                                                            ? true
                                                            : false
                                                    }
                                                    onDelete={() =>
                                                        handleHapjiArrDelete(
                                                            index
                                                        )
                                                    }
                                                />
                                            );
                                        })}
                                        <div
                                            className="secondAdd"
                                            style={{ marginTop: "20px" }}
                                            onClick={() => {
                                                !materialChk &&
                                                    materialChoose({
                                                        ceiling: false,
                                                        location: 4,
                                                        class01: "합지",
                                                    });
                                            }}
                                        >
                                            <img src={imageData.Plus} />
                                            <p>추가</p>
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </>
            )}
            {clkbox === 2 && (
                <>
                    <div className="ContentBody">
                        <div className="timerWrap">
                            <div className="timer">
                                <h1>입력기한</h1>
                                <div className="alignLine" />
                                <p>
                                    {moment(materialEndDate).format(
                                        "YYYY년 MM월 DD일 HH:mm"
                                    )}
                                </p>
                            </div>
                            <div className="timer">
                                <h1>남은시간</h1>
                                <div className="alignLine" />
                                <p>
                                    {" "}
                                    {materialEndDateDays > 1 ? (
                                        <span>{`${materialEndDateDays}일 ${materialEndDateHours}시간 ${materialEndDateMinutes}분 ${materialEndDateSeconds}초`}</span>
                                    ) : (
                                        <span
                                            style={{ color: "red" }}
                                        >{`${materialEndDateDays}일 ${materialEndDateHours}시간 ${materialEndDateMinutes}분 ${materialEndDateSeconds}초`}</span>
                                    )}
                                </p>
                            </div>
                        </div>
                        <div className="ContentBodyUl">
                            <ul>
                                <li>
                                    온라인 샘플북 확인 후 바닥재 번호를
                                    입력해주세요.
                                </li>
                                <li>
                                    샘플북 조회는 컴퓨터 모니터(큰 화면) 사용을
                                    권장합니다.
                                </li>
                                <li>
                                    모든 제조사의 자재를 취급하고 있지 않습니다.
                                    해당 페이지에 없는 자재가 사전에 계획되어
                                    있으신 경우 별도 문의해주세요.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="ContentHeader">
                        <h1>바닥재</h1>
                        <div className="alignLine" />
                        <p>장판</p>
                    </div>
                    <div className="ContentBody">
                        <div className="warningWrap">
                            {/* <div className="warningOrange">
                                <img src={imageData.warningOrange} />
                                <div className="warningText">
                                    <h2>
                                        선택한 브랜드에 따라 가격이 변동될 수
                                        있습니다.
                                    </h2>
                                </div>
                            </div> */}
                        </div>

                        <h2
                            style={{
                                fontSize: "13px",
                                marginBottom: "10px",
                            }}
                        >
                            장판 {zangpanMM}
                        </h2>

                        {materialZangpanChooseArr?.length === 0 ||
                        materialZangpanChooseArr === undefined ? (
                            <div
                                className="firstAdd"
                                style={{ marginTop: "10px" }}
                                onClick={() => {
                                    !materialChk && zangpanChoose();
                                }}
                            >
                                <img src={imageData.Plus} />
                                <p>추가</p>
                            </div>
                        ) : (
                            <>
                                {materialZangpanChooseArr?.map(
                                    (item, index) => {
                                        return (
                                            <MaterialBox
                                                key={index}
                                                material_img={
                                                    item?.material_img
                                                }
                                                material_company={
                                                    item?.material_company
                                                }
                                                material_number={
                                                    item?.material_number
                                                }
                                                material_name={
                                                    item?.material_name
                                                }
                                                material_pattern={
                                                    item?.material_pattern
                                                }
                                                material_color={
                                                    item?.material_color
                                                }
                                                material_badge={
                                                    item?.material_badge
                                                }
                                                value={"전체"}
                                                material_chk={materialChk}
                                                readOnly={true}
                                                onDelete={() =>
                                                    handleZangpanArrDelete(
                                                        index
                                                    )
                                                }
                                            />
                                        );
                                    }
                                )}
                                {/* 장판 추가 버튼 */}
                                {/* <div
                                    className="secondAdd"
                                    style={{ marginTop: "20px" }}
                                    onClick={() => zangpanChoose()}
                                >
                                    <img src={imageData.Plus} />
                                    <p>추가</p>
                                </div> */}
                            </>
                        )}
                    </div>
                    {(zangpanMolding === 2 || zangpanMolding === 3) && (
                        <>
                            <div className="ContentHeader">
                                <h1>걸레받이</h1>
                                {zangpanMolding === 2 && (
                                    <>
                                        <div className="alignLine" />
                                        <p>굽도리</p>
                                    </>
                                )}
                            </div>
                            <div className="ContentBody">
                                <h2
                                    style={{
                                        fontSize: "13px",
                                        marginBottom: "10px",
                                    }}
                                >
                                    {zangpanMolding === 2
                                        ? "굽도리"
                                        : "걸레받이"}
                                </h2>

                                {baseboardMaterialChooseArr?.length === 0 ||
                                baseboardMaterialChooseArr === undefined ? (
                                    <div
                                        className="firstAdd"
                                        style={{ marginTop: "10px" }}
                                        onClick={() => {
                                            !materialChk &&
                                                baseboardMaterialChoose();
                                        }}
                                    >
                                        <img src={imageData.Plus} />
                                        <p>추가</p>
                                    </div>
                                ) : (
                                    <>
                                        {baseboardMaterialChooseArr?.map(
                                            (item, index) => {
                                                return (
                                                    <MaterialBaseBoardBox
                                                        key={index}
                                                        material_img={
                                                            item?.material_img
                                                        }
                                                        material_company={
                                                            item?.material_company
                                                        }
                                                        material_number={
                                                            item?.material_number
                                                        }
                                                        material_name={
                                                            item?.material_name
                                                        }
                                                        material_pattern={
                                                            item?.material_pattern
                                                        }
                                                        material_color={
                                                            item?.material_color
                                                        }
                                                        material_chk={
                                                            materialChk
                                                        }
                                                        value={"전체"}
                                                        readOnly={true}
                                                        // readOnly={
                                                        //     materialChk
                                                        //         ? true
                                                        //         : false
                                                        // }
                                                        // onChange={(newMemo) =>
                                                        //     setBaseboardMaterialChange(
                                                        //         index,
                                                        //         newMemo
                                                        //     )
                                                        // }
                                                        // onBlur={() => {
                                                        //     handleBaseboardMaterialUpdate(
                                                        //         item
                                                        //     );
                                                        // }}
                                                        onDelete={() =>
                                                            handleBaseboardMaterialDelete(
                                                                index
                                                            )
                                                        }
                                                    />
                                                );
                                            }
                                        )}
                                        {/* 장판 추가 버튼 */}
                                        {/* <div
                                    className="secondAdd"
                                    style={{ marginTop: "20px" }}
                                    onClick={() => zangpanChoose()}
                                >
                                    <img src={imageData.Plus} />
                                    <p>추가</p>
                                </div> */}
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </>
            )}
            <div className="cutLine" />
            <div
                className="ContentBody"
                style={looking ? { paddingBottom: "60px" } : {}}
            >
                <div className="cautionWrap" style={{ marginBottom: "20px" }}>
                    <h2>주의사항</h2>
                    <p>
                        바닥재 범위에 포함되지 않는 공간 : 베란다, 창고
                        (보일러실, 세탁실 등), 빌트인 가구와 옷장내부 등 (기존에
                        바닥재로 되어있던 공간이라도 바닥재 범위에 포함되지
                        않습니다.)
                    </p>
                </div>
                {}

                {looking === true ? (
                    <>
                        <Button
                            title="다음"
                            onClick={NextClk}
                            style={{ marginBottom: "30px" }}
                        />
                        <BottomBtn
                            active={5}
                            movementFlag={movementFlag}
                            index={index}
                        />
                    </>
                ) : !materialChk ? (
                    <Button title="제출" onClick={BtnClk} />
                ) : (
                    <Button
                        title="다음"
                        onClick={NextClk}
                        style={{ marginBottom: "30px" }}
                    />
                )}
            </div>
        </>
    );
}

export default UserNewMaterialSelect;
