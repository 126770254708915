import { useNavigate } from "react-router-dom";
import { imageData } from "../libs/images";

export const HeaderComp = ({ title, onClose }) => {
    return (
        <div className="header">
            <p>{title}</p>
            <div className="closeBtn">
                <img src={imageData.exit} onClick={onClose} alt="exit" />
            </div>
        </div>
    );
};

export const HeaderComp2 = () => {
    return (
        <div className="header2">
            <p>8AMDOBAE 여덟시도배</p>
        </div>
    );
};
export const HeaderComp3 = ({ title, isGreen = false, noLine = false }) => {
    const navigate = useNavigate(); //변수 할당시켜서 사용
    const onClickBtn = () => {
        navigate(-1); // 바로 이전 페이지로 이동, '/main' 등 직접 지정도 당연히 가능
    };

    return (
        <div
            className={`header ${isGreen ? "active" : ""} `}
            style={{ borderBottom: noLine ? "none" : "" }}
        >
            <p>{title}</p>
            <div className="BackBtn">
                <img
                    src={
                        isGreen ? imageData.arrowLeftWhite : imageData.ArrowLeft
                    }
                    alt="exit"
                    onClick={onClickBtn}
                />
            </div>
        </div>
    );
};
