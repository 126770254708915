const Routes = {
    UserEstimate: "/UserEstimate",
    UserContract: "/UserContract",
    UserAddressIn: "/UserAddressIn",
    UserPaymentInfo: "/UserPaymentInfo",
    UserMaterialSelect: "/UserMaterialSelect",
    UserFinalConfirm: "/UserFinalConfirm",
    UserASInquire: "/UserASInquire",
    UserSigongFinish: "/UserSigongFinish",
    UserLogin: "/UserLogin",
    UserNewEstimate: "/UserNewEstimate",
    UserNewContract: "/UserNewContract",
    UserNewAddressIn: "/UserNewAddressIn",
    UserNewPaymentInfo: "/UserNewPaymentInfo",
    UserNewPaymentInfo2: "/UserNewPaymentInfo2",
    UserNewMaterialSelect: "/UserNewMaterialSelect",
    UserNewFinalConfirm: "/UserNewFinalConfirm",
    UserNewFinished: "/UserNewFinished",
    UserNewAsInquire: "/UserNewAsInquire",
    UserNewConstrManagement: "/UserNewConstrManagement",
};

export default Routes;
