import React, { useState } from "react";

const paginationCustom = ({ totalPages, onPageChange, page, setPage }) => {
    const handlePageChange = (page) => {
        setPage(page);
        onPageChange(page);
    };

    const handlePrevious = () => {
        if (page > 1) {
            handlePageChange(page - 1);
        }
    };

    const handleNext = () => {
        if (page < totalPages) {
            handlePageChange(page + 1);
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];

        if (page <= 2) {
            for (let i = 1; i <= Math.min(3, totalPages); i++) {
                pageNumbers.push(i);
            }
            if (totalPages > 3) {
                pageNumbers.push("...");
                pageNumbers.push(totalPages);
            }
        } else if (page === 3) {
            for (let i = 1; i <= 4; i++) {
                pageNumbers.push(i);
            }
            if (totalPages > 4) {
                pageNumbers.push("...");
                pageNumbers.push(totalPages);
            }
        } else if (page > 3 && page < totalPages - 2) {
            pageNumbers.push(1);
            pageNumbers.push("...");
            for (let i = page - 1; i <= page + 1; i++) {
                pageNumbers.push(i);
            }
            pageNumbers.push("...");
            pageNumbers.push(totalPages);
        } else {
            pageNumbers.push(1);
            pageNumbers.push("...");
            for (let i = totalPages - 3; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        }

        return pageNumbers.map((number, index) =>
            number === "..." ? (
                <span key={index}>...</span>
            ) : (
                <button
                    key={index}
                    onClick={() => handlePageChange(number)}
                    disabled={number === page}
                    className={`${
                        number === page ? "active" : ""
                    } paginationCustom-number`}
                >
                    {number}
                </button>
            )
        );
    };

    return (
        <div className="paginationCustom-wrap">
            <button
                className="paginationCustom-prev"
                onClick={handlePrevious}
                disabled={page === 1}
            >
                &lt; 이전
            </button>
            {renderPageNumbers()}
            <button
                className="paginationCustom-next"
                onClick={handleNext}
                disabled={page === totalPages}
            >
                다음 &gt;
            </button>
        </div>
    );
};

export default paginationCustom;
