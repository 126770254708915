import routes from "../libs/routes";
const exportData = {
    SerVer: "https://api.8amdobae.co.kr",
    ServerImgFolder: "https://img.8amdobae.co.kr/",
    ImgState: "https://8amdobaebucket.s3.ap-northeast-2.amazonaws.com/",
    passwordPattern:
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
    idPattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]+$/,
    phonePattern: /^(01[016789]{1})?[0-9]{3,4}?[0-9]{4}$/,
    phonePattern2: /^(0[2-6]{1}|01[016789]{1}|[0-9]{3})-?[0-9]{3,4}-?[0-9]{4}$/,
    bank: /^[0-9]{10,16}$/,

    dayText: ["일", "월", "화", "수", "목", "금", "토"],
};
export default exportData;
