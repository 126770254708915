import { create } from "zustand";

export const usePopup = create((set) => ({
    popContent: "",
    popTitle: "",
    isYes: false,
    SubmitClk: () => {},
    isPopup: false,
    isOneBtn: false,

    setPopTitle: (v) => set({ popTitle: v }),
    setOneBtn: (v) => set({ isOneBtn: v }),
    setPopup: (v) => set({ isPopup: v }),
    setPopContent: (v) => set({ popContent: v }),
    setIsYes: (v) => set({ isYes: v }),
    setSubmitClk: (v) => set({ SubmitClk: v }),
}));

export default usePopup;
