import { useEffect } from "react";
import consts from "../libs/consts";
import usePopup from "../zustands/usePopup";
import { imageData } from "../libs/images";
export const PopupComp = () => {
    const {
        popContent,
        popTitle,
        isYes,
        SubmitClk,
        isPopup,
        setPopup,
        isOneBtn = false,
    } = usePopup();

    return (
        <>
            {isPopup && (
                <div className="overlay2">
                    <div className="popup">
                        {popTitle === "" ? (
                            <div
                                style={{ justifyContent: "flex-end" }}
                                className="popupTitle"
                            >
                                <img
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setPopup(!isPopup)}
                                    src={imageData.exit}
                                    alt="exit"
                                />
                            </div>
                        ) : (
                            <div
                                style={{ justifyContent: "space-between" }}
                                className="popupTitle"
                            >
                                <h1>{popTitle}</h1>
                                <img
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setPopup(!isPopup)}
                                    src={imageData.exit}
                                    alt="exit"
                                />
                            </div>
                        )}
                        <div className="popupContent">
                            <p
                                style={{
                                    fontSize: "14px",
                                    whiteSpace: "pre-line",
                                    lineHeight: "23px",
                                    textAlign: "center",
                                }}
                                className="FlexCenter "
                            >
                                {popContent}
                            </p>
                        </div>

                        <div className="FlexCenter">
                            {isOneBtn ? (
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        gap: "10px",
                                        padding: "0 20px",
                                    }}
                                >
                                    <button
                                        style={{
                                            height: "44px",
                                            marginBottom: "20px",
                                            fontSize: "12px",
                                        }}
                                        className="btn LastBtn"
                                        onClick={() => setPopup(!isPopup)}
                                    >
                                        확인
                                    </button>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        gap: "10px",
                                        padding: "0 20px",
                                    }}
                                >
                                    <button
                                        style={{
                                            height: "44px",
                                            marginBottom: "20px",
                                            fontSize: "12px",
                                        }}
                                        className="btn LastBtn CancelBtn"
                                        onClick={() => setPopup(!isPopup)}
                                    >
                                        취소
                                    </button>
                                    <button
                                        style={{
                                            height: "44px",
                                            marginBottom: "20px",
                                            fontSize: "12px",
                                        }}
                                        className="btn LastBtn"
                                        onClick={SubmitClk}
                                    >
                                        확인
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
